import React from 'react';
import { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BiDotsVerticalRounded } from "react-icons/bi";

interface HoverDropDownProps {
    items: MenuProps['items']; // Define the type of items prop
}

const HoverDropDown: React.FC<HoverDropDownProps> = ({ items }) => {
    return (
        <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
                <BiDotsVerticalRounded size={25}/>
            </a>
        </Dropdown>
    );
};

export default HoverDropDown;
