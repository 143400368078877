import React from 'react';
import { List, Button, Card } from 'antd';
import actionIcon from '../department/Group 73.png';

const data = [
  {
    day: 'Monday 10th',
    event: 'Interview',
    time: '9:00 am - 11:30 am',
  },
  {
    day: 'Monday 10th',
    event: 'Interview',
    time: '9:00 am - 11:30 am',
  },
  {
    day: 'Monday 10th',
    event: 'Interview',
    time: '9:00 am - 11:30 am',
  },
  {
    day: 'Monday 10th',
    event: 'Interview',
    time: '9:00 am - 11:30 am',
  },
  // ... other data items
];

const ScheduleList = () => {
  const handleNewScheduleClick = () => {
    // Redirect to Google Calendar
    window.location.href = 'https://calendar.google.com/';
  };

  return (
    <div className='w-[100%] md:w-[50%] h-auto bg-white rounded-md'>
      <div className='flex flex-row justify-between px-3 py-4'>
        <h2 className="text-lg font-poppins mb-4 text-[#030229]">Schedules</h2>
        <Button type="primary" className="mb-4" onClick={handleNewScheduleClick}>
          + New Schedule
        </Button>
      </div>

    
      <List
        className='px-3'
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={item.day}
              title={`${item.event}`}
              description={item.time}
            />
            <div className="mb-4 rounded-md bg-[#e6e5f1] text-[#605BFF] text-md shadow-sm cursor-pointer">
            <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
            </div>
          </List.Item>
        )}
      />

    </div>
  );
};

export default ScheduleList;

