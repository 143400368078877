import React, { useState } from "react";
import { VaultData } from "../../../assets/data/vaults";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  getActiveVault,
  storeActiveVault,
  storeSelectedSubVault
} from "../../../utils/helper";
import "../../../components/layout/sideNav.css";
import { useMediaQuery } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {}
}));

interface vaultProps {
  activeTabs?: any;
  setActiveTabs?: any;
}
const SideBar = ({ activeTabs, setActiveTabs }: vaultProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveVault());
  const smallScreen = useMediaQuery("(max-width:767px)");
  // localStorage.setItem("activeNav", activeTabs);
  // const activeTabFromLocalStorage=localStorage.getItem("activeNav")
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "0px 2px 4px 0px rgba(3, 2, 41, 0.10)"
    }
  };
  const { auth, vault } = useSelector((state: any) => state);
  const IdleDeviceData = vault?.all?.data
    ?.map((el: any) => {
        if (el?.enableVaultResponse?.serviceDetails?.toUpperCase() === "IDLE") {
          return { el };
        }
        return null;
      
    })
  const filteredIdleDeviceData = IdleDeviceData.filter(
    (item: any) => item !== null
  );
  const postPaidDeviceData = vault?.all?.data
    ?.map((el: any) => {
        if (el?.enableVaultResponse?.serviceDetails?.toUpperCase() === "PREPAID") {
          return { el };
        }
        return null;
      
    })
    .flat();
  const filteredPostPaidDeviceData = postPaidDeviceData.filter(
    (item: any) => item !== null
  );
  const PrePaidDeviceData = vault?.all?.data
    ?.map((el: any) => {
        if (el?.enableVaultResponse?.serviceDetails?.toUpperCase() === "POSTPAID") {
          return { el };
        }
        return null;
      });
    
  const filteredPrePaidDeviceData = PrePaidDeviceData.filter(
    (item: any) => item !== null
  );
  return (
    <>
      <div
        className="w-[334px] mt-2 fixed bg-white pl-6 pt-6 h-[calc(100%-40px)]"
        style={styles.shadow}
      >
        <ul className="font-medium text-base text-[#030229]">
          {VaultData.map((vault: any, index: number) => {
            return (
              <li
                className="flex py-1 gap-2"
                key={vault.id}
                onClick={() => {
                  storeActiveVault(vault.id);
                  setSelectedTabIndex(vault.id);
                  localStorage.setItem("activeSideVault", vault.id);
                  // setOpenDropDownIndex(
                  //   openDropDownIndex === getActiveMenu()
                  //     ? -1
                  //     : getActiveMenu()
                  // );
                }}
              >
                {vault.dropDown ? (
                  <Accordion
                    expanded={expanded === vault.id}
                    onChange={
                      // storeActiveVault(vault.id)
                      handleChange(vault.id)
                    }
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="w-[270px]"
                    >
                      <Typography
                        className={`flex gap-2 text-[#030229] text-base`}
                      >
                        <span
                          className={`text-lg pt-1 pr-4  ${
                            selectedTabIndex === vault.id
                              ? "fill-[#2943D6] vaultIcon"
                              : "#0F0F47"
                          }`}
                        >
                          {vault.icon}
                        </span>{" "}
                        <span
                          className={`pt-1 ${
                            selectedTabIndex === vault?.id
                              ? "text-[#2943D6]"
                              : "text-[#030229]"
                          }`}
                        >
                          {vault.title}
                        </span>{" "}
                      </Typography>
                    </AccordionSummary>
                    <MuiAccordionDetails>
                      <Typography className=" pl-1">
                        <ul className="pl-6">
                          {vault.dropDown.map((item: any) =>
                         { 
                          return (
                            <li
                              key={item?.menu}
                              className={`text-[#030229B2] cursor-pointer list-disc font-medium text-base py-4 relative  ${
                                localStorage.getItem("selectedSubVault") ===
                                item.menu
                                  ? "bg-[#FAFAFB] rounded-md pl-1"
                                  : ""
                              }`}
                              onClick={() => {
                                const selectedSubVault = item.menu;
                                storeSelectedSubVault(selectedSubVault);
                                setActiveTabs(item.activeTab);
                                localStorage.setItem(
                                  "activeNav",
                                  item.activeTab
                                );
                              }}
                            >
                              <span> {item.menu}</span>{" "}
                              {item.menu === "Add devices" ? (
                                ""
                              ) : (
                                <span className="absolute right-2 border border-[#03022940] rounded-full p-1 w-8 h-8 text-center">
                                  {vault?.isFetching ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{ fontSize: 24 }}
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    (item.menu === "Prepaid Devices" ? filteredPostPaidDeviceData.length :
                                    item.menu === "PostPaid Devices" ? filteredPrePaidDeviceData.length :
                                    item.menu === "Idle Devices" ? filteredIdleDeviceData?.length : null
                                      )
                                  )}
                                </span>
                              )}
                            </li>
                          )})}
                        </ul>
                      </Typography>
                    </MuiAccordionDetails>
                  </Accordion>
                ) : (
                  <div
                    className="flex gap-2 ml-4 cursor-pointer"
                    onClick={() => setActiveTabs(vault.activeTab)}
                  >
                    <span
                      className={`text-lg pt-2 pr-4  ${
                        selectedTabIndex === vault.id
                          ? "fill-[#2943D6] vaultIcon"
                          : "#0F0F47"
                      }`}
                    >
                      {vault.icon}
                    </span>{" "}
                    <span
                      className={`pt-2 ${
                        selectedTabIndex === vault.id
                          ? "text-[#2943D6]"
                          : "text-[#030229]"
                      }`}
                    >
                      {vault.title}
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
