import { Button, Form } from "antd";
import { InputNumber } from "antd/lib";
import React from "react";
import { unLockDeviceAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const UnlockDevice = (props: any) => {
  const { auth,vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const onUnlock = async (values: any) => {
    auth?.token &&
      (await unLockDeviceAction(auth?.token, {
        unLockList: [
          {
            deviceUid: props?.data?.deviceUniqueID,
            validityDays: values.validityDays
          }
        ],
        accountId: "65533064ae06d4f32a4c299a"
      })(dispatch));
    props.onClose();
  };
  return (
    <>
      <div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onUnlock}
          autoComplete="off"
        >
          <h1 className="text-[#030229] text-sm py-2 pl-4">
            Device will move to Active state
          </h1>
          <div className="text-center gap-3 w-full flex text-[#030229] text-sm mt-5">
          <p> Unlock for</p>  
             <Form.Item name="validityDays" className="w-2/3- " rules={[{ required: true, message: "Please enter days" }]}>
          <InputNumber defaultValue={30} className="w-full"/> 
        </Form.Item><p>day(s)</p>
          </div>
          <div className="flex gap-3 ml-[5rem] mt-10">
            <Button
              htmlType="submit"
              className="w-32 border border-red-400 hover:border-red-400"
            >
              Cancel
            </Button>
            <Button htmlType="submit" className="w-32  border border-[#605BFF]">
            {vault?.isFetching?  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:"Unlock"} 
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default UnlockDevice;
