import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { Divider, Tag } from "antd";
import { Table } from "antd";
import { Mycolumns } from "../../../../assets/data/data";
import { searchValue } from "../../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import { getAllSubdeliverynotesItemAction } from "../../../../store/subscription/deliverynote/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

const Invoice = (props: any) => {
  const { account, auth, deliverynote } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);

  function getMonthlyInstalment(data: any, duration: any) {
    for (let item of data) {
      if (item?.orderItem && item?.orderItem?.product?.subscriptionPlan) {
        for (let plan of item?.orderItem?.product?.subscriptionPlan) {
          if (plan?.duration === duration) {
            return plan?.monthlyInstalment;
          }
        }
      }
    }
    return null;
  }

  useEffect(() => {
    if (
      (account?.selected?.deliveryNote?._id ||
        props?.dataToDisplay?.account?.deliveryNote?._id) &&
      auth?.token &&
      props?.openInvoiceView
    ) {
      getAllSubdeliverynotesItemAction(
        auth?.token,
        `?deliveryNote=${
          props?.fromBilling
            ? props?.dataToDisplay?.account?.deliveryNote?._id
            : account?.selected?.deliveryNote?._id
        }&limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [
    auth?.token,
    account?.selected?.deliveryNote?._id,
    dispatch,
    limit,
    page,
    props?.openInvoiceView,
    props?.fromBilling,
  ]);

  const billingdata = [
    {
      company: "Samphone",
      address: "Kigali City - Nyarugenge",
      email: "samphone@gmail.com",
      contact: "+25078888888",
    },
  ];

  if (props?.dataToDisplay?.data) {
  }

  const productData = deliverynote?.invoiceResult?.data?.map((el: any) => {
    return {
      key: el._id,
      type: el?.wareHouseItem?.product?.type,
      brand: el?.wareHouseItem?.product?.brand,
      model: el?.wareHouseItem?.product?.model,
      specs: el?.wareHouseItem?.quotationItem?.specification
        ?.slice(2, 6)
        ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
        ?.join(", "),
      imei: el?.wareHouseItem?.serialNumber,
      status: el?.wareHouseItem?.product?.status,
      amountPaid:
        props?.dataToDisplay?.type === "b2c"
          ? props?.dataToDisplay?.amountPaid?.toLocaleString()
          : getMonthlyInstalment(
              deliverynote?.subDeliveryNoteItem?.data,
              props?.dataToDisplay?.data?.account?.duration
            )?.toLocaleString(),
    };
  });

  return (
    <div className="text-black  h-screen px-12">
      <Stack
        direction="row"
        alignItems={"start"}
        justifyContent="space-between"
      >
        {" "}
        <div className="text-xl font-bold">
          <h2>INVOICE</h2>{" "}
        </div>
        <Tag
          color={searchValue(props?.dataToDisplay?.data?.status)}
          className="capitalize p-2 px-4 text-lg"
        >
          {props?.dataToDisplay?.data?.status ?? props?.dataToDisplay?.status}
        </Tag>
        <div className="flex flex-col float-right mb-4">
          <Stack direction="row" spacing={2}>
            <p className="font-semibold text-[#000] text-[15px]">
              Invoice Number
            </p>
            <p className="font-light  text-gray-500 text-[14px] ">
              {props?.dataToDisplay?.bill ?? props?.dataToDisplay?.transaction}
            </p>
          </Stack>
          <Stack direction="row" spacing={5}>
            <p className="font-semibold text-[#000] text-[15px]">
              Invoice Date
            </p>
            <p className="font-light text-[14px] text-gray-500">
              {props?.dataToDisplay?.data?.date?.split("T")[0] ??
                props?.dataToDisplay?.date?.split("T")[0]}
            </p>
          </Stack>
          <Stack direction="row" spacing={7}>
            <p className="font-semibold  text-[#000] text-[15px]">Due Date</p>
            <p className="font-light text-[14px] text-gray-500">
              {props?.dataToDisplay?.data?.dueDate?.split("T")[0] ??
                props?.dataToDisplay?.dueDate?.split("T")[0]}
            </p>
          </Stack>
        </div>
      </Stack>
      <Divider />
      <div className="flex flex-row justify-between pt-2">
        <div className="w-[15rem]">
          <h2 className="text-base font-semibold mb-2">Billing Information</h2>
          {billingdata?.map((item: any) => (
            <div className="flex flex-col gap-1">
              <Stack>
                <p className="text-sm font-semibold">Company</p>
                <p className="text-sm">{item?.company}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Address</p>
                <p className="text-sm">{item?.address}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Email</p>
                <p className="text-sm">{item?.email}</p>
              </Stack>
              <Stack>
                <p className="text-sm font-semibold">Contact</p>
                <p className="text-sm">{item?.contact}</p>
              </Stack>
            </div>
          ))}
        </div>

        <div className="w-[14.7rem] pl-12">
          <h2 className="text-base font-semibold mb-2">Shipping Information</h2>

          <div className="flex flex-col gap-1">
            <Stack>
              <p className="text-sm font-semibold">
                {(props?.dataToDisplay?.data?.account?.type ||
                  props?.dataToDisplay?.account?.type) === "b2c"
                  ? "Individual"
                  : "Company"}
              </p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.account?.customerDetails?.name ??
                  props?.dataToDisplay?.account?.customerDetails?.name}
              </p>
            </Stack>
            <Stack>
              <p className="text-sm font-semibold">Address</p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.account?.customerDetails
                  ?.country ??
                  props?.dataToDisplay?.account?.customerDetails?.country}{" "}
                -{" "}
                {props?.dataToDisplay?.data?.account?.customerDetails
                  ?.district ??
                  props?.dataToDisplay?.account?.customerDetails?.district}
              </p>
            </Stack>
            <Stack>
              <p className="text-sm font-semibold">Email</p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.account?.customerDetails?.email ??
                  props?.dataToDisplay?.account?.customerDetails?.email}
              </p>
            </Stack>
            <Stack>
              <p className="text-sm font-semibold">Contact</p>
              <p className="text-sm">
                {props?.dataToDisplay?.data?.account?.customerDetails?.tel ??
                  props?.dataToDisplay?.account?.customerDetails?.tel}
              </p>
            </Stack>
          </div>
        </div>
      </div>
      <Divider dashed />

      <div className="text-lg font-semibold mb-1">
        <h2>Products</h2>{" "}
      </div>

      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={deliverynote?.subDeliveryNoteItem?.total}
        count={Math.ceil(deliverynote?.subDeliveryNoteItem?.total / limit)}
      >
        <div className=" w-full  pt-2">
          <Table
            columns={Mycolumns}
            dataSource={productData}
            pagination={false}
            loading={deliverynote?.deliveryIsFetching}
          />
        </div>
      </ScrollableFrame>
      <div className="flex flex-col mt-5 mb-2  float-right">
        <div className="flex  justify-between">
          <p className="text-[.9rem] font-light">Subtotal</p>
          <h2 className="text-[.9rem] font-semibold ">
            {`${
              props?.dataToDisplay?.value?.toLocaleString() ??
              props?.dataToDisplay?.amountPaid?.toLocaleString()
            } RWF`}
          </h2>
        </div>
        <div className="flex justify-between">
          <p className="text-[.9rem] font-light">Taxes</p>
          <h2 className="text-[.9rem] font-semibold ">0%</h2>
        </div>
        <div className="flex mt-2 justify-between gap-12">
          <p className="text-lg font-semibold">Total Amount Due</p>
          <h2 className="text-lg font-semibold float-right">
            {`${
              props?.dataToDisplay?.value?.toLocaleString() ??
              props?.dataToDisplay?.amountPaid?.toLocaleString()
            } RWF`}
          </h2>
        </div>
      </div>
      <Divider dashed />
      <Stack direction="row" spacing={6}>
        <h2 className="text-base">Additional Notes</h2>
        <p className="text-gray-500 text-sm">No Additional Notes</p>
      </Stack>
    </div>
  );
};
export default Invoice;
