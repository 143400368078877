import React, { useState } from "react";
import { EbmPage } from "../assets/data/EbmPages";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  getActiveEbm,
  storeActiveEbm,
  storeSelectedSubEbm
} from "../../../utils/helper";
import "../../../components/layout/sideNav.css";
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {}
}));

interface EbmProps {
  active?: any;
  setActive?: any;
}
const SideBar = ({ active, setActive }: EbmProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveEbm());
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "0px 2px 4px 0px rgba(3, 2, 41, 0.10)"
    }
  };
 
  return (
    <>
      <div
        className="w-[344px] fixed bg-white pl-3 pt-6 h-[calc(100%-40px)]"
        style={styles.shadow}
      >
        <ul className="font-medium text-base text-[#030229B2]">
          {EbmPage.map((ebm: any, index: number) => {
            return (
              <li
                className="flex py-1 gap-2"
                key={ebm.id}
                onClick={() => {
                  storeActiveEbm(ebm.id);
                  setSelectedTabIndex(ebm.id);
                  localStorage.setItem("activeSideEbm", ebm.id);
                }}
              >
                {ebm.dropDown ? (
                  <Accordion
                    expanded={expanded === ebm.id}
                    onChange={
                      handleChange(ebm.id)
                    }
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="w-full"
                    >
                      <Typography
                        className={`flex gap-1 text-[#030229CC] text-base`}
                      >
                        <span
                          className={`text-base pt-1 pr-4  ${
                            selectedTabIndex === ebm.id
                              ? "fill-[#2943D6] ebmIcon"
                              : "fill-[#0F0F47] ebmIcons"
                          }`}
                        >
                          {ebm.icon}
                        </span>{" "}
                        <span
                          className={`pt-1 text-base ${
                            selectedTabIndex === ebm?.id
                              ? "text-[#2943D6] "
                              : "text-[#030229CC]"
                          }`}
                        >
                          {ebm.title}
                        </span>{" "}
                      </Typography>
                    </AccordionSummary>
                    <MuiAccordionDetails>
                      <Typography className=" pl-1">
                        <ul className="pl-6">
                          {ebm.dropDown.map((item: any) =>
                         { 
                          return (
                            <li
                              key={item?.menu}
                              className={`text-[#030229B2] cursor-pointer list-disc font-medium text-base py-4 relative  ${
                                localStorage.getItem("selectedSubEbm") ===
                                item.menu
                                  ? "bg-[#FAFAFB] rounded-md pl-1"
                                  : ""
                              }`}
                              onClick={() => {
                                const selectedSubEbm = item.menu;
                                storeSelectedSubEbm(selectedSubEbm);
                                setActive(item.activeTab);
                                localStorage.setItem(
                                  "activeNav",
                                  item.activeTab
                                );
                              }}
                            >
                              <span> {item.menu}</span>{" "}
                            
                            </li>
                          )})}
                        </ul>
                      </Typography>
                    </MuiAccordionDetails>
                  </Accordion>
                ) : (
                  <div
                    className="flex gap-1 ml-4 cursor-pointer"
                    onClick={() => setActive(ebm.activeTab)}
                  >
                    <span
                      className={`text-base pt-4 pr-4  ${
                        selectedTabIndex === ebm.id
                          ? "fill-[#2943D6] ebmIcon "
                          : "fill-[#0F0F47] ebmIcons"
                      }`}
                    >
                      {ebm.icon}
                    </span>{" "}
                    <span
                      className={`pt-4 ${
                        selectedTabIndex === ebm.id
                          ? "text-[#2943D6]"
                          : "text-[#030229B2]"
                      }`}
                    >
                      {ebm.title}
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
