import { myReportActions } from ".";
import {
  getAllSaveTheChildrenService,
  getAllStockReportService,
  getAllTransferReportService,
} from "./services";

export const getAllSaveTheChildrenAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllSaveTheChildrenService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setSaveTheChildren(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockReportAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllStockReportService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setStockReport(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTransferReportAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myReportActions.setIsFetching(true));
      const res = await getAllTransferReportService(token, query);
      if (res?.status === 200) {
        dispatch(myReportActions.setTranserReport(res));
        dispatch(myReportActions.setIsFetching(false));
      }
      dispatch(myReportActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
