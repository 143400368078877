import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import sunIcon from './sun.svg';

const TimeComponent = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = time.toLocaleTimeString();
  const formattedDate = time.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="text-center">
        <div className="flex flex-col items-center">
          <div className="flex items-center text-lg font-light mb-2">
            <div >
              <img src={sunIcon} alt="Sun Icon" className="mr-2 h-12 w-10" />
            </div>
            <div className='text-gray-500'>
              <div>{formattedTime}</div>
              <div className="text-gray-500 text-sm">Realtime Insight</div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-black">
          <div className="text-lg flex-start">Today:</div>
          <div className="text-xl font-poppins">{formattedDate}</div>
        </div>
        <div className="mt-8">
          <div className="cursor-pointer bg-[#605BFF] text-[#FFFFFF] py-3 rounded-md font-poppins" >
            View Attendance
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeComponent;

