import React from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  const storedStatus =
    props?.data?.data?.listOfItems &&
    props?.data?.data?.listOfItems?.map(
      (item: any, _index: number) => item?.status
    );

  const checkReceiveAndStoredStatus =
    props?.data?.status &&
    storedStatus?.every((status: any) => status === "Stored-Complete");

  return (
    <>
      <div
        className="  min-h-[120px] bg-white text-black p-2 rounded-md border hover:border-black relative"
        onClick={() => {
          navigate(`/inventory/ws/in/${props.data.id}`);
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Stack spacing={1}>
              <h1>
                {props?.data?.status === "In-Transit"
                  ? props?.data?.deliveryID
                  : props?.data?.warehouseID}
              </h1>
              <p className="text-xs" style={{ color: "blue" }}>
                {props?.data?.pOrderID}
              </p>
              <p className="text-[10px]" style={{ color: "chocolate" }}>
                {props?.data?.data?.pOrderID?.supplier?.name}
              </p>
              {props?.data?.data?.ebmRegistered && (
                <Tag color={"blue"} className="text-center">
                  SAVED IN EBM
                </Tag>
              )}
            </Stack>
            <Tag
              color={searchValue(
                ["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
                  props?.data?.status
                )
                  ? props?.data?.status
                  : checkReceiveAndStoredStatus
                  ? props?.data?.status
                  : "Verified - Not Stored"
              )}
              style={{ minWidth: "4rem", textAlign: "center" }}
              className="absolute top-2.5 right-1"
            >
              {/* {props?.data?.status} */}
              {["In-Transit", "Verified-Incomplete", "Stored"]?.includes(
                props?.data?.status
              )
                ? props?.data?.status
                : checkReceiveAndStoredStatus
                ? props?.data?.status
                : "Verified - Not Stored"}
            </Tag>
          </Stack>
          <div className="bottom-2 right-2 flex justify-between items-end w-full">
            <Stack spacing={0} style={{ marginTop: "15px" }}>
              <p className="text-xs">
                <b>{props?.data?.data?.updatedAt.split("T")[0]}</b>
              </p>
            </Stack>
            <p className="text-xs pr-3">
              <b>{props?.data?.qnt} items</b>
            </p>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default Component;
