import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";
import { searchFuction } from "../../utils/converter";
import { regions } from "../../utils/regions";
const { Option } = Select;

const ReportFiltering = (props: any) => {
  const { auth, channel, report } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(
    props?.isStockReport ? true : false
  );
  const [selectedhannel, setSelectedChannel] = useState("");
  const [shopFilter, setShopFilter] = useState([]);

  const resetFilter = () => {
    if (selectedFilters) {
      setSelectedChannel("");
      props?.setSelectedShop("");
      props?.setSelectedBrand("");
      props?.setSelectedRagion("");
    }
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && selectedhannel) {
      getAllShopAction(auth?.token, `channel=${selectedhannel}`)(dispatch);
    }
  }, [auth, selectedhannel, dispatch]);

  const extractUniqueProductInfo = (data: any) => {
    let productInfoSet = new Set();
    let productInfo: any[] = [];
    data?.forEach((item: any) => {
      if (
        item?.product &&
        item?.product?._id &&
        item?.product?.product &&
        item?.product?.product.brand
      ) {
        const productId = item?.product?._id;
        if (!productInfoSet?.has(productId)) {
          productInfoSet?.add(productId);
          productInfo?.push({
            _id: productId,
            brand: `${item?.product?.product?.brand} ~ ${item?.product?.product?.model}`,
          });
        }
      }
    });

    return productInfo;
  };

  return (
    <div className="px-4 py-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          spacing={1}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>

          {selectedFilters && (
            <Stack
              spacing={1}
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Select Channel"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={channel.isFetching}
                disabled={channel.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  !value && setSelectedChannel("");
                  value && setSelectedChannel(value);
                }}
              >
                {channel?.all?.data?.map((d: any, index: any) => (
                  <Option Key={index} value={d?._id} className="capitalize">
                    {d?.name}
                  </Option>
                ))}
              </Select>
              {selectedhannel && (
                <Select
                  showSearch
                  onSearch={(value: any) =>
                    searchFuction(
                      value,
                      channel?.allShop?.data,
                      (option: any, val) =>
                        option?.name?.toLowerCase().includes(val.toLowerCase()),
                      setShopFilter
                    )
                  }
                  style={{ width: 200 }}
                  allowClear
                  placeholder="Filter By Shop"
                  optionFilterProp="children"
                  filterOption={false}
                  loading={channel.isFetching}
                  disabled={channel.isFetching}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && props?.setSelectedShop("");
                    value && props?.setSelectedShop(value);
                  }}
                >
                  {shopFilter.length > 0
                    ? shopFilter.map((el: any) => (
                        <Option value={el?._id}>{el?.name}</Option>
                      ))
                    : channel?.allShop?.data?.map((d: any, index: any) => (
                        <Option
                          Key={index}
                          value={d?._id}
                          className="capitalize"
                        >
                          {d?.name}
                        </Option>
                      ))}
                </Select>
              )}
              {!props?.selectedShop && props?.isStockReport && (
                <Select
                  placeholder="Filter by Region"
                  allowClear
                  // style={{ width: 150 }}
                  className="w-[60%] md:w-[150px]"
                  onChange={(value: any) => {
                    !value && props?.setSelectedRagion("");
                    value && props?.setSelectedRagion(value);
                  }}
                >
                  {regions.map((d: any, index: any) => (
                    <Option
                      value={d?.toLowerCase()?.replace(/\s/g, "")}
                      key={index}
                    >
                      {d}
                    </Option>
                  ))}
                </Select>
              )}
              {props?.isStockReport && (
                <Select
                  showSearch
                  style={{ width: 200 }}
                  allowClear
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  loading={channel.isFetching}
                  disabled={channel.isFetching}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && props?.setSelectedBrand("");
                    value && props?.setSelectedBrand(value);
                  }}
                >
                  {report?.stockReport?.data &&
                    extractUniqueProductInfo(report?.stockReport?.data)?.map(
                      (d: any, index: any) => (
                        <Option
                          Key={index}
                          value={d?._id}
                          className="capitalize"
                        >
                          {d?.brand}
                        </Option>
                      )
                    )}
                </Select>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default ReportFiltering;
