import React, { useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Select, Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import ManageItemTable from "../components/tables/ManageItemTable";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import { MdOutlineFilterList } from "react-icons/md";
import BargeCard from "../components/cards/BargeCard";
import ItemCardInfo from "../components/ItemCardInfo";
import AddItemForm from "../components/forms/AddItemForm";
import GeneralDrawer from "../components/GeneralDrawer";
import ItemDetails from "../components/ItemDetails";
import ManageCustomerTable from "../components/tables/ManageCustomerTable";
import CustomerDetails from "../components/CustomerDetails";
import AddCustomerForm from "../components/forms/AddCustomerForm";
const CustomerManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  return (
    <div>
    <GeneralDrawer openDrawer={openDrawer} closeDrawer={onClose} width={550} title={<h1 className="text-base font-medium text-[#030229]">Customer Details</h1>} component={<div>
      <CustomerDetails/>
    </div>}/>
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Customer Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex gap-5 mt-5">
        <EbmCard title={"Total Customer"} total={"452"} />
        <EbmCard title={"Individual"} total={"RWF 2000,000"} />
        <EbmCard title={"Corperate"} total={"RWF 3000,000"} />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-base ">
            Customer Code, Customer Name
          </h1>
          <div className="flex gap-5">
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="User"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "User 1"
                },
                {
                  value: "2",
                  label: "User 2"
                }
              ]}
            />
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: "200px" }}
            />
          </div>
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={<h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Add New Customer
            </h1>}
            component={
              <div className="px-4 h-[50vh] w-full">
                <div className="mt-10 w-full">
                  <AddCustomerForm/>

                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <div className="mt-5">
          <ManageCustomerTable open={showDrawer}/>
        </div>
      </div>
    </div>
  );
};

export default CustomerManagement;
