import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import ItemManagement from "./ItemManagement";
import Overview from "./Overview";
import CustomerManagement from "./CustomerManagement";
import SalesManagement from "./SalesManagement";
import PurchaseManagement from "./PuchaseManagement";
import ImportManagement from "./ImportManagement";
import StockManagement from "./StockManagement";
import SideBar from "../components/SideBar";
import InsurerManagement from "./InsurerManagement";

const EBMOverview = (props: any) => {
  const { hash, pathname } = useLocation();
  const selectedTabs = hash.replace(/^#/, "");
console.log("path",hash, "")
  const [active, setActive] = useState(() => {
    return localStorage.getItem("activeEbmTab") || selectedTabs || "ebmOverview";
  });
  
  console.log("active",active, "and",selectedTabs)
  return (
    <div className="text-black mt-[8px]">
        <SideBar active={active} setActive={setActive} />
      <div className="ml-[16rem] xl:ml-[25rem] pt-5">
        {active === "ebmOverview" && <Overview/>}
        {active === "ebmItem" && <ItemManagement />}
        {active === "ebmCustomer" && <CustomerManagement />}
        {active === "ebmInsurer" && <InsurerManagement />}
        {active === "ebmSales" && <SalesManagement />}
        {active === "ebmPurchase" && <PurchaseManagement />}
        {active === "ebmImport" && <ImportManagement />}
        {active === "ebmStock" && <StockManagement />}
      </div>
    </div>
  );
};

export default EBMOverview;
