import React, { useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Select, Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import GeneralDrawer from "../components/GeneralDrawer";
import AddCustomerForm from "../components/forms/AddCustomerForm";
import { BsDownload, BsPrinter } from "react-icons/bs";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { GoPlus } from "react-icons/go";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import type { RadioChangeEvent, SelectProps } from 'antd';
import { Radio } from 'antd';
import PurchaseDetails from "../components/PuchaseDetails";
import ImportTable from "../components/tables/ImportTable";
import RegisterItem from "../components/forms/RegisterItem";

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';
type SelectCommonPlacement = SelectProps['placement'];
const ImportManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [placement, SetPlacement] = useState<SelectCommonPlacement>('topLeft');

  const placementChange = (e: RadioChangeEvent) => {
    SetPlacement(e.target.value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpenDrawer(true);

  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  return (
    <div>
    <GeneralDrawer openDrawer={openDrawer} closeDrawer={onClose} width={650} title={<h1 className="text-base font-medium text-[#030229]">Import Information Details</h1>} component={<div >
      <div className="flex justify-between ">
        <div>
          <div className="bg-[#F4F6FA] flex justify-center items-center gap-2"> <h1 className="text-base text-black pl-2">CurrentStatus:</h1>
            <CustomButton title={"Wait for Approval"} textColor="white" bgColor="green-500" textSize="base"/>
          </div>
        </div>
        <EbmButton
        textColor="black"
        btnName={"Export"}
        icon={<ImportIcons />}
        btnBgColor="gray-50"
      />
      </div>
      <div className="mt-5">

      <PurchaseDetails/>
      </div>
    </div>}/>
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Import Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex justify-between mt-5">
        <div></div>
        <div className="flex gap-5">
          <h1 className="pt-1">From</h1>
        <DatePicker
    defaultValue={dayjs('2019-09-03', dateFormat)}
    minDate={dayjs('2019-08-01', dateFormat)}
    maxDate={dayjs('2020-10-31', dateFormat)}
  />
  <h1 className="pt-1">To</h1>
  <DatePicker
    defaultValue={dayjs('2019-09-03', dateFormat)}
    minDate={dayjs('2019-08-01', dateFormat)}
    maxDate={dayjs('2020-10-31', dateFormat)}
  />
        </div>

      </div>
      <div className="grid grid-cols-3 gap-5 mt-7">
        <TwoIconsCard amount={"650"} title={"Total Items"} name={"Increased than last month"} icon={<GoPlus color="#43900C"/>} iconTwo={<NewIcons/>}/>
        <TwoIconsCard amount={"600"} title={"United Arab"} name={"Increased than last month"} icon={<GoPlus color="#43900C"/>} iconTwo={<AmountIcons/>}/>
        <TwoIconsCard amount={"50"} title={"Canada"} name={"20 new customers added!"} icon={<GoPlus color="#43900C"/>} iconTwo={<CustomerAmountIcons/>}/>
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
      <Radio.Group value={placement} onChange={placementChange}>
        <Radio.Button value="topLeft">All Status</Radio.Button>
        <Radio.Button value="topRight">Approved</Radio.Button>
        <Radio.Button value="bottomRight">Cancelled</Radio.Button>
        <Radio.Button value="bottomRight">Unset</Radio.Button>
        <Radio.Button value="bottomRight">Waiting</Radio.Button>
      </Radio.Group>
      <Search
              placeholder="Search by supplier name"
              onSearch={onSearch}
              style={{ width: "200px" }}
            />
        </div>
        <div className="flex justify-between">
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={<h1 className="text-[#030229] font-semibold text-lg text-center py-2">
          Import Registration
            </h1>}
            component={
              <div className="px-4 h-full w-full">
                <div className="mt-10 w-full">
                  <RegisterItem/>

                </div>
              </div>
            }
            width={1100}
          />
        </div>
        <div className="mt-5">
          <ImportTable open={showDrawer}/>
        </div>
      </div>
    </div>
  );
};

export default ImportManagement;
