import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import { calculateTotalAmountPaid } from "../../../../../utils/converter";
import Mtn2 from "../../../../../assets/download3.png";
import BillingCard from "./BillingCard";

type DataItem = {
  title: string;
  value: any;
};

const HeadersComponent = ({ data }: { data: DataItem[] }) => {
  const { account, invoice } = useSelector((state: any) => state);

  const productsPrice = account?.selected?.deliveryNote?.list?.flatMap(
    (item: any) => item?.orderItem?.product?.prices?.map((d: any) => d?.value)
  );

  const totalProductsPrice =
    invoice?.all?.data &&
    invoice?.all?.data?.at(0)?.amountToBePaid *
      invoice?.all?.data?.at(0)?.account?.duration;

  const totalPaidAmount = calculateTotalAmountPaid(invoice?.all?.data);

  let totalAmount = 0;
  invoice?.all?.data
    .filter((item: any) => item.status !== "paid")
    .map((item: any) => {
      return (totalAmount = totalAmount + item?.amountToBePaid);
    });

  return (
    <>
      {account?.isFetching ? (
        <Skeleton />
      ) : (
        <div className="mb-10 mt-4 py-3  rounded-md text-gray-900  flex flex-col md:flex-row   h-auto md:h-[350px] capitalize font-poppins">
          <div className=" w-[100%]  h-auto md:h-40 ">
            <div className="flex flex-row bg-white rounded-md  h-[220px]  pt-2 md:pt-auto">
              <div className="bg-white w-[100%] h-[100px] rounded-md p-2 space-y-2 ">
                <div className="border-b">
                  <div className="flex flex-wrap justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2 pb-2">
                    <p> Current Plan Summary </p>
                    <p className="text-[#605BFF] cursor-pointer border border-gray-500 rounded-md p-1">
                      {" "}
                      Upgrade plan{" "}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <div>
                    <div className="text-gray-500 font-poppins">Plan Type</div>
                    <div className="text-[#030229] font-poppins   leading-21">
                      {account?.selected?.type}
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500 font-poppins">
                      Installment
                    </div>
                    <div className="font-poppins">
                      {account?.selected?.planType}
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500">amount</div>
                    <div className="font-poppins ">
                      {`RWF ${invoice?.all?.data[0]?.amountToBePaid?.toLocaleString()}`}
                    </div>
                  </div>
                </div>
                {account?.selected?.type?.toLowerCase() !== "b2b" && (
                  <div className="px-2">
                    <div className="text-gray-500 ">initial payment</div>
                    <div>{`RWF ${productsPrice || 0} `}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 pt-4">
              <BillingCard
                value={invoice?.all?.data[0]?.payment?.date?.slice(0, 10)}
                name={"previous date"}
              />
              <BillingCard
                value={`RWF ${invoice?.all?.data[0]?.amountToBePaid?.toLocaleString()}`}
                name={"previous billing amount"}
              />
            </div>
          </div>
          <div className=" w-[100%]  h-auto md:h-40 ">
            <div className="flex flex-col md:flex-row  bg-white rounded-md mx-4 h-[220px]  pt-2 md:pt-auto">
              <div className="bg-white w-[100%] h-[100px] rounded-md p-2 space-y-2">
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <p> Payment Method </p>
                  <p></p>
                </div>
                <div className="flex flex-row justify-between font-poppins rounded-md font-medium text-base leading-6 ml-2 pt-2 h-16 border border-[#EBEFF2]">
                  <div className="p-2 flex flex-row gap-2">
                    <img src={Mtn2} alt="Mtn2" />
                    <p>
                      {invoice?.all?.data[0]?.payment?.mode
                        ?.split("_")
                        ?.join(" ")}
                    </p>
                  </div>
                  <div className="p-2 text-[#605BFF] border border-[#EBEFF2] rounded-md h-10 cursor-pointer mx-4">
                    Change
                  </div>
                </div>
                <div className="flex flex-col justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2"></div>
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <div>
                    <div className="text-gray-500 mx-2"> Payment type </div>
                  </div>

                  <div>
                    <div className="font-poppins font-normal text-base leading-5 mx-2">
                      Manual
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mx-4 gap-4 pt-4">
              <BillingCard
                value={dayjs(invoice?.all?.data[0]?.dueDate).format(
                  "YYYY-MM-DD"
                )}
                name={"next billing date"}
              />
              <BillingCard
                value={`RWF ${totalAmount.toLocaleString() ?? 0}`}
                name={"next bill amount"}
              />
            </div>
          </div>
          <div className="w-[100%]  h-auto md:h-40 space-y-3 ">
            <div className="flex flex-row   md:pt-auto gap-4">
              <BillingCard
                value={dayjs(account?.selected?.startingDate).format(
                  "YYYY-MM-DD"
                )}
                name={"start date"}
              />
              <BillingCard
                value={dayjs(account?.selected?.endingDate).format(
                  "YYYY-MM-DD"
                )}
                name={"end date"}
              />
            </div>
            <div className="flex flex-row md:pt-auto gap-4">
              <BillingCard
                value={
                  account?.selected?.planType === "Weekly"
                    ? `RWF ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.numberOfWeeks)
                      ).toLocaleString()}`
                    : account?.selected?.planType === "Daily"
                    ? `RWF ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.numberOfDays)
                      ).toLocaleString()}`
                    : `RWF ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.duration)
                      ).toLocaleString()}`
                }
                name={"expected amount"}
              />
              <BillingCard
                value={`${dayjs(account?.selected?.endingDate).diff(
                  account?.selected?.startingDate,
                  "month"
                )} months`}
                name={"installment period"}
              />
            </div>
            <div className="flex flex-row   md:pt-auto gap-4">
              <BillingCard
                value={`RWF ${totalPaidAmount?.toLocaleString() ?? 0}`}
                name={"paid amount"}
              />
              <BillingCard
                value={`RWF ${
                  (totalProductsPrice - totalPaidAmount)?.toLocaleString() || 0
                } `}
                name={"Remain amount"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default HeadersComponent;
