import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getOneShop = async (itemId: string, token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/shop/one/${itemId}`, token);
};

export const createShopRequest = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/stockreq`, data, token);
};

export const getAllServiceRequest = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/stockreq${query}`, token);
};

// Ignored Functions

export const createChannel = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/channel`, data, token);
};

export const createShop = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop`, data, token);
};

export const createAgent = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/agent`, data, token);
};

export const getAllChannel = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/channel?${query || ""}`, token);
};

export const getAllShop = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop?${query || ""}`, token);
};

export const getAllAgentInChannel = async (token: string, itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign?channel=${itemId}`, token);
};

export const getAllAgent = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign?${query || ""}`, token);
};

export const getOneChannel = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/channel/one/${id}`, token);
};

export const updateChannel = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/channel/one/${itemId}`,
    data,
    token
  );
};

export const updateShop = async (itemId: string, data: any, token: string) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shop/one/${itemId}`,
    data,
    token
  );
};

export const deleteChannel = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/channel/one/${itemId}`, token);
};

export const deleteAgentService = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/agent/one/${itemId}`, token);
};

export const deleteShop = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/shop/one/${itemId}`, token);
};

export const updateServiceAgent = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/agent/one/${itemId}`,
    data,
    token
  );
};

// Delete selected products using its ids
export const updateIsDeleteChannel = async (data: any, token: string) => {
  return await HttpRequest.update(`${SERVER_URL}/channel/`, data, token);
};

// Prices
export const getPriceLists = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelist${query}`, token);
};
export const getPriceListItem = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
};
export const addPriceListItem = async (
  token: string,
  priceListId: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/pricelist/additem/${priceListId}`,
    data,
    token
  );
};

export const deletePriceListItem = async (
  token: string,
  priceListItemId: any
) => {
  return HttpRequest.delete(
    `${SERVER_URL}/pricelistitem/one/${priceListItemId}`,
    token
  );
};

export const updatePriceListItem = async (
  token: string,
  priceListItemId: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/pricelistitem/price/${priceListItemId}`,
    data,
    token
  );
};
export const addOneItemServiceRequest = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(
    `${SERVER_URL}/stockreq/additem/${id}`,
    data,
    token
  );
};

export const sendRequestService = async (
  id: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/stockreq/one/${id}`,
    data,
    token
  );
};

export const updateQntyApprovedRequest = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(
    `${SERVER_URL}/stockreqitem/one/${id}`,
    data,
    token
  );
};
export const deleteRequestStockItem = async (id: string, token: string) => {
  return HttpRequest.delete(`${SERVER_URL}/stockreqitem/one/${id}`, token);
};

export const updateAdjustStockService = async (
  // itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/warehouse/adjust`,
    data,
    token
  );
};

export const revertRequestStockItem = async (
  id: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/warehouse/revert/${id}`,
    data,
    token
  );
};


export const shopEligibleToproductService = async (token: string, id: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/stockreqitem/one/${id}/assignshop`,token
  );
}
