import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "csv",
  initialState: {
    isFetching: false,
    csvDownloadedData: null,
    allowcsvDownload: false,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setCSVDownloadedData(state, action) {
      state.csvDownloadedData = action.payload;
    },
    setAllowCSVDownload(state, action) {
      state.allowcsvDownload = action.payload;
    },
  },
});

export const myCSVAction = mySlice.actions;

export default mySlice.reducer;
