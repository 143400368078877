import React, { useState } from "react";
import { Button, Table, Tag, Modal, Popconfirm } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import { getCustomerPurchaseAction } from "../../../store/customer/actions";
import {
  getAllRepairsAction,
  updateRepairStatusAction,
} from "../../../store/repair/actions";
import RepairDrower from "../pages/RepairDrower";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import RepairDelivererOutForm from "../../../components/forms/RepairDelivererOutForm";

interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const CustomTable = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const [openModel, setOpenModel] = React.useState(false);
  const [completedDataId, setCompletedDataId] = useState("");
  const [rejectReqId, setRejectReqId] = useState<any>("");
  const [repairingId, setRepairingReqId] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>([]);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
  };

  const showCompleteModal = (d: any) => {
    setOpenModel(true);
    setCompletedDataId(d);
  };

  const handleCancelCompleteModal = () => {
    setOpenModel(false);
  };

  const onClose = () => {
    setOpen(false);
    setDataToUpdate([]);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.channel} </p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Specifications",
      dataIndex: "specifications",
      key: "specifications",
      width: 200,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Repaired At",
      dataIndex: "repairedAt",
      key: "repairedAt",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, _record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            {["technician", "admin", "dev"]?.includes(auth?.user?.role) && (
              <div className="flex items-end justify-center space-x-1">
                <Button
                  className="capitalize min-w-[50px] text-center outline-none"
                  onClick={() => {
                    showDrawer(record);
                    setDataToUpdate([...record?.data?.deviceIssues]);
                  }}
                >
                  View
                </Button>

                <Button
                  className="capitalize min-w-[50px] bg-green-500 text-white text-center border-none outline-none"
                  style={{ color: "white" }}
                  onClick={() =>
                    record.repairedAt === "shop"
                      ? updateRepairStatusAction(
                          record?.auth?.token,
                          record?.key,
                          { status: "completed" }
                        )(record?.dispatch)
                      : showCompleteModal(record)
                  }
                  disabled={[
                    "done",
                    "completed",
                    "transit-out",
                    "pending",
                    "cancelled",
                    "send-to-office",
                  ]?.includes(record?.status)}
                  loading={repairs?.isFetching}
                >
                  complete
                </Button>
                {record?.status === "pending" ? (
                  <Popconfirm
                    title="Reject request"
                    description="Are you sure to Reject this request?"
                    okButtonProps={{
                      loading: repairs?.isFetching,
                      className: "bg-blue-500",
                    }}
                    onConfirm={handleRejectReq}
                    okText="Yes"
                    cancelText="No"
                    disabled={record?.status !== "pending"}
                  >
                    <Button
                      danger
                      disabled={record?.status !== "pending"}
                      onClick={() => setRejectReqId(record)}
                    >
                      {" "}
                      Reject
                    </Button>
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="In-Repairing"
                    description="Are you sure this request has arrived?"
                    okButtonProps={{
                      loading: repairs?.isFetching,
                      className: "bg-blue-500",
                    }}
                    onConfirm={handleRepairingReq}
                    okText="Yes"
                    cancelText="No"
                    disabled={record?.status !== "transit-in"}
                  >
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: `${
                          record?.status === "transit-in" ? "blue" : "#dce0dc"
                        } `,
                      }}
                      disabled={record?.status !== "transit-in"}
                      onClick={() => setRepairingReqId(record)}
                    >
                      {" "}
                      Arrived
                    </Button>
                  </Popconfirm>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  const handleRejectReq = async () => {
    if (rejectReqId) {
      await updateRepairStatusAction(auth?.token, rejectReqId?.key, {
        status: "cancelled",
        isNotify: true,
        notification: {
          action: "Repair Cancelled",
          role: ["admin", "sales-supervisor", "sales-agent", "technician"],
          message: `${rejectReqId?.model} Request has been Rejected`,
          title: "Repair Cancelled",
        },
      })(dispatch);
      setRejectReqId("");
    }
  };

  const handleRepairingReq = async () => {
    if (repairingId) {
      await updateRepairStatusAction(auth?.token, repairingId?.key, {
        status: "repairing",
        isNotify: true,
        notification: {
          action: `Repairing ${repairingId?.model}`,
          role: ["admin", "sales-supervisor", "sales-agent", "technician"],
          message: `${repairingId?.model} is In-Repaire `,
          title: `Repairing ${repairingId?.model}`,
        },
      })(dispatch);
      setRepairingReqId("");
    }
  };

  React.useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customerId}`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      getAllRepairsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
  }, [auth.token, dispatch, limit, page]);

  const value = repairs?.all.data;
  React.useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          key: el._id,
          date_time: el?.createdAt?.slice(0, 10),
          shop: el?.cartItemId?.shopStockItem?.shop?.name,
          agent: el?.createdBy?.names,
          channel: el?.cartItemId?.shopStockItem?.shop?.channel?.name,
          model: el?.cartItemId?.priceProduct?.product?.model,
          imei: el?.cartItemId?.warehouseProduct?.serialNumber,
          specifications:
            el?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
              ?.slice(2, 6)
              ?.map((d: any) => d[1] && `${d[0]} : ${d[1]}`)
              ?.join(", "),
          customer: el?.cartItemId?.cart?.customer?.name,
          status: el?.status,
          handleCancelCompleteModal: handleCancelCompleteModal,
          setRejectReqId: setRejectReqId,
          dispatch: dispatch,
          auth: auth,
          repairedAt: el?.repairedAt,
          data: el,
        });
      });
    setformData(dataMapper);
  }, [repairs?.all]);

  return (
    <>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={repairs?.all?.total}
        count={Math.ceil(repairs?.all?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={formData}
          onChange={onChange}
          pagination={false}
          loading={repairs?.isFetching}
        />
      </ScrollableFrame>
      <RepairDrower
        open={open}
        onClose={onClose}
        data={data}
        dataToUpdate={dataToUpdate}
        setDataToUpdate={setDataToUpdate}
      />
      { }
      <Modal
        open={openModel}
        onCancel={handleCancelCompleteModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RepairDelivererOutForm
          onCancel={handleCancelCompleteModal}
          completedDataId={completedDataId}
        />
      </Modal>
    </>
  );
};

export default CustomTable;




