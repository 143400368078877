import React from "react";
import { Avatar, List, Card, Button, Progress, DatePicker, Divider } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import Applicants from "./applicants";
import ScheduleList from "./ScheduleList";
import StatisticsGraph from "./StatisticsGraph";



const messages = [
  {
    name: "Kate Kyansimire",
    message: "Greetings HR, I hope this message...",
  },
  {
    name: "James Nyiramubande",
    message: "Greetings HR, I hope this message...",
  },
  {
    name: "James Nyiramubande",
    message: "Greetings HR, I hope this message...",
  }
];

const opportunities = [
  {
    title: "Sales Agent",
    description: "4 Positions, Customer care Skills",
    time: "3 days ago",
  },
  {
    title: "Back-end Developer",
    description: "2 Positions, (Python,Java,Ruby)Skills",
    time: "3 days ago",
  },
  {
    title: "Logistic Manager",
    description: "1 Position, Management Skills",
    time: "3 days ago",
  },
  {
    title: "UI/UX designer",
    description: "1 Position, Figma, Sketch, Prototyping...",
    time: "4 days ago",
  },
  {
    title: "Secretary",
    description: "1 Position, Administration Skills",
    time: "6 days ago",
  },
];

const data = [
  { month: 'Jan', type: 'Applicants', value: 80 },
  { month: 'Jan', type: 'Shortlisted', value: 60 },
  { month: 'Jan', type: 'Rejected', value: 40 },
  { month: 'Feb', type: 'Applicants', value: 90 },
  { month: 'Feb', type: 'Shortlisted', value: 70 },
  { month: 'Feb', type: 'Rejected', value: 50 }
];



const Careers = (props: any) => {
  return (
      <div className="flex flex-col md:flex-row items-stretch mb-4 gap-3 pt-70px] min-h-screen">
        <div className="flex flex-col  w-[100%] md:w-[75%] h-auto pt-[35px]  overflow-y-auto mt-30">
          <div className="mb-4">
            <div className="flex flex-col md:flex-row justify-between items-center pb-3">
              <DatePicker />
              <Button type="primary" icon={<PlusOutlined />}>New Opening Job</Button>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="text-center h-[122px] w-[100%] md:w-[25%] flex flex-row  justify-around items-center bg-white rounded-md">
                <div className="flex  flex-col items-baseline">
                  <h1 className="text-xl font-semibold text-[#030229] m-0">31</h1>
                  <p className="text-[#030229] m-0 ml-2 text-md">Total Applicants</p>
                </div>
                <div> <Progress type="circle" percent={70} width={50} strokeColor="#57C362" /></div>
              </div>
              <div className="text-center h-[122px] w-[100%] md:w-[25%] flex flex-row  justify-around items-center bg-white rounded-md">
                <div className="flex  flex-col items-baseline">
                  <h3 className="text-xl font-semibold" style={{ color: '#030229' }}>8</h3>
                  <p className="text-[#030229] m-0 ml-2 text-md">Shortlisted</p>
                </div>
                <div>  <Progress type="circle" percent={56} width={50} strokeColor="#F17B2C" /></div>
              </div>
              <div className="text-center h-[122px] w-[100%] md:w-[25%] flex flex-row  justify-around items-center bg-white rounded-md">
                <div className="flex  flex-col items-baseline">
                  <h3 className="text-xl font-semibold" style={{ color: '#030229' }}>10</h3>
                  <p className="text-[#030229] m-0 ml-2 text-md">Rejected</p>
                </div>
                <div>  <Progress type="circle" percent={62} width={50} strokeColor="#AFADFF" /></div>
              </div>
              <div className="text-center h-[122px] w-[100%] md:w-[25%] flex flex-row  justify-around items-center bg-white rounded-md">
                <div className="flex  flex-col items-baseline">
                  <h3 className="text-xl font-semibold" style={{ color: '#030229' }}>10</h3>
                  <p className="text-[#030229] m-0 ml-2 text-md">In-Review</p>
                </div>
                <div> <Progress type="circle" percent={62} width={50} strokeColor="#82B1FF" /></div>
              </div>
            </div>
          </div>
          <div className="w-full h-auto  bg-white rounded-md">
            <StatisticsGraph />
          </div>
          <div className="flex flex-col md:flex-row items-center  gap-3 px-2 py-2">
            <Applicants /><ScheduleList />
          </div>
        </div>
        <div className="w-[100%] md:w-[350px]  p-4 shadow-lg overflow-y-auto bg-white absolute right-0 h-screen">
          <div className="flex flex-col items-center mb-4">
            <Avatar size={100} src="https://via.placeholder.com/100" />
            <h2 className="text-md font-semibold mt-2 text-[#0d0d1f]">Constantin MUCO</h2>
            <h3 className="text-sm text-gray-500">Head of Recruitment</h3>
          </div>
          <div>
          <Divider />
          </div>
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-md font-poppins font-normal text-left text-[#0d0d1f]">
                messages
              </h2>
              <a href="#" className="text-blue-500 text-sm">View All</a>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                  <h3 className="text-md font-medium text-[#030229]">Kate Kyansimire</h3>
                  <p className="text-gray-400 font-Poppins text-xs">Greetings HR, I hope this message... </p>
                </div>
              </div>
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                  <h3 className="text-md font-medium text-[#030229]">Kate Kyansimire</h3>
                  <p className="text-gray-400 font-Poppins text-xs">Greetings HR, I hope this message... </p>
                </div>
              </div>
            </div>

          </div>
          <div>
            <div className="flex justify-between items-center mb-2 px-3">
              <h2 className="text-md font-poppins font-normal text-left text-[#0d0d1f]">
                Recent Opportunities
              </h2>
              <a href="#" className="text-blue-500 text-sm">View All</a>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                <h3 className="text-md font-medium text-[#030229]">Secretary</h3>
                  <p className="text-gray-400 font-Poppins text-xs">1 Position, Management Skills</p>
                </div>
              </div>
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                <h3 className="text-md font-medium text-[#030229]">Secretary</h3>
                  <p className="text-gray-400 font-Poppins text-xs">1 Position, Management Skills</p>
                </div>
              </div>
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                <h3 className="text-md font-medium text-[#030229]">Secretary</h3>
                  <p className="text-gray-400 font-Poppins text-xs">1 Position, Management Skills</p>
                </div>
              </div>
              <div className="flex items-center space-x-3 pb-6 bg-[#605BFF08] opacity-3 rounded-md py-4 px-3">
                <img className="w-12 h-12 rounded-full object-cover" src={`https://via.placeholder.com/40`} alt="" />
                <div>
                  <h3 className="text-md font-medium text-[#030229]">Secretary</h3>
                  <p className="text-gray-400 font-Poppins text-xs">1 Position, Management Skills</p>
                </div>
              </div>

            </div>
            {/* <List
              className='px-3'
              itemLayout="horizontal"
              dataSource={opportunities}
              renderItem={(item, index) => (
                <List.Item className="bg-[#f4f4f5] rounded-md py-14">
                  <List.Item.Meta
                    avatar={<Avatar src={`https://via.placeholder.com/40`} />}
                    title={item.title}
                    description={item.description}
                  />
                  <div className="mb-4 text-gray-500 text-md">
                    <span className='px-2 py-2 text-xs'>{item.time}</span>
                  </div>
                </List.Item>
              )}
            /> */}
          </div>
        </div>
      </div>
  );
};

export default Careers;





