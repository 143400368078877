import { Button, Card, Select, Checkbox, Form, Input } from "antd";
import { InputNumber } from "antd/lib";
import React, { useEffect, useState } from "react";
import {
  getMessageTamplateAction,
  sendMessageAction,
  unLockDeviceAction
} from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const SendMessage = (props: any) => {
  const { auth,vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { TextArea } = Input;
  const [value, setValue] = useState('');
  const handleChange = (value: string) => {
    auth?.token &&
    getMessageTamplateAction(
      auth?.token,
      `?noticePeriod=${value}`
    )(dispatch);
  };
  useEffect(()=>{
    auth?.token &&
    getMessageTamplateAction(
      auth?.token,
      `?noticePeriod=${value}`
    )(dispatch);
  },[auth?.token,dispatch])
  const onSendMessage = async (values?: any) => {
    auth?.token &&
      (await sendMessageAction(auth?.token, {
        MessageList: [
          {
            notificationTitle: vault?.messageTamplate?.data[0]?.title,
            deviceUid: props?.data?.deviceUniqueID,
            notificationType: "HEADSUP",
            notificationContent: vault?.messageTamplate?.data[0]?.content
          }
        ],
        accountId: "65533064ae06d4f32a4c299a"
      })(dispatch));
    props?.onClose();
  };
  return (
    <>
      <div>
      <Form
    name="basic"
    style={{ maxWidth: 600 }}
    initialValues={{ remember: true }}
    onFinish={onSendMessage}
    autoComplete="off"
  >
     <Form.Item name="notificationTitle" className="w-full">
        <Select
          style={{ width: "100%" }}
          defaultValue="0"
          onChange={handleChange}
          options={[
            { value: "7", label: "Payment Due In 7 Days" },
            { value: "3", label: "Payment Due In 3 Days" },
            { value: "1", label: "Payment Due In 1 Days" },
            {
              value: "0",
              label: "Device is restricted for non-Payment"
            }
          ]}
        />
        </Form.Item>
          <Form.Item name="notificationContent" className="w-full">
        <div className="flex gap-2 text-[#030229] w-full  mt-5">
          <h1 className="pt-5 w-[10rem] text-[13px]">Message Content</h1>
          <Input.TextArea
          disabled
          className="p-4 w-full"
        value={vault?.messageTamplate?.data[0]?.content}
        onChange={(e) =>( setValue(e.target.value))}
        // placeholder="Controlled autosize"
        autoSize={{ minRows: 3, maxRows: 7 }}
      />
        </div>
      </Form.Item>
      <Form.Item  className="w-full">
        <div className="flex gap-3 ml-[11rem] mt-10">
          <Button
          
            className="w-32 border border-red-400"
          >
            Cancel
          </Button>
          <Button
          htmlType="submit"
            className="w-32  border border-[#605BFF]"
          >
            {vault?.isFetching?  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />:"Send"} 
          </Button>
        </div>
        </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default SendMessage;