import React from 'react';
import { Table } from 'antd';
import SettingButton from '../../../../pages/dashboard/setting/SettingButton';



const ManageItemTable = (props:any) => {
  const columns= [
    {
      title: 'Item Code',
      dataIndex: 'code',
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
    },
    {
      title: 'Qty Unit',
      dataIndex: 'unit',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'purchase',
    },
    {
      title: 'Sale Price',
      dataIndex: 'sale',
    },
    {
      title: 'Beginning Stock',
      dataIndex: 'stock',
    },
    {
      title: ' ',
      dataIndex: 'data',
      render :()=>(
          <>
          <button className='text-[#605BFF] border border-[#605BFF] px-3 py-1 rounded-md' onClick={props?.open}>View</button>
          </>
      )
    },
  ];
  
  const data= [
    {
      key: '1',
      code:"CA2CTXU000000020",
      name: 'Sumsung Galaxy S23 Plus (25GB)',
      unit:"Prices/item [Number]",
      purchase:"y",
      sale:"y",
      stock:"0"
    },
    {
      key: '2',
      code:"CA2CTXU000000020",
      name: 'Sumsung Galaxy S23 Plus (25GB)',
      unit:"Prices/item [Number]",
      purchase:"y",
      sale:"y",
      stock:"0"
    },
    {
      key: '3',
      code:"CA2CTXU000000020",
      name: 'Sumsung Galaxy S23 Plus (25GB)',
      unit:"Prices/item [Number]",
      purchase:"y",
      sale:"y",
      stock:"0"
    },
    {
      key: '4',
      code:"CA2CTXU000000020",
      name: 'Sumsung Galaxy S23 Plus (25GB)',
      unit:"Prices/item [Number]",
      purchase:"y",
      sale:"y",
      stock:"0"
    },
    {
      key: '5',
      code:"CA2CTXU000000020",
      name: 'Sumsung Galaxy S23 Plus (25GB)',
      unit:"Prices/item [Number]",
      purchase:"y",
      sale:"y",
      stock:"0"
    },
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default ManageItemTable;