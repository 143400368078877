import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from 'react-redux';
type btnProps = {
    cardIcon?: any;
    value?: any;
    title?: any;
    percentage?: any;
  };
const Card = ({ cardIcon, value, title, percentage }: btnProps) => {
    const {channel}=useSelector((state:any)=>state)
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className='bg-white flex gap-5 w-[315px] p-4 rounded-md'>
    <div className='border border-[#615bff25] rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2'>
     <img src={cardIcon} alt="*image" />
    </div>
    <div>
     <h1 className='text-[#030229] font-semibold text-lg'> {channel.isFetching ? <Spin indicator={antIcon} /> : value}</h1>
     <p className='text-[#030229] text-ellipsis font-thin text-base opacity-90'>{title}</p>
    </div>

   </div>
  )
}

export default Card