import HeaderComponent from "../../../components/HeaderComponent";
import {
  Paper,
  Stack,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import QuotationTable from "../../../components/tables/QuotationTable";
import QuotationGrid from "../../../components/grids/QuotationGrid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddNewQuotation from "../../../components/forms/NewQuotationForm";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllQuotationSupplierAction,
  getAllQuotationsAction,
  createQuotationAction,
} from "../../../store/quotation/actions";
import { myQuotationActions } from "../../../store/quotation";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { rfqIncludeItem } from "../../../assets/data/includedItem";

const QuotationFilter = (props: any) => {
  const { quotation, auth } = useSelector((state: any) => state);

  console.log(auth);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [supplier, setSupplier] = useState("");

  dispatch(
    myQuotationActions.setQuery(
      `?${type && `status=${type}&`}${supplier && `supplier=${supplier}&`}`
    )
  );
  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setSupplier("");
    }
  };
  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          Filter
        </Button>

        {selectedFilters && (
          <Stack
            spacing={1}
            direction="row"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              allowClear
              placeholder="Filter By Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={quotation.isFetching}
              disabled={quotation.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={quotation?.filters?.status.map((stat: any) => ({
                label: stat._id.toUpperCase(),
                value: stat._id,
              }))}
              onChange={(value: any) => {
                // console.log("::", value);
                !value && setType("");
                value && setType(value);
              }}
            />
            {quotation?.filters?.suppliers && (
              <Select
                showSearch
                style={{ width: 200 }}
                allowClear
                placeholder="Filter By Supplier"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={quotation.isFetching}
                disabled={quotation.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={quotation?.filters?.suppliers.map((stat: any) => ({
                  label: stat?.name.toString().toUpperCase(),
                  value: stat._id,
                }))}
                onChange={(value: any) => {
                  // console.log("::", value);
                  !value && setSupplier("");
                  value && setSupplier(value);
                }}
              />
            )}
          </Stack>
        )}
        <h1 className="text-gray-400">
          Result:{" "}
          {quotation?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            quotation?.allRfqSupplier?.result
          )}
        </h1>
      </Stack>
    </Paper>
  );
};

const AddNewQuotationButton = (props: any) => {
  const navigate = useNavigate();
  const [showDrafted, setShowDrafted] = useState(false);
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleAddNewQuotation = () => {
    auth?.token &&
      createQuotationAction(auth?.token, {
        isNotify: true,
        notification: {
          action: "RFQ Created",
          role: ["admin", "finance", "finance-manager", "inventory"],
          message: `New RFQ has been Created`,
          title: "Request for Quotation",
        },
      })(dispatch);
    console.log("clicked");
  };
  useEffect(() => {
    quotation?.new &&
      navigate(
        `/${props?.route === "purchase" ? "purchase" : "inventory"}/rfq/${
          quotation?.new?.data.quotationId
        }`
      );
  }, [navigate, props?.route, quotation?.new]);
  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Tooltip title={showDrafted ? "Hidde Drafted RFQs" : "Show Drafted RFQs"}>
        <IconButton
          // color="primary"
          sx={{ p: 1 }}
          aria-label="directions"
          onClick={() => {
            setShowDrafted(!showDrafted);
            props.setShowDrafted(!showDrafted);
          }}
        >
          {showDrafted ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <LoadingButton
        variant="contained"
        startIcon={<AddCircleIcon />}
        onClick={() => handleAddNewQuotation()}
        loading={quotation?.isFetching}
      >
        New RFQ
      </LoadingButton>
    </Stack>
  );
};
const PageView = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  const [showDrafted, setShowDrafted] = useState(true);

  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(24);
  const [page, setPage] = useState(1);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    dispatch(myQuotationActions.setSelected(null));
    dispatch(myQuotationActions.setNewRfq(null));
    auth?.token &&
      getAllQuotationSupplierAction(
        auth?.token,
        `${quotation?.query}limit=${limit}&page=${
          page - 1
        }&field=${rfqIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, quotation?.query, page, limit]);

  useEffect(() => {
    auth?.token &&
      getAllQuotationsAction(
        auth?.token,
        `?isDraft=true&status=draft`
      )(dispatch);
  }, [auth?.token, quotation?.query, quotation.allRfqSupplier.data, dispatch]);
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2 pb-2">
        <DetailsHeaderActionBar
          pageName="Request For Quotation"
          title=" "
          goBack={goBack}
        />
      </div>
      <HeaderComponent
        // title="Manage Quotations"
        item="Quotation"
        modelTitle="Add Quotation"
        isNotCollapse={true}
        isNotAddButton={true}
        filterCard={<QuotationFilter />}
        ModelComponent={<AddNewQuotation />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
        setIsGridView={setIsGridView}
        goForward="/purchase/rfq/new"
        OtherButton={<AddNewQuotationButton setShowDrafted={setShowDrafted} />}
      />
      {isGridView && (
        <Stack spacing={1}>
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={quotation?.allRfqSupplier?.total}
            count={Math.ceil(quotation?.allRfqSupplier?.total / limit)}
          >
            <Box>
              <div className="flex flex-wrap gap-5 justify-start items-start">
                <QuotationGrid data={quotation?.allRfqSupplier?.data} />
              </div>
            </Box>
            {showDrafted && (
              <Box sx={{ pt: 2 }}>
                <Stack direction="column" spacing={1}>
                  <span className="space-x-1 text-black font-medium">
                    RFQs Drafted:
                    {quotation.isFetching ? (
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    ) : (
                      quotation?.all?.result
                    )}
                  </span>
                  <div className="flex flex-wrap gap-5 justify-start items-start">
                    <QuotationGrid data={quotation?.all?.data} draft={true} />
                  </div>
                </Stack>
              </Box>
            )}
          </ScrollableFrame>
        </Stack>
      )}
      {!isGridView && (
        <Paper elevation={2}>
          <Stack spacing={1}>
            <QuotationTable data={quotation?.all?.data} />
          </Stack>
        </Paper>
      )}
    </Stack>
  );
};

export default PageView;
