/* eslint-disable no-mixed-operators */
//@ts-nocheck
import React, { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { IoIosMore } from "react-icons/io";
import Ellipse from "../../../../assets/Ellipse2323.png";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { GrFormNext } from "react-icons/gr";

const CurrentPlanSummary: React.FC = () => {
  const { account, deliverynote, orders } = useSelector((state: any) => state);
  const [currentContactIndex, setCurrentContactIndex] = useState(0);
  const nextContact = () => {
    setCurrentContactIndex(
      (prevIndex) => (prevIndex + 1) % account.selected.contacts.length
    );
  };

  const addressData =
    account?.selected?.customerDetails?.address &&
    account?.selected?.customerDetails?.address?.split(", ");

  return (
    <div className="text-black w-[100%] rounded-md bg-white xmd:h-48 flex flex-col md:flex-row justify-between px-6 py-4">
      {account?.isFetching ? (
        <Skeleton />
      ) : (
        <>
          <div className="flex flex-col md:flex-row  gap-6 pb-2">
            <div>
              <Avatar
                alt="account image"
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVw9x0c3GglD7ozfJFUyOdT4eFDuH9mxawEA&usqp=CAU"
                }
                sx={{ width: "3rem", height: "3rem" }}
              />{" "}
            </div>
            <div className="space-y-2 font-medium">
              <div className="font-poppins text-lg  leading-normal text-[#030229] capitalize">
                {account?.selected?.customerDetails?.name}
              </div>
              <div className="flex items-center space-x-2">
                <span>Tel:</span>
                <span className="text-gray-800">
                  {" "}
                  {account?.selected?.customerDetails?.tel}
                </span>{" "}
              </div>
              <div>
                <span>Email: </span>
                <span className="text-gray-800">
                  {account?.selected?.customerDetails?.email}
                </span>
              </div>
              <div className="text-[#030229] font-poppins text-base font-normal leading-normal flex flex-row">
                <span className="capitalize pl-1">
                  {addressData?.map((line: any, index: number) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />} {line?.trim()}{" "}
                    </React.Fragment>
                  ))}
                </span>
              </div>
              <div className="bg-green-500 rounded-md px-4  text-white w-20 cursor-pointer">
                {account.selected?.channel?.status}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2 ">
            {account?.selected?.contacts?.length > 0 && (
              <div className="flex flex-row   rounded-md  items-center">
                <div>
                  <div className="flex flex-row  gap-10 space-y-2">
                    <div className="flex flex-row  items-center gap-10 justify-between">
                      <div className="font-poppins text-base leading-normal text-[#030229] pl-2">
                        Contacts person
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="px-2">Name:</span>
                      <span
                        className={`text-gray-400 ${
                          account.selected.contacts[currentContactIndex]
                            .nameWarning
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        {account.selected.contacts[currentContactIndex].name}
                      </span>
                    </div>
                    <div>
                      <span className="px-2">Phone: </span>
                      <span
                        className={`text-gray-400 ${
                          account.selected.contacts[currentContactIndex]
                            .phoneWarning
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        {account.selected.contacts[currentContactIndex].phone}
                      </span>
                    </div>
                    <div>
                      <span className="px-2">Email </span>
                      <span
                        className={`text-gray-400 ${
                          account.selected.contacts[currentContactIndex]
                            .relationshipWarning
                            ? "text-red-500"
                            : ""
                        }`}
                      >
                        {(account?.selected?.contacts &&
                          account?.selected?.contacts[currentContactIndex]
                            ?.email) ||
                          " N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-4 pl-2">
                    <div className="text-sm text-gray-500">
                      {currentContactIndex + 1} /{" "}
                      {account.selected.contacts.length}
                    </div>
                    {account.selected.contacts.length > 1 && (
                      <div
                        className="mt-4 p-2 text-[#605BFF] border border-[#EBEFF2] rounded-md cursor-pointer"
                        onClick={nextContact}
                      >
                        <GrFormNext />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="flex flex-wrap gap-4">
                        <div className=" text-gray-500 text-2xl cursor-pointer ">
                            <p className="rounded-full border-gray-500 border p-2">
                                <MdOutlineMail />
                            </p>
                        </div>
                        <div className=" rounded-lg text-gray-500 text-2xl cursor-pointer border-gray-400">
                            <a href={`tel:+250787486966`}>
                                {" "}
                                <p
                                    className="rounded-full border-gray-500 border p-2"
                                    onClick={handleCall}
                                >
                                    <IoCallOutline />
                                </p>
                            </a>
                        </div>
                        <div className=" rounded-lg text-gray-500 text-2xl cursor-pointer border-gray-400">
                            <p className="rounded-full border-gray-500 border p-2">
                                <IoIosMore />
                            </p>
                        </div>
                    </div> */}
          <div className="flex flex-col md:flex-row gap-2 pb-2 ">
            <div className="space-y-2 w-96 pt-3">
              <div className="flex items-center justify-between font-medium">
                <span>Order Date:</span>
                <span className="text-gray-800">
                  {" "}
                  {account?.selected?.updatedAt?.slice(0, 10)}
                </span>{" "}
              </div>
              <div className="flex items-center justify-between font-medium">
                <span>PO#:</span>
                <span className="text-gray-800">
                  {" "}
                  {orders?.selected?.SOID}
                </span>{" "}
              </div>
              <div className="flex items-center justify-between font-medium">
                <span>Delivery Note#:</span>
                <span className="text-gray-800">
                  {" "}
                  {deliverynote?.singleDeliveryNote?.SDNID}
                </span>{" "}
              </div>
              <div className="flex items-center justify-between font-medium">
                <span>Customer ID:</span>
                <span className="text-gray-800">
                  {" "}
                  {account?.selected?.accountID}
                </span>{" "}
              </div>
              <div className="flex items-center justify-between font-medium">
                <span>Despatch Date:</span>
                <span className="text-gray-800"> {"N/A"}</span>{" "}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default CurrentPlanSummary;
