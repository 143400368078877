import React, { useEffect } from "react";

const Knowledge = () => {
  const randomQueryParam = `nocache=${Math.random()}`;

  const embedUrl = `https://knowledge.samphone.africa/?${randomQueryParam}`;

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      sessionStorage.clear();
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.clear();
      });
    };
  }, []);

  return (
    <div className="relative h-full">
      <embed
        src={embedUrl}
        width="100%"
        height={700}
        className="w-full h-[95vh] -mb-14"
      />
    </div>
  );
};

export default Knowledge;
