import { myCSVAction } from ".";
import { getPurchasedItemsService } from "../inventory/services";
import { myLayoutActions } from "../layout";
import { getAllNosaleService } from "../pos/services";
import { getAllService } from "../product/services";
import { getAllPurchaseOrdersService } from "../purchase/services";
import {
  getAllSaveTheChildrenService,
  getAllStockReportService,
  getAllTransferReportService,
} from "../report/services";
import { getStockStats } from "../wareHouse/services";
import { getFinanceTransactionService } from "./services";

export const getFinanceTransaction_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getFinanceTransactionService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
        dispatch(myLayoutActions.setSelectedShop(""));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
        dispatch(myLayoutActions.setSelectedShop(""));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllNoSales_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllNosaleService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllProducts_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getStock_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getStockStats(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllPO_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllPurchaseOrdersService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllPurchased_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getPurchasedItemsService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllStockReport_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllStockReportService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllTransferReport_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllTransferReportService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllSaveTheChildrenReport_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllSaveTheChildrenService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
