import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { DefaultStaticRanges } from "./defaultRanges";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { getSalesStatisticsAction } from "../../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";


interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateRangeSelectorProps {
  ranges?: any[];
  onSubmit?: (selectedRange: DateRange) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  ranges = DefaultStaticRanges,
  onSubmit,
}) => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const startDates = new Date(selectedDateRange?.startDate)
    .toISOString()
    .split("T")[0];
  const endDates = new Date(selectedDateRange?.endDate)
    .toISOString()
    .split("T")[0];

  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  useEffect(() => {
    auth?.token &&
      getSalesStatisticsAction(
        auth?.token,
        `date1=${startDates}&date2=${endDates}`
      )(dispatch);
  }, [startDates, endDates, auth?.token, dispatch]);

  return (
    <>
      <div className="shadow overflow-x-auto md:overflow-x-hidden">
        <DateRangePicker
          onChange={handleSelect}
          showPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDateRange]}
          direction="vertical"
          locale={enUS}
        />
      </div>
    </>
  );
};
export default DateRangeSelector;




