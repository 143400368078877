import { LoadingButton } from "@mui/lab";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  approveInvoicePaymentAction,
  getAllAccountInvoiceAction,
} from "../../store/invoice/actions";

const ConfirmSubInvoiceForm: React.FC<{
  itemId: string;
  onCancel: any;
}> = ({ itemId, onCancel }) => {
  const { auth, invoice, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();

  const onFinish = async (values: any) => {
    if (auth?.token && itemId) {
      await approveInvoicePaymentAction(itemId, auth?.token, {
        ...values,
      })(dispatch);

      onCancel();

      await getAllAccountInvoiceAction(
        auth?.token,
        `?account=${accountId}`
      )(dispatch);
    }
    form.resetFields();
  };
  const [form] = Form.useForm();

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
      >
        <Form.Item
          name="amountPaid"
          label="Amount Paid"
          rules={[
            {
              required: true,
              message: "Amount is required!",
              whitespace: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          name="momoTransactionId"
          label="Transaction Id"
          rules={[
            {
              required: true,
              message: "Transaction Id is required!",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={account?.isFetching || invoice?.isFetching}
          >
            {"Submit"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmSubInvoiceForm;
