import { ReactComponent as ItemIcons } from "../images/icons/ph_shopping-cart.svg";
import { ReactComponent as ManageIcons } from "../images/icons/Group.svg";
import { ReactComponent as OverviewIcons } from "../images/icons/heroicons_users (1).svg";
import { ReactComponent as DashIcons } from "../images/icons/ri_dashboard-3-line.svg";
import { ReactComponent as PurchaseIcons } from "../images/icons/solar_money-bag-outline (1).svg";
import { ReactComponent as ImportIcons } from "../images/icons/convert-3d-cube.svg";
import { ReactComponent as StockIcons } from "../images/icons/house.svg";

export const EbmPage=[
    {
        id:1,
        title:<h1>Overview</h1>,
        icon:<DashIcons/>,
        activeTab:"ebmOverview"
    },
    {
        id:2,
        title:<h1>Item Management</h1>,
        icon:<ItemIcons/>,
        activeTab:"ebmItem"
    },
    {
    id:3,
        title:<h1>Custoner Management</h1>,
        icon:<OverviewIcons />,
        dropDown:[
            {
                menu:"Customer",
                activeTab:"ebmCustomer"
            },
            {
                menu:"Insurer",
                activeTab:"ebmInsurer"
            }
        ]
    },
    {
        id:4,
        title:<h1>Sales Management</h1>,
        icon:<ManageIcons/>,
        activeTab:"ebmSales"
    },
    {
        id:5,
        title:<h1>Purchase Management</h1>,
        icon:<PurchaseIcons/>,
        activeTab:"ebmPurchase"
    },
    {
        id:6,
        title:<h1>Import Management</h1>,
        icon:<ImportIcons/>,
        activeTab:"ebmImport"
    },
    {
        id:7,
        title:<h1>Stock Management</h1>,
        icon:<StockIcons/>,
        activeTab:"ebmStock"
    },
]