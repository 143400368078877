import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllShopStockService = async (token: string, id: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/warehouseByproduct/${id}`, token);
};

export const getStockItemsStats = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse?${query || ""}`, token);
};

export const getRevertItem = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/warehouse/${itemId}`,
    data,
    token
  );
};

export const getPurchasedItemsService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/deliverItem/dateRange?${query || ""}`,
    token
  );
};
export const getProductHistoryService = async (
  token: string,
  query: string
) => {
  return HttpRequest.get(
    `${SERVER_URL}/shopstockitem/history?${query || ""}`,
    token
  );
};

export const getStockRequestOverViewService = async (
  token: string,
  query: string 
) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/stockReport${query}`, // Append query directly to the URL
    token
  );
};


export const getTopSellingProductService = async (
  token: string,
  query: string 
) => {
  return HttpRequest.get(
    `${SERVER_URL}/stats/warehouseBrands${query}`, 
    token
  );
};

