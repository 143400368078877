import HeaderComponent from "../../../../components/HeaderComponent";
import { Stack, Skeleton } from "@mui/material";
import WareHouseOutGrid from "../../../../components/grids/warehouseOut/WareHouseOutGrid";
import StockOutGrid from "../../../../components/grids/warehouseOut/StockOutGrid";
import RequestGrid from "../../../../components/grids/RequestGrid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ButtonGroupComponent from "../../../../components/buttons/ButtonGroupComponent";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  getAllWarehouseOutAction,
  getAllStockRequestSentAction,
} from "../../../../store/wareHouseOut/actions";
import WarehouseOutFilter from "../../../../components/filters/WarehouseOutFilter";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { myChannelActions } from "../../../../store/channel";
import { warehouseOutIncludeItem } from "../../../../assets/data/includedItem";

const groupByOptions = [
  { label: "Stock To be Approved", value: "review" },
  { label: "Stock In Transit", value: "done" },
  { label: "Stock Pending In Warehouse", value: "approved" },
  { label: "Stock Request", value: "request" },
];

const groupByOptionsWout: any = [
  { label: "Stock Approved", value: "approved" },
  { label: "Stock In Transit", value: "In-Transit" },
  { label: "Pending Stock", value: "Pending" },
  { label: "Received stock", value: "completed" },
];

const PageView = (props: any) => {
  const isChannel = props.route === "channel";
  const [selectedValueButton, setSelectedValueButton] = useState<any>(
    isChannel ? "review" : "approved"
  );

  const { wareHouseOut, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token &&
      getAllStockRequestSentAction(
        auth?.token,
        `?status=${
          selectedValueButton.value ?? (isChannel ? "review" : "approved")
        }&limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
      )(dispatch);
    auth?.token &&
      getAllWarehouseOutAction(
        auth?.token,
        `?status=${selectedValueButton.value}&limit=${limit}&page=${
          page - 1
        }&field=${warehouseOutIncludeItem}`
      )(dispatch);
  }, [auth?.token, selectedValueButton, dispatch, isChannel, limit, page]);

  const values = wareHouseOut?.allStockRequested?.data;
  const stockOutData: {
    id: any;
    warehouseOutId: any;
    shopName: any;
    status: any;
    date: any;
    data: any;
  }[] = [];

  wareHouseOut?.warehouseOut?.data?.forEach((el: any) => {
    stockOutData?.push({
      id: el?._id,
      warehouseOutId: el?.warehouseOutId,
      shopName: el?.list[0]?.shop?.name,
      status: el?.status,
      data: el,
      date: el?.createdAt?.split("T")[0],
    });
  });

  const data: {
    id: any;
    requestId: any;
    shopName: any;
    status: any;
    numberReq: any;
    date: any;
    data: any;
  }[] = [];

  values &&
    values.forEach((el: any) => {
      data.push({
        id: el.requestId,
        requestId: el?.requestId,
        shopName: el?.shopId?.name,
        status: el?.status,
        data: el,
        date: el?.createdAt?.split("T")[0],
        numberReq: el?.list?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtyRequested),
          0
        ),
      });
    });

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(myChannelActions.setRfsSelected(null));
  }, []);

  return (
    <Stack spacing={1}>
      <div className="text-black pt-5 pb-2">
        <DetailsHeaderActionBar
          title={"Out"}
          pageName={props.route ?? "Warehouse"}
          goBack={goBack}
        />
      </div>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <HeaderComponent
          isNotCollapse={true}
          isNotAddButton={true}
          filterCard={
            !isChannel && (
              <WarehouseOutFilter
                status={selectedValueButton.value}
                page={page}
                limit={limit}
                isChannel={isChannel}
              />
            )
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
        <div>
          {
            <ButtonGroupComponent
              options={
                props.route === "channel" ? groupByOptions : groupByOptionsWout
              }
              setSelectedValueButton={setSelectedValueButton}
            />
          }
        </div>
      </Stack>

      <ScrollableFrame
        loading={wareHouseOut.isFetching}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={
          wareHouseOut?.warehouseOut?.total ||
          wareHouseOut?.allStockRequested?.total
        }
        count={Math.ceil(
          (wareHouseOut?.warehouseOut?.total ||
            wareHouseOut?.allStockRequested?.total) / limit
        )}
      >
        <Stack spacing={1}>
          {props.route === "channel" && (
            <Box>
              {/* <div className="flex flex-wrap gap-5 justify-start items-start"> */}
              {wareHouseOut.isFetching ? (
                <div
                  className={`grid ${
                    layout?.isSideNavOpen
                      ? "sm:grid-cols-1 lg:grid-cols-2"
                      : "sm:grid-cols-2 lg:grid-cols-3"
                  } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                >
                  {Array.from({ length: 12 }).map(() => (
                    <Box sx={{}}>
                      <Skeleton animation="wave" />
                      <Skeleton variant="rectangular" height={110} />
                    </Box>
                  ))}
                </div>
              ) : (
                <div
                  className={`grid ${
                    layout?.isSideNavOpen
                      ? "sm:grid-cols-1 lg:grid-cols-2"
                      : "sm:grid-cols-2 lg:grid-cols-3"
                  } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
                >
                  <RequestGrid data={values} />
                </div>
              )}
            </Box>
          )}

          {props.route !== "channel" && (
            <>
              <Box>
                <div className="flex flex-wrap gap-5 justify-start items-start">
                  {wareHouseOut.isFetching && (
                    <Stack direction={"row"} spacing={2}>
                      {Array.from({ length: 3 }).map(() => (
                        <Box sx={{ width: 270 }}>
                          <Skeleton sx={{ height: 70, m: 0 }} />
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Skeleton animation="wave" sx={{ width: "40%" }} />
                            <Skeleton animation={false} sx={{ width: "30%" }} />
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {!wareHouseOut.isFetching && <WareHouseOutGrid data={data} />}
                </div>
              </Box>
              <Box>
                <div className="flex flex-wrap gap-5 justify-start items-start mt-4">
                  {wareHouseOut.isFetching && (
                    <Stack direction={"row"} spacing={2}>
                      {Array.from({ length: 3 }).map(() => (
                        <Box sx={{ width: 270 }}>
                          <Skeleton sx={{ height: 70, m: 0 }} />
                          <Stack
                            direction={"row"}
                            justifyContent="space-between"
                          >
                            <Skeleton animation="wave" sx={{ width: "40%" }} />
                            <Skeleton animation={false} sx={{ width: "30%" }} />
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {!wareHouseOut.isFetching && (
                    <StockOutGrid route={props?.route} data={stockOutData} />
                  )}
                </div>
              </Box>
            </>
          )}
        </Stack>
      </ScrollableFrame>
    </Stack>
  );
};

export default PageView;
