import React from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TwoIconsCard from "../components/cards/TwoIconsCard";
import { GoPlus } from "react-icons/go";
import { ReactComponent as NewIcons } from "../assets/images/icons/Group 1000002857.svg";
import { ReactComponent as AmountIcons } from "../assets/images/icons/Group 1000002860.svg";
import { ReactComponent as CustomerAmountIcons } from "../assets/images/icons/Group 1000002860_1.svg";
import { ReactComponent as VectorIcons } from "../assets/images/icons/Vector (1).svg";
import { ReactComponent as PurchaseIcons } from "../assets/images/icons/Group 8936 (1).svg";
import { ReactComponent as CartIcons } from "../assets/images/icons/Group 1000002857 (1).svg";
import { ReactComponent as PIcons } from "../assets/images/icons/Group 8936 (5).svg";
import { ReactComponent as StockIcons } from "../assets/images/icons/Group 8936.svg";
import { ReactComponent as RRAIcons } from "../assets/images/icons/RRA logo 1.svg";
import OverviewChart from "../components/OverviewChart";
import OverViewPieChart from "../components/OverviewPieChart";
import Timer from "../components/Timer";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const Overview = () => {
  return (
    <div>
      <div className="flex justify-between ">
        <div className="flex gap-5">
          <RRAIcons />
          <Timer/>
        </div>
        <div className="flex gap-8 h-10 mt-5">
          <h1 className="pt-1">From</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
          <h1 className="pt-1">To</h1>
          <DatePicker
            defaultValue={dayjs("2019-09-03", dateFormat)}
            minDate={dayjs("2019-08-01", dateFormat)}
            maxDate={dayjs("2020-10-31", dateFormat)}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5 mt-7">
        <TwoIconsCard
          amount={"650"}
          title={"Total Items"}
          name={"10 nwe Item Added!"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<CartIcons />}
        />
        <TwoIconsCard
          amount={"600"}
          title={"Total Customer"}
          name={"20 New Customer added!"}
          icon={<GoPlus color="#43900C" />}
          iconTwo={<CustomerAmountIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Total Sales"}
          name={"20 Waiting for approval"}
          iconTwo={<AmountIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Total Purchase"}
          name={"20 Waiting for Approval"}
          iconTwo={<PIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Total Import"}
          name={"20 Waitiing for approval"}
          iconTwo={<PurchaseIcons />}
        />
        <TwoIconsCard
          amount={"50"}
          title={"Total in Stock"}
          name={"Increased than Yesterday"}
          icon={<VectorIcons color="#43900C" />}
          iconTwo={<StockIcons />}
        />
      </div>
      <div className="flex gap-5 mt-5 flex-wrap">
        <div className="1/2 bg-white p-4 rounded-md">
          <div className="flex justify-between w-full my-3">
            <h1 className="text-[#030229] text-lg ">
              Sales and Purchase Management
            </h1>
            <div className="flex gap-5">
              <div className="flex gap-2  ">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#AFADFF] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p>Sales</p>
              </div>
              <div className="flex gap-2">
                <p className="text-[#030229] text-[12px] mt-1 font-light flex justify-center items-center bg-[#DEDEDE] w-4 h-4 rounded-full ">
                  {" "}
                </p>
                <p>Purchase</p>
              </div>
            </div>
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Monthly"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "Monthly"
                }
              ]}
            />
          </div>
          <OverviewChart />
        </div>
        <div className="1/2 bg-white p-4">
          <h1 className="text-[#030229] text-lg pl-6">Import Summary</h1>
          <div className="mt-8 relative  right-20">
            <OverViewPieChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
