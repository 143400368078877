import React from "react";
import { searchValue } from "../../../utils/setColor";
import { Tag } from "antd";

const RecentSearchCard = (props: any) => {
  return (
    <div
      className="shadow-md border-l-[3px] py-3 px-5 w-[500px] bg-white cursor-pointer border-[#2943D6] rounded-md"
      onClick={props.onClick}
    >
      <div className="flex justify-between">
        <h1 className="text-[#030229] font-medium text-xl">
          {props?.data?.product?.model}
        </h1>
        <div className="flex">
          <p className=" py-1 rounded-[5px] text-sm">Status: </p>
          <Tag
            color={searchValue(props?.data?.status)}
            className="py-[1px] w-32 ml-5 text-base text-center"
          >
            {props?.data?.status}
          </Tag>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          <p className="text-[#605BFF] text-sm py-1">
            S/N: {props?.data?.serialNumber}
          </p>
          {props?.data?.quotationItem?.specification?.map((d: any) => (
            <div className="flex gap-2">
              <p className="text-[#030229B2] text-sm py-1">{d[0]}:</p>
              <p className="text-[#030229B2] text-sm py-1">{d[1]}</p>
            </div>
          ))}
        </div>
        <div className="mt-3">
          <div className="text-sm flex gap-1">
            {" "}
            <span className="pt-2">Channel: </span>{" "}
            <div className=" w-32 bg-[#FFCB05]  px-2 py-1 text-center rounded-[5px]">
              {" "}
              <span className="text-[12px]">
                {props?.data?.shopStock?.channel?.name}
              </span>
            </div>
          </div>
          <div className="mt-5 ml-7 text-sm flex gap-1">
            <span className="pt-2">Shop: </span>
            <div className=" w-32 bg-[#0302291b] text-center px-2 py-1 rounded-[5px]">
              {" "}
              <span className=" text-[12px] ">
                {props?.data?.shopStock?.name}
              </span>
            </div>
          </div>
          {/* <div className="mt-11 ml-20 text-sm">
              Date: {props?.data?.shopStock?.createdAt?.slice(0, 10)}
            </div> */}
        </div>
      </div>
    </div>
  );
};
export default RecentSearchCard;
