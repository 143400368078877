import { Carousel } from 'antd'
import React from 'react'
import phoneImg from "../assets/images/Apple-iPhone-15-Pro Max 500px by 500px 1.png"

const ItemCardInfo = () => {
  return (
    <div>
        
        <div className="">
                  <div className="bg-[#E5E5E5] w-[100px] h-[100px] p-2 rounded-xl">
                    <Carousel autoplay className="w-full h-full">
                          <div>
                            <img src={phoneImg} className="w-full h-full" alt="" />
                          </div>
                        
                    </Carousel>
                  </div>
                  <div className="mt-4">
                    <h1 className="text-[#030229] font-semibold text-lg">
                    iPhone 15 Pro
                    </h1>
                    <p className="mt-1">
                      <span className="text-[#030229] text-sm">Type:</span>{" "}
                      <span className="text-[#030229B2] text-sm pl-1 font-light">
                        Samsung
                      </span>
                    </p>
                    <p>
                      <span className="text-[#030229] text-sm">Brand:</span>{" "}
                      <span className="text-[#030229B2] text-sm pl-1 font-light">
                        tecno
                      </span>
                    </p>
                    
                    <p>
                      <span className="text-[#030229] text-sm">Capacity(ROM):</span>{" "}
                      <span className="text-[#030229B2] text-sm pl-1 font-light">
                        512 GB
                      </span>
                    </p>
                    <p>
                      <span className="text-[#030229] text-sm">Memory(RAM):</span>{" "}
                      <span className="text-[#030229B2] text-sm pl-1">
                        8 GB
                      </span>
                    </p>
                  </div>
                </div></div>
  )
}

export default ItemCardInfo