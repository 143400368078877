import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../../components/HeaderChannel";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddItem from "../subscription/AddItems";
import { useDispatch, useSelector } from "react-redux";
import {
  getPricelistItemAction,
  getSubscriptionPricelistItemAction,
} from "../../../../store/channel/actions";
// import PriceListFilter from "../../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import SearchInput from "../../../../components/buttons/SearchButton";
import SubProductGrid from "../../../../components/grids/subscriptionPriceList/SubProductGrid";
import { myChannelActions } from "../../../../store/channel";
import { mySubScritionPriceList } from "../../../../store/subscription/priceList";

const SubscriptionPriceList = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(myChannelActions.setSelectedPriceListItems(null));
    dispatch(mySubScritionPriceList.setUpdated(null));
  };
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subChannelId } = useParams();
  const [selectedModelId, setSelectedModelId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const location = useLocation();
  const isSubsPage = () => {
    return ["/sub/"].some((path) => location.pathname.includes(path));
  };
  const isSubs = isSubsPage();

  const hasNotChannel = () => {
    return ["/subscription/devices"].some((path) =>
      location.pathname.includes(path)
    );
  };
  const isChannelNotExist = hasNotChannel();

  React.useEffect(() => {
    auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}`
      )(dispatch);
  }, [auth?.token, channel?.selected, dispatch, page, limit]);

  React.useEffect(() => {
    auth?.token &&
      getSubscriptionPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id ?? subChannelId}&page=${
          page - 1
        }&limit=${limit}&hasSubs=true`
      )(dispatch);
  }, [
    auth?.token,
    channel?.selected,
    dispatch,
    page,
    limit,
    isSubs,
    isChannelNotExist,
    subChannelId,
  ]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearch = () => {
    // getPricelistSearchAction(
    //   auth?.token,
    //   `?channel=${channel?.selected?._id}&sk=${searchKey}`
    // )(dispatch);
  };

  return (
    <Stack spacing={1} style={{ marginTop: "25px" }}>
      <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
        <DetailsHeaderActionBar
          pageName="Price List"
          goBack={goBack}
          title=" "
        />
        <HeaderComponent
          item="Price List"
          modelTitle="Add New Subscription Price List"
          isNotCollapse={true}
          ModelComponent={
            <AddItem
              setSelectedModelId={setSelectedModelId}
              onCancel={handleCancel}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>
      <div className="flex items-center justify-between pr-20">
        {/* <PriceListFilter isModalOpen={isModalOpen} /> */}
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.priceListItems?.total}
        count={Math.ceil(channel?.priceListItems?.total / limit)}
      >
        {channel?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 15 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!channel.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            <SubProductGrid data={channel?.subPriceListItems?.data} />
          </div>
        )}
      </ScrollableFrame>
    </Stack>
  );
};

export default SubscriptionPriceList;
