import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myWareHouseOutActions } from "../../store/wareHouseOut";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../store/channel/actions";
import {
  getAllStockRequestSentAction,
  getAllWarehouseOutAction,
} from "../../store/wareHouseOut/actions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";

const WarehouseOutFilter: React.FC<{
  status: string;
  limit?: number;
  page: number;
  isChannel: boolean;
}> = ({ status, limit, page, isChannel }) => {
  const { wareHouseOut, channel, auth } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const { Option } = Select;
  const dispatch = useDispatch();

  useEffect(() => {
    auth.token && getAllChannelAction(auth.token, channel.query)(dispatch);
  }, [auth.token, channel.query, dispatch]);

  useEffect(() => {
    auth.token &&
      getAllWarehouseOutAction(auth.token, wareHouseOut.query)(dispatch);
    auth.token &&
      getAllStockRequestSentAction(auth?.token, wareHouseOut.query)(dispatch);
  }, [auth.token, dispatch, wareHouseOut.query]);

  const resetFilter = () => {
    if (selectedFilters) {
      setSelectedShop("");
      setSelectedShop("");
      dispatch(myWareHouseOutActions.setQuery(`?status=${status}`));
    }
  };

  return (
    <div className="pl-4 pr-4 pt-2 pb-2">
      <div className="flex justify-between items-center space-x-2">
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          onClick={() => {
            setSelectedFilters(!selectedFilters);
            resetFilter();
          }}
        >
          Filter
        </Button>

        {selectedFilters && (
          <div className="flex justify-between items-center space-x-2">
            <Select
              size="small"
              showSearch
              style={{ width: 200, fontSize: "5px" }}
              allowClear
              placeholder="Filter By Channel"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={channel.isFetching}
              disabled={channel.isFetching}
              onClear={() => resetFilter()}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                auth.token &&
                  getAllShopAction(auth.token, `channel=${value}`)(dispatch);
                console.log("::", value);
                !value && setSelectedChannel("");
                value && setSelectedChannel(value);
              }}
            >
              {channel?.all?.data?.map((channel: any) => (
                <Option
                  key={channel._id}
                  value={channel._id}
                  label={channel.name}
                >
                  {channel.name}
                </Option>
              ))}
            </Select>
            {selectedChannel && (
              <Select
                size="small"
                showSearch
                style={{ width: 200, fontSize: "5px" }}
                allowClear
                placeholder="Filter By shop"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={channel.isFetching}
                disabled={channel.isFetching}
                onClear={() => resetFilter()}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  console.log("::", value);
                  dispatch(
                    myWareHouseOutActions.setQuery(
                      `?${
                        value &&
                        `shop=${value}&status=${
                          status || "approved"
                        }`
                      }`
                    )
                  );
                  !value && setSelectedShop("");
                  value && setSelectedShop(value);
                }}
              >
                {channel?.allShop?.data?.map((channel: any) => (
                  <Option
                    key={channel._id}
                    value={channel._id}
                    label={channel.name}
                  >
                    {channel.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        )}

        <h1 className="text-gray-400">
          Result:{" "}
          {wareHouseOut?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            wareHouseOut?.allStockRequested?.result ||
            wareHouseOut.warehouseOut?.data?.length
          )}
        </h1>
      </div>
    </div>
  );
};
export default WarehouseOutFilter;
