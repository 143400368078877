import { useEffect } from "react";
import { getProductHistoryActions } from "../../../store/inventory/actions";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";

const History =((props:any)=>{
    const {auth,inventory}=useSelector((state:any)=>state)
    const dispatch=useDispatch();
    const productId = inventory?.searchBySn?.data[0]?._id;
  useEffect(()=>{
    auth?.token && getProductHistoryActions(auth?.token,`wareHouseItem=${productId}`)(dispatch)
      },[auth?.token,dispatch])
      
    return(
        <>
        {inventory?.isFetching? <div className="h-[22rem]"><Skeleton/></div>:
        <ul className="ml-6  h-[22rem] overflow-y-auto py-5">
        {inventory?.productHistory?.data?.map((el: any, index: any) => {

          return (
            <li className="flex" key={index}>
              <div className="mt-3 h-[auto]">
                <div className="h-3 w-3 bg-[#0f0f4716] border-[0.1px] border-[#0f0f4746] rounded-full"></div>
                <hr className="border-[0.1px] border-[#0f0f4711] mt-[0.6px] ml-[.3rem] mr-3 h-full cursor-pointer  duration-500" />
              </div>
              <div className={`mb-3 px-6 py-2 w-full h-full rounded-md`}>
                <div className="w-[90%]">
                  <div className="mb-2 font-normal">
                      <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                      Delivered From <span className="border border-[#03022940] rounded-full px-2 mx-2 font-normal text-sm"> {el?.from}</span> to <span className="border border-[#03022940] rounded-full px-2 font-normal mx-2 text-sm">{el?.to}</span> 
                      </p>

                    <p className="text-[#03022980] text-[12px] pt-2">
                      {el?.doneAt?.slice(0,10)}
                    </p>
                  </div>
                  <div className="mt-4">
                    <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                    Received By {el?.receivedBy}
                    </p>
                    {/* <p className="text-[#0f0f47] text-[12px] pt-2">
                     Received By {el?.receivedBy}
                    </p> */}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>}
        </>
    )
})

export default History;