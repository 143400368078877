import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Form, Input, Select } from "antd";
import {
  createSupplierAction,
  updateSupplierActions,
} from "../../store/supplier/actions";
import UploadComponent from "./Upload";
import { useSelector, useDispatch } from "react-redux";
import { getAllAttributesAction } from "../../store/product/actions";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { product } = useSelector((state: any) => state);
  const [pictures, setPictures] = useState([]);
  const { supplier, auth } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    auth?.token &&
      !props?.data &&
      (await createSupplierAction(auth?.token, { ...values, logo: images[0] })(
        dispatch
      ));
    auth?.token &&
      props?.data &&
      (await updateSupplierActions(
        props?.data?._id,
        { ...values, logo: images[0] },
        auth?.token
      )(dispatch));
    form.resetFields();
    props?.onCancel();
  };

  useEffect(() => {
    auth?.token && getAllAttributesAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: "70%" }}>
          <Form.Item
            name="name"
            label="Supplier Name"
            tooltip="Please enter  name of the Supplier?"
            rules={[
              {
                required: true,
                message: "Name is required!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Supplier Phone"
            tooltip="Please enter  phone of the Supplier?"
            rules={[
              {
                required: true,
                message: "Phone is required!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="+1 (377) 317-1945" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Order email"
            tooltip="Please enter  order email of the Supplier?"
            rules={[{ required: true, message: "email is required!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="tin"
            label="Supplier TIN"
            tooltip="Please enter  TIN of the Supplier?"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Supplier Address / Location"
            tooltip="Where our supplier is located"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="type"
            label="Supplier Type"
            rules={[
              {
                required: true,
                message: "Please select type of the Supplier!",
              },
            ]}
          >
            <Select showSearch placeholder="Select  type of the Supplier!">
              {["International", "Local"].map((type: any) => (
                <Option value={type.toLowerCase()}>{type}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="brands"
            label="Supported Brands"
            rules={[
              {
                required: true,
                message: "Please select brand!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              loading={product.isFetching}
              disabled={product.isFetching}
              placeholder="Select Brand"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={product?.attributes?.brands.map((brand: any) => ({
                value: brand.value,
                label: brand.label,
              }))}
              mode="multiple"
            />
          </Form.Item>
        </Stack>
        <Stack sx={{ width: "30%" }}>
          <UploadComponent
            setPictures={setPictures}
            limit={1}
            default={
              props?.data && [
                {
                  status: "done",
                  url: props?.data?.logo,
                },
              ]
            }
          />
        </Stack>
      </Stack>
      <Divider orientation="left">Add Contacts</Divider>

      <Form.List name="employees" {...formItemLayout}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Stack>
                <Stack direction={"row"}>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[{ required: true, message: "Missing names" }]}
                    style={{ minWidth: 250 }}
                  >
                    <Input placeholder="Names" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "position"]}
                    rules={[{ required: true, message: "Missing position" }]}
                  >
                    <Input placeholder="Position" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "phone"]}
                    rules={[
                      { required: true, message: "Missing phone contacts" },
                    ]}
                  >
                    <Input placeholder="Phone" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "email"]}
                    rules={[
                      { required: true, message: "Missing email contacts" },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Stack>
              </Stack>
            ))}
            <Form.Item>
              <Button onClick={() => add()} startIcon={<PlusOutlined />}>
                Add Contacts
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ minWidth: "100%" }}
          loading={supplier?.isFetching}
        >
          {props.data ? "update" : "Save"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;
