import React, { useEffect, useState } from "react"
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar"
import AddDevicesForm from "../../../../components/forms/addDeviceForm"
import UploadSvcFile from "../../../../components/forms/uploadSvcFile"
import * as siIcons from "react-icons/sl"
import DeviceTable from "./deviceTable"
import { getAllDevicesAction } from "../../../../store/vault/actions"
import { useDispatch, useSelector } from "react-redux"
const AddDevices=()=>{
  const {auth,vault}=useSelector((state:any)=>state);
  const dispatch=useDispatch();
  useEffect(() => {
    auth?.token && getAllDevicesAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  const onRefresh=(()=>{
    getAllDevicesAction(auth?.token)(dispatch)
  })
    return(
        <>
        <div>
        <DetailsHeaderActionBar
        goBack={"goBack"}
        pageName={"Manage Devices"}
        title={"Add Devices"}
        data={""}
      />
        </div>
        
      <div className="flex justify-between w-full">
        <div className="w-[48%] bg-white p-8">
          <h1 className="text-[#030229] text-lg font-medium">Add new devices</h1>
        <AddDevicesForm />
        </div>
        <div className="w-[48%] bg-white p-8">
          <h1 className="text-[#030229] text-lg font-medium">Upload CSV file</h1>
        <UploadSvcFile/>
        </div>
        </div>
        <div className="flex justify-between my-4">
                <h1 className="text-[#030229] text-base">Upload History</h1>
                <h1 className="text-[#030229] text-base flex gap-3 cursor-pointer" onClick={onRefresh}><siIcons.SlRefresh size={20}/> Refresh</h1>
        </div>
        <div>
          <DeviceTable/>
        </div>

        </>
    )
}
export default AddDevices