import { Box, Checkbox, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Component = (props: any) => {
  const navigate = useNavigate();
  console.log(":::::...", props?.data?.data?.stockRequest?.shopId?.name);
  return (
    <div className="relative w-[23%] p-2 bg-white text-black rounded-md border hover:border-black">
      {props?.route === "channel" && (
        <div className="absolute top-0 left-0 z-10">
          <Checkbox
            id={props?.data._id}
            onChange={props?.handleSelected}
            {...label}
            color="success"
          />
        </div>
      )}
      <div
        onClick={() =>
          navigate(`/inventory/ws/out/${props.data.data.warehouseOutId}`)
        }
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
            className="pt-5"
          >
            <Stack spacing={0}>
              <h1>{props?.data?.warehouseOutId}</h1>
              <p className="text-xs text-blue-400">
                {`${props?.data?.data?.stockRequest?.shopId?.name} ~ ${props?.data?.data?.stockRequest?.shopId?.address}`}
              </p>
              <p className="text-xs text-blue-400">
                {props?.data?.data?.stockRequest?.shopId?.channel?.name}
              </p>
            </Stack>
            <Tag
              color={searchValue(props?.data?.status)}
              style={{ minWidth: "4rem", textAlign: "center" }}
              className="capitalize"
            >
              {props?.data?.status}
            </Tag>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0} style={{ marginTop: "15px" }}>
                <p className="text-xs">
                  Approved By:{" "}
                  <b>{props?.data?.data?.stockRequest?.approvedBy?.names}</b>
                </p>
                <p className="text-xs">
                  Approved On:{" "}
                  <b>{props?.data?.data?.updatedAt?.split("T")[0]}</b>
                </p>
                <p className="text-xs">
                  Created On: <b>{props?.data?.date}</b>
                </p>
              </Stack>
              <p className="text-xs pr-3">
                <b>{props?.data?.data?.list?.length} items</b>
              </p>
            </Stack>
          </Box>
        </Stack>
      </div>
    </div>
  );
};


export default Component;
