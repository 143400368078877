import React from "react";
import { Divider } from "@mui/material";

const ViewSubscriptionForm = (props: any) => {
  const dataPlan = props?.data && props?.data?.subscriptionPlan[0];

  return (
    <div className="pb-8">
      <div className="flex items-center space-x-10">
        <div className="grid grid-cols-2 gap-y-4">
          <div className="font-semibold">Type</div>
          <div>{props?.data?.product?.type}</div>
          <div className="font-semibold">Brand</div>
          <div>{props?.data?.product?.brand}</div>
          <div className="font-semibold">Model</div>
          <div>{props?.data?.product?.model}</div>
        </div>
        <figure className="flex h-fit">
          <img
            className="h-44 rounded-lg"
            src={props?.data?.product?.images[0]}
            alt={props?.data?.product?.model}
          />
        </figure>
      </div>
      <h1 className="py-2 font-bold text-lg">Data Plan</h1>
      <div className="grid grid-cols-2 gap-y-4">
        <div className="font-semibold">Bundles</div>
        <div>{dataPlan?.bundle?.map((d: any) => d)?.join(", ")}</div>
        <div className="font-semibold">SMS</div>
        <div>{dataPlan?.sms?.map((d: any) => d)?.join(", ")}</div>
        <div className="font-semibold">Talk time</div>
        <div>{dataPlan?.call?.map((d: any) => d)?.join(", ")}</div>
      </div>
      <h1 className="py-2 font-bold text-lg">Subscription Plans</h1>
      {props?.data?.subscriptionPlan?.map((d: any) => (
        <div className="grid grid-cols-2 gap-y-2">
          <div className="font-semibold pt-2">Duration</div>
          <div className="pt-2">{`${d?.duration} Months`}</div>
          {d?.subscriptionType !== "B2B" && (
            <>
              <div className="font-semibold">Initial Payment</div>
              <div>{d?.initialPayment}</div>
            </>
          )}
          <div className="font-semibold">Monthly Instalment</div>
          <div>{d?.monthlyInstalment}</div>
          {d?.subscriptionType !== "B2B" && (
            <>
              <div className="font-semibold">Weekly Instalment</div>
              <div>{d?.weeklyInstalment}</div>
              <div className="font-semibold">Daily Instalment</div>
              <div>{d?.dailyInstalment}</div>
            </>
          )}
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default ViewSubscriptionForm;
