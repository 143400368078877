import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "WareHouse States",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    selectedDeliveryNote: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    filters: null,
    allStockStats: null,
    allStockProductStats: null,
    searchResult: null,
    cartData: null,
    searchError: null,
    createdCart: null,
    isCartCreated: false,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSelectedDeliveryNote(state, action) {
      state.selectedDeliveryNote = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },

    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllStockStats(state, action) {
      state.allStockStats = action.payload;
    },
    setAllStockProductStats(state, action) {
      state.allStockProductStats = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setCardData(state, action) {
      state.cartData = action.payload;
    },
    setSearchError(state, action) {
      state.searchError = action.payload;
    },
    setCreatedCart(state, action) {
      state.createdCart = action.payload;
    },
    setIsCartCreated(state, action) {
      state.isCartCreated = action.payload;
    },
  },
});

export const myWareHouseActions = mySlice.actions;

export default mySlice.reducer;
