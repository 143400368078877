import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const data = [
  { value: 5, label: 'Apple' },
  { value: 10, label: 'Sumsung' },
  { value: 15, label: 'Tecno' },
  { value: 20, label: 'Itel' },
  { value: 25, label: 'Infinix' },
];

const size = {
  width: 400,
  height: 200,
};

export default function PieChartWithCenterLabel() {
  const colors = ['#3930A8', '#3762D0', '#78CFFF', '#BBD9FC', '#030229']; 
  return (
    <PieChart series={[{ data, innerRadius: 60 }]} colors={colors} {...size}>
    </PieChart>
  );
}
