import React from "react";
import { Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import Inactive from "../../../../assets/images/subscription/inactive.png";
import { useSelector } from "react-redux";

interface DataType {
  key: string;
  ordernumber: string;
  name: string;
  totalorder: number;
  tags: string[];
  price: number;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Order no",
    dataIndex: "ordernumber",
    key: "ordernumber",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: any) => (
      <>
        <div className="flex items-center space-x-2">
          <img src={Inactive} alt="" className="w-6 h-6" /> <h1>{text}</h1>
        </div>
      </>
    ),
  },
  {
    title: "Total Order",
    dataIndex: "totalorder",
    key: "totalorder",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color =
            tag === "Delivered" ? "green" : tag === "Canceled" ? "red" : "gold";

          return (
            <Tag color={color} key={tag} style={{ borderRadius: 12 }}>
              {tag}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Unity Price/Monthy",
    dataIndex: "price",
    key: "price",
  },
];

const OrdersHistory = () => {
  const { overView } = useSelector((state: any) => state);
  const history = overView?.allOrderHistory?.data;
  const data = history.map((el: any, id: any) => {
    return {
      key: el._id,
      ordernumber: id + 1,
      name: el?.account?.customerDetails?.name,
      totalorder: el?.list?.quantity,
      tags: [el?.status],
      price: el?.product?.prices[0]?.value,
    };
  });

  //   {
  //     key: "1",
  //     ordernumber: "#876364",
  //     name: "MTN",
  //     totalorder: 235,
  //     tags: ["Delivered"],
  //     price: 146660,
  //   },
  //   {
  //     key: "2",
  //     ordernumber: "#876364",
  //     name: "Airtel",
  //     totalorder: 300,
  //     tags: ["Canceled"],
  //     price: 146660,
  //   },
  //   {
  //     key: "3",
  //     ordernumber: "#876364",
  //     name: "RRA",
  //     totalorder: 10,
  //     tags: ["Delivered"],
  //     price: 146660,
  //   },
  //   {
  //     key: "4",
  //     ordernumber: "#876364",
  //     name: "MINADEF",
  //     totalorder: 235,
  //     tags: ["Inprogress"],
  //     price: 146660,
  //   },
  //   {
  //     key: "5",
  //     ordernumber: "#876364",
  //     name: "MTN",
  //     totalorder: 35,
  //     tags: ["Delivered"],
  //     price: 146660,
  //   },
  // ];
  return (
    <div className="w-[50%] bg-[#fff] h-[28rem] overflow-hidden">
      <div className="text-[#0F0F47] font-bold text-2xl p-6">Order History</div>
      <Table
        columns={columns}
        dataSource={data}
        loading={overView?.isFetching}
        scroll={{ y: 400 }}
        pagination={false}
      />
    </div>
  );
};

export default OrdersHistory;
