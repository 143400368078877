import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import * as piIcons from "react-icons/pi";
import { useSelector } from "react-redux";
interface DataType {
  key: string;
  uploadedBy: string;
  service: string;
  number: number;
  imei: number;
  quantity: number;
  status: string;
  uploadedOn: string;
}

const DeviceTable = () => {
  const { vault } = useSelector((state: any) => state);
  const columns: ColumnsType<DataType> = [
    {
      title: "no",
      dataIndex: "number",
      key: "number",
      render: (text) => <a>{text}</a>
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei"
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service"
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
              <Tag
                key={status}
                className={`py-1 w-24 text-center rounded-full ${
                  status === "active"
                    ? "text-[#0FA91E] border border-[#0FA91E80]"
                    : status === "locked"
                    ? "text-[#FA3659] border border-[#FA365980]"
                    : status === "released"
                    ? "text-[#2943D6] border border-[#2943D6]"
                    : status === "ready for use"
                    ? "text-[#F19F1D] border border-[#F19F1D80]"
                    : "text-[#2943D6] border border-[#2943D6]"
                } `}
              >
              { status}
              </Tag>
        </>
      )
    },
    {
      title: "Uploaded On",
      dataIndex: "uploadedOn",
      key: "uploadedOn"
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy"
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <piIcons.PiDotsThreeLight size={23} />
        </Space>
      )
    }
  ];
  const deviceData = vault?.all?.data?.map((el: any) => {
    return { 
      el,
    devices: el?.uploadVaultResponse?.deviceList?.map((device:any,index:any)=>{
    
      return{
        key: el?._id,
        imei: device?.deviceUid,
        number: index+1,
        uploadedBy: el?.enableVaultResponse?.createdBy?.toUpperCase(),
       quantity: el?.uploadVaultResponse?.totalDeviceCount,
        service: device?.serviceName,
        uploadedOn: el?.createdAt?.slice(0, 10),
        status: device?.uploadStatus,
        data: el
      };
    })
  };
}).flat();
const flattenedDeviceData = deviceData?.map((item:any) => item.devices).flat();
  return (
    <Table
      columns={columns}
      loading={vault?.isFetching}
      // dataSource={data}
      dataSource={flattenedDeviceData}
    />
  );
};

export default DeviceTable;