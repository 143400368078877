import React from "react";
import { useSelector } from "react-redux";
import { convertArrayToObjectNew } from "../../../utils/converter";
import { LineChart } from "react-chartkick";



export default function CustomChart() {
  const { channel } = useSelector((state: any) => state)
  const comparedDate = channel?.salesStatiscs?.data?.revenue?.comparedDate?.revenueByHour;
  const currentDate = channel?.salesStatiscs?.data?.revenue?.currentDate?.revenueByHour;
  const data2 = [
    {
      name: "current",
      data: convertArrayToObjectNew(
        channel?.salesStatiscs?.data?.revenue?.currentDate?.revenueByHour
      ),
      color: "#2943D6",
    },
    {
      name:
        "Compared",
      data: convertArrayToObjectNew(
        channel?.salesStatiscs?.data?.revenue?.comparedDate?.revenueByHour
      ),
      color: "#C4C4C4",
    },
  ];

  return (
   
      <div>
        <LineChart
          data={data2}
          prefix="RWF "
          thousands=","
          xtitle="Hours"
          // height={"85%"}
          loading="Loading..."
          // options={lineOptions}
          legend={false}
        />
      </div>
  );
}