import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const AddDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault`, data, token);
};
export const lockDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/lock`, data, token);
};
export const unLockDeviceService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/unlock`, data, token);
};
export const sendMessageService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/message`, data, token);
};
export const getDeviceDetailsService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/vault/devices`, data, token);
};
export const getDeviceStatusService = async (token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vault/statusCount`,  token);
};
export const getOverviewCountService = async (token: string) => {
  return await HttpRequest.get(`${SERVER_URL}/vault/counts`,  token);
};
export const getNotesServices = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/note${query}`, token);
};
export const getDeviceStatus = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault${query}`, token);
};
export const getMessageTamplateService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/messageTemplate${query}`, token);
};
export const getDeviceServiceStatus = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/statusCount${query}`, token);
};
export const getAllDevicesService = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/vault/allDevices`, token);
};

