import React from "react";


const Payroll = (props: any) => {
 

  return (
    <div className="text-black p-1">
     payroll
    
    </div>
  );
};

export default Payroll;
