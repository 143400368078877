import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ProductFilter from "../../../../components/filters/ProductFiltering";
import HeaderComponent from "../../../../components/HeaderComponent";
import ProductGrid from "../../../../components/grids/warehouse/ProductGridStock";
import { Box, Skeleton } from "@mui/material";
import { getStockStatsAction } from "../../../../store/wareHouse/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { WarehouseHeaders } from "../../../../components/csvHeaders/Headers";
import FilesCard from "../../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { getStock_CSVAction } from "../../../../store/csvDownload/actions";
const WareHouseStock = () => {
  const { auth, wareHouse, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  React.useEffect(() => {
    auth?.token && getStockStatsAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getStock_CSVAction(
          auth?.token,
          `?limit=${wareHouse?.allStockStats?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    model: record?.model || "N/A",
    brand: record?.brand || "N/A",
    type: record?.type || "N/A",
    inShop: record?.statuses
      .filter((status: any) => status.status === "out")
      .map((status: any) => status.count),
    inWarehouse: record?.statuses
      .filter((status: any) => status.status === "in")
      .map((status: any) => status.count),
    sold: record?.statuses
      .filter((status: any) => status.status === "sold")
      .map((status: any) => status.count),
  }));

  return (
    <div className="text-black space-y-5">
      <div className="text-black pt-2 pb-2">
        <DetailsHeaderActionBar pageName="Warehouse" title="Stock" />
      </div>
      <div className="flex items-center justify-between mr-24">
        <HeaderComponent
          isNotCollapse={true}
          isNotAddButton={true}
          filterCard={<ProductFilter />}
        />
        <div className="flex justify-end gap-4 p-4">
          <FilesCard
            Icon={VscFilePdf}
            tailwindTextColor="text-white"
            tailwindBackgroundColor="bg-orange-400"
            disabled
          />
          <CSVExportButton
            csvHeaders={WarehouseHeaders}
            csvData={csvData ? csvData : ""}
            filename={`Warehouse/stock.csv`}
          />
        </div>
      </div>
      {wareHouse?.isFetching && (
        <div className="flex flex-wrap gap-4 justify-start items-start">
          {Array.from({ length: 9 }).map((d: any) => (
            <Box sx={{ width: 330 }}>
              <Skeleton animation="wave" />
              <Skeleton variant="rectangular" height={150} />
            </Box>
          ))}
        </div>
      )}
      <ScrollableFrame hidePagination>
        {!wareHouse?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start min-h-[60vh]">
            {wareHouse?.allStockStats?.status === 200 && (
              <ProductGrid
                // handleSelected={handleSelected}
                data={wareHouse?.allStockStats?.data}
              />
            )}
          </div>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default WareHouseStock;
