import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import productReducer from "./product";
import SupplierReducer from "./supplier";
import CustomerReducer from "./customer";
import QuotationReducer from "./quotation";
import ProformaReducer from "./proforma";
import ChannelReducer from "./channel";
import ShopReducer from "./shop";
import purchase from "./purchase";
import WareHouseReducer from "./wareHouse";
import WareHouseOutReducer from "./wareHouseOut";
import PosReducer from "./pos";
import settingReducer from "./setting";
import dashboardReducer from "./dashboard";
import transferReducer from "./transfer";
import inventoryReducer from "./inventory";
import layoutReducer from "./layout";
import ebmReducer from "./ebm";
import notificationReducer from "./notification";
import repairReducer from "./repair";
import socketReducer from "./socket";
import chatReducer from "../apps/chat/redux";
// import { Knowledge } from "../apps/knowledge";
import AccountReducer from "./account";
import ordersReducer from "./subscription/order";
import subPriceListReducer from "./subscription/priceList";
import deliverynoteReducer from "./subscription/deliverynote";
import deliverynoteItemReducer from "./subscription/deliverynote";
import invoiceReducer from "./invoice";
import subscriptionRequestReducer from "./subscription/subscriptionRequest";
import termsAndConditionReducer from "./terms_conditons";
import overViewReducer from "./subscription/overview";
import reportReducer from "./report";
import companyReducer from "./setting/company";
import roleAndPermissionReducer from "./setting/roleAndPermissions";
import vaultReducer from "./vault";
import CSVReducer from "./csvDownload";
import FileReducer from "./file";
import shopReducer from "./shop";
import financeReducer from "./finance";
import agentKpiReducer from "./kpi";

const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    supplier: SupplierReducer,
    quotation: QuotationReducer,
    customer: CustomerReducer,
    proforma: ProformaReducer,
    channel: ChannelReducer,
    shop: ShopReducer,
    purchase: purchase,
    wareHouse: WareHouseReducer,
    wareHouseOut: WareHouseOutReducer,
    pos: PosReducer,
    setting: settingReducer,
    dashboard: dashboardReducer,
    transfer: transferReducer,
    inventory: inventoryReducer,
    layout: layoutReducer,
    ebm: ebmReducer,
    notifications: notificationReducer,
    repairs: repairReducer,
    socket: socketReducer,
    chat: chatReducer,
    // knowledge: Knowledge.Redux,
    account: AccountReducer,
    orders: ordersReducer,
    order: ordersReducer,
    subPriceList: subPriceListReducer,
    deliverynote: deliverynoteReducer,
    deliverynoteItem: deliverynoteItemReducer,
    invoice: invoiceReducer,
    subscriptionRequest: subscriptionRequestReducer,
    termsandConditions: termsAndConditionReducer,
    overView: overViewReducer,
    report: reportReducer,
    company: companyReducer,
    roleAndPermission: roleAndPermissionReducer,
    vault: vaultReducer,
    CSV: CSVReducer,
    file: FileReducer,
    shopEligible: shopReducer,
    finance: financeReducer,
    agentKPI: agentKpiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
