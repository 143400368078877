import React from "react";
import TimeComponent from "./TimeComponent";
import { Card } from "antd";
import {UserOutlined} from "@ant-design/icons";
import AttendanceChart from "../attendance/AttendanceChart";
import CustomAttendanceChart from "./customAttendanceChart";


const stats = [
  { label: 'Total Employees', value: 360, change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs ' },
  { label: 'On Time', value: 30, change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-[#605BFF] bg-violet-300 text-xs ' },
  { label: 'Absent', value: 112,  change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs '  },
  { label: 'Early Departures', value: 60, change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs'},
  { label: 'Time-off', value: 100, change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs ' },
  { label: 'Total Pay-Checks', value: 2, change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs '}, 
  { label: 'Opportunities', value: 72,change:"+", text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs '}
];


const HROverview = (props: any) => {

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-stretch mb-4 gap-5">
        <div className="p-4  w-[100%] md:w-[293px] py-8 h-[292px] bg-white rounded-md">
          <TimeComponent />
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <div key={index} className="h-[134px] w-[100%] md:w-[303px] bg-white rounded-md">
              <div className="flex flex-col items-center justify-between h-full py-2 px-3">
                <>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className="text-xl font-poppins text-black">{stat.value}</div>
                    <div className={`rounded-full p-1 ${stat.color}`}>
                      {stat.icon}
                    </div>

                  </div>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className="text-sm font-poppins text-[#030229]">{stat.label}</div>
                    <div></div>
                  </div>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className={`rounded-full px-2 py-1 ${stat.color}`}>
                    {stat.change}
                    </div>
                    <div className="text-[#030229] opacity-[50%] text-sm">{stat.text}</div>
                  </div>
                </>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row  mt-6 gap-3">
        <div className="p-4 w-full md:w-[947px]  h-auto rounded-md bg-white">
          <CustomAttendanceChart />
        </div>
        <div className=" w-[100%] md:w-[40%]  h-auto bg-white">
          <div className="font-poppins text-base font-normal leading-6 text-left">
            <div className="text-lg font-poppins mb-4">Weekly Attendance</div>
          </div>
          <AttendanceChart />
        </div>
      </div>
    </div >
  );
};

export default HROverview;



