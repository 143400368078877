import React from "react";
import { useSelector } from "react-redux";
import SubProductCardGrid from "./SubProductCardGrid";

const SubProductGrid = (props: any) => {
  const { product, channel } = useSelector((state: any) => state);

  return channel?.subPriceListItems?.data?.map((d: any) => (
    <SubProductCardGrid
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
      brands={product?.attributes?.brands}
    ></SubProductCardGrid>
  ));
};

export default SubProductGrid;
