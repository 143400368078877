import React from 'react';
import { Table, Tag } from 'antd';
import HoverDropDown from '../DropDown/HoverDropDown';
import type { MenuProps } from 'antd';
import { GrFormView } from 'react-icons/gr';
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";


const ImportTable = (props:any) => {
  console.log("props",props)
  const items: MenuProps['items'] = [
    {
        label: <div className='flex gap-2'><GrFormView size={25}/><span className="text-sm pt-1">View Details</span></div>,
        key: '0',
        // onClick: () => props?.open(),
    },
    {
        label: <div className='flex gap-3'><FiEdit  size={22}/><span className="text-sm pt-1">Modify</span></div>,
        key: '1',
    },
    {
        label: <div className='flex gap-2'><MdDeleteOutline  size={25}/><span className="text-sm pt-1">Delete</span></div>,
        key: '3',
    },
];

  const columns= [
    {
      title: 'Declared Date',
      dataIndex: 'date',
    },
    {
      title: 'Op. Code',
      dataIndex: 'code',
    },
    {
      title: 'Item Seq',
      dataIndex: 'item',
    },
    {
      title: 'Orgin',
      dataIndex: 'orgin',
    },
    {
      title: 'Export Country',
      dataIndex: 'country',
    },
    {
      title: 'Qty ',
      dataIndex: 'quantity',
    },
    {
      title: 'Gross WT',
      dataIndex: 'vat',
    },
    {
      title: 'Net WT',
      dataIndex: 'total',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render :(text:any)=>(
        <>
        <Tag className='border border-[#03022940] px-3 py-1  rounded-xl capitalize' >{text}</Tag>
        </>
    )
    },
    {
      title: ' ',
      dataIndex: 'data',
      render :()=>(
          <>
          <HoverDropDown items={items}/>
          </>
      )
    },
  ];
  
  const data= [
    {
      key: '1',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"waiting"
    },
    {
      key: '2',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"waiting"
    },
    {
    key: '3',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Approved"
    },
    {
      key: '4',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Approved"
    },
    
   
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default ImportTable;