import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Dropdown, Tag, Modal } from "antd";
import { BsThreeDots } from "react-icons/bs";
import ViewAction from "./view";
import {
  approveInvoicePaymentAction,
  getAllAccountInvoiceAction,
} from "../../../../../store/invoice/actions";
import { useDispatch, useSelector } from "react-redux";
import ConfirmSubInvoiceForm from "../../../../../components/forms/ConfirmSubInvoiceForm";

const ThreeInvoiceDotDropdown = (props: any) => {
  const { auth } = useSelector((state: any) => state);
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch();

  // const approvePayment = async () => {
  //   auth?.token &&
  //     (await approveInvoicePaymentAction(
  //       props?.record?.data?._id,
  //       auth?.token,
  //       {
  //         amountPaid: props?.record?.data?.amountToBePaid,
  //         momoTransactionId: "-----------",
  //       }
  //     )(dispatch));
  //   auth?.token &&
  //     (await getAllAccountInvoiceAction(
  //       auth?.token,
  //       `?account=${props?.record?.data?.account?._id}`
  //     )(dispatch));
  // };

  const handleOpenConfirmModel = () => {
    setOpenConfirm(true);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <ViewAction onOpenViewModel={props?.onOpenViewModel} />,
    },
    {
      key: "2",
      label: (
        <Tag
          color="#87d068"
          style={{
            fontSize: "15px",
            height: "30px",
            width: "100px",
            lineHeight: "30px",
            borderRadius: "10px",
            paddingLeft: "35px",
          }}
          onClick={handleOpenConfirmModel}
        >
          Paid
        </Tag>
      ),
    },
  ];
  return (
    <>
      <>
        {props?.record?.status !== "paid" && (
          <Dropdown menu={{ items }} placement="bottom">
            <BsThreeDots size={20} />
          </Dropdown>
        )}
        {props?.record?.status === "paid" && (
          <ViewAction onOpenViewModel={props?.onOpenViewModel} />
        )}
      </>
      <Modal
        title="Confirm Payment"
        centered
        open={openConfirm}
        onCancel={handleCancel}
        width={400}
        footer={null}
      >
        <ConfirmSubInvoiceForm
          onCancel={handleCancel}
          itemId={props?.record?.data?._id}
        />
      </Modal>
    </>
  );
};
export default ThreeInvoiceDotDropdown;
