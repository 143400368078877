import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CustomButton from '../buttons/CustomButton';
import {ReactComponent as SaveIcon} from "../../assets/images/icons/save.svg"

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';
const AddItemForm: React.FC = () => (
  <Form
    name="wrap"
    labelCol={{ flex: '110px' }}
    labelAlign="left"
    labelWrap
    wrapperCol={{ flex: 1 }}
    colon={false}
    style={{ width: "100%"}}
  >
    <div className='flex gap-10 w-full'>

      <div className='w-1/2'>
      <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Item Code:</span> name="code" rules={[{ required: true }]} >
      <Input className='h-10'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Item Name:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10'/>
    </Form.Item>
    <div className='flex gap-6 w-full'>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Butch Number:</span> name="code" rules={[{ required: true }]} className='w-1/2'>
      <Input className='h-10 w-[9rem]'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Origin:</span> name="name" rules={[{ required: true }]}  className='w-1/2'>
      <Input className='h-10 w-[9rem]'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>
    <div className='flex gap-6'>
        <span className='text-[12px] text-[#030229] pt-2'>Item Type:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>

      <div className='flex gap-4'>
        <span className='text-[12px] text-[#030229] pt-2'>Pkg unit:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 ,backgroundColor:"#0302291A"}}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>
    </div>
    <div className='flex gap-5 w-full mt-3'>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Purchase Price:</span> name="code" rules={[{ required: true }]} className='w-1/2'>
      <Input className='h-10 w-[9rem]' type='number'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Sale price:</span> name="name" rules={[{ required: true }]}  className='w-1/2'>
      <Input className='h-10 w-[9rem]' type='number'/>
    </Form.Item>
    </div>
    <div className='flex gap-5 w-full'>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Beginning Stock:</span> name="code" rules={[{ required: true }]} className='w-1/2'>
      <Input className='h-10 w-[9rem]'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2 '>Current Stock:</span> name="name" rules={[{ required: true }]}  className='w-1/2'>
      <Input className='h-10 w-[9rem]'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>
    <div className='flex gap-6'>
        <span className='text-[12px] text-[#030229] pt-2'>Use Expiration:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>

      <div className='flex gap-4'>
        <span className='text-[12px] text-[#030229] pt-2'>Expiration Date:</span>
        <DatePicker
    defaultValue={dayjs('2019-09-03', dateFormat)}
    minDate={dayjs('2019-08-01', dateFormat)}
    maxDate={dayjs('2020-10-31', dateFormat)}
  />
      </div>
    </div>
      </div>

        <div className='w-1/2'>
        <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Class Code:</span> name="code" rules={[{ required: true }]}>
      <Input className='h-10'/>
    </Form.Item>
    <div className='w-full flex gap-1'>
      <div className='flex gap-4'>
        <span className='text-[12px] text-[#030229] pt-2'>Use Barcode:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>
    
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Bar code:</span> name="name" rules={[{ required: true }]} className=' w-[60%]'>
      <Input className='h-10 '/>
    </Form.Item>
   
    
    </div>
    <div className='w-full flex gap-1'>
      <div className='flex gap-4'>
        <span className='text-[12px] text-[#030229] pt-2'>Insurance Y/N:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: 'yes',
        label: 'yes',
      },
      {
        value: 'no',
        label: 'no',
      }
    ]}
  />
      </div>
    
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Sale Price:</span> name="name" rules={[{ required: true }]} className=' w-[60%]'>
      <Input className='h-10 '/>
    </Form.Item>
   
    
    </div>
    <div className='flex gap-4'>
        <span className='text-[12px] text-[#030229] pt-2'>Qty Unit:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>
     
    <div className='flex gap-4 mt-3'>
        <span className='text-[12px] text-[#030229] pt-2'>Tax Type:</span>
        <Select
    showSearch
    style={{ width: 150,height:40 }}
    placeholder="No Use"
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').includes(input)}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={[
      {
        value: '1',
        label: 'No User',
      },
      {
        value: '2',
        label: 'users',
      }
    ]}
  />
      </div>
      <div className='w-1/2 mt-3'>

        <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Safety Stock:</span> name="name" rules={[{ required: true }]} >
        <Input className='h-10 w-[10rem]'/>
      </Form.Item>
      </div>
           
    <div className='flex gap-4'>
    <span className='text-[12px] text-[#030229] pt-2'>Use:</span>
    <Select
showSearch
style={{ width: 150,height:40 }}
placeholder="No Use"
optionFilterProp="children"
filterOption={(input, option) => (option?.label ?? '').includes(input)}
filterSort={(optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}
options={[
  {
    value: '1',
    label: 'No User',
  },
  {
    value: '2',
    label: 'users',
  }
]}
/>
  </div>
        </div>
    </div>
   
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Description:</span> name="code" rules={[{ required: true }]} className='mt-3'>
    <Input className='h-10'/>
  </Form.Item>
    <Form.Item label=" " className='w-1/2 m-auto'>
   <CustomButton title={"Save"} bgColor='[#605BFF]' width='full' textColor='white' icon={<SaveIcon/>}/>
    </Form.Item>
  </Form>
);

export default AddItemForm;