import { Form, Input, Select } from "antd";
import React from "react";
import VaultButton from "../buttons/vaultButton";
import VaultUploadComponent from "../../pages/dashboard/vaults/vaultAploadComponent";

type FieldType = {
  email?: string;
  password?: string;
  current?: string;
  new?: string;
  confirm?: string;
};
interface formProps{
    inputName?:any,
    selectName?:any,
    placeholder?:any,
    selectPlaceholder?:any,
}
const { Option } = Select;
const UploadSvcFile = ({inputName,selectName,placeholder,selectPlaceholder}:formProps) => {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%" , marginTop:"20px"}}
        // onFinish={onFinish}
      >
        <Form.Item
          name="imei"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Enter Device IMEI
            </span>
          }
          className="w-full"
          rules={[{ required: true, message: "Please enter device Id" }]}
        >
         <VaultUploadComponent/>
       </Form.Item>
       <Form.Item
                name="role"
                label={
                  <span className="text-[#030229b2] pl-1 pt-4 text-sm">
                    Assign to service
                  </span>
                }
                className="w-full h-[52px] vault-select"
                rules={[{ required: true, message: "Please select service" }]}
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Inventory"
                  style={{ border: "none" }}
                  className=" capitalize rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
                >
                  <option value={"Inventory"}> <span>Inventory <p className="text-[#030229b2] text-[12px] font-thin">Inventory &gt; Idle</p> </span> </option>
                  <option value={"prepaid"}> <span>Device Financing (Prepaid) <p className="text-[#030229b2] text-[12px] font-thin">Prepaid &gt; Ready for Use</p> </span> </option>
                  <option value={"postpaid"}> <span>Device Financing (PostPaid) <p className="text-[#030229b2] text-[12px] font-thin">PostPaid &gt; Ready for Use</p> </span> </option>
                </Select>
              </Form.Item>
              <Form.Item className="mt-10">
              <VaultButton btnName={"Save"} />
              </Form.Item>
   
      </Form>
            {/* <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="vertical"
              style={{ maxWidth: "100%" }}
              autoComplete="off"
            >
                  <p className="text-[#030229b2] pl-1 pt-4 text-sm py-4">
                    Select SVC File
                  </p>
              <VaultUploadComponent/>
              <Form.Item
                name="role"
                label={
                  <span className="text-[#030229b2] pl-1 pt-4 text-sm">
                    Assign to service
                  </span>
                }
                className="w-full h-[52px] "
              >
                <Select
                  dropdownMatchSelectWidth={false}
                  showSearch
                  placeholder="Inventory"
                  style={{ border: "none" }}
                  className=" capitalize rounded-md h-[52px] w-full lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
                >
                  <option value={"Inventory"}> <span>Inventory <p className="text-[#030229b2] text-[12px] font-thin">Inventory &gt; Idle</p> </span> </option>
                  <option value={"prepaid"}> <span>Device Financing (Prepaid) <p className="text-[#030229b2] text-[12px] font-thin">Prepaid &gt; Ready for Use</p> </span> </option>
                  <option value={"postpaid"}> <span>Device Financing (PostPaid) <p className="text-[#030229b2] text-[12px] font-thin">PostPaid &gt; Ready for Use</p> </span> </option>
                </Select>
              </Form.Item>
              <Form.Item >
                <VaultButton btnName={"Save"} />
              </Form.Item>
            </Form> */}
    </>
  );
};
export default UploadSvcFile;
