import { SERVER_URL } from "../../../utils/constants";
import HttpRequest from "../../../utils/HttpRequest";

export const createOrderService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/subsOrder`, data, token);
};

export const createSubOrderItemService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/subsOrderItem`, data, token);
};

export const getAllSubOrder = async (token: string, query?:any) => {
  return HttpRequest.get(`${SERVER_URL}/subsOrder/${query}`, token);
};

export const getOneSubOrderService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/subsOrder/one/${id}`, token);
};

export const addOneItemServiceOrder = async (data: any, token: string) => {
  return HttpRequest.update(`${SERVER_URL}/subsOrderItem`, data, token);
};

export const deleteSubOrderService = async (id: string, token: string) => {
  return HttpRequest.delete(`${SERVER_URL}/subsOrder/one/${id}`, token);
};


export const updateSubOrderService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsOrder/one/${itemId}`,
    data,
    token
  );
};



export const updateSubsOrderStatusService = async (
  orderId: string,
  status: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsOrder/one/${orderId}`,
    status,
    token
  );
};
export const getOrderStatusService= async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/statistics/subsStats`, token);
};
