import { Paper, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";

const Component = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Paper
        elevation={1}
        sx={{ width: "23%", p: 1 }}
        className=""
        onClick={() => navigate(`/inventory/ws/out/req/${props.data.id}`)}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack spacing={0}>
              <h1>{props?.data?.requestId}</h1>
              <p className="text-xs" style={{ color: "blue" }}>
                {props?.data?.shopName} ~ {props?.data?.data?.shopId?.address}
              </p>
            </Stack>
            <Tag
              color={searchValue(props?.data?.status)}
              style={{ minWidth: "4rem", textAlign: "center" }}
            >
              {props?.data?.status}
            </Tag>
          </Stack>
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0} style={{ marginTop: "10px" }}>
                <p className="text-xs py-0.5">
                  Approved As:{" "}
                  <b>
                    {" "}
                    {props?.data?.data?.isTransferred
                      ? "Transfer"
                      : "Warehouse Out"}
                  </b>
                </p>
                <p className="text-xs">
                  Approved By: <b>{props?.data?.data?.approvedBy?.names}</b>
                </p>
                <p className="text-xs">
                  Approved On:{" "}
                  <b>{props?.data?.data?.updatedAt?.split("T")[0]}</b>
                </p>
                <p className="text-xs">
                  Created On: <b>{props?.data?.date}</b>
                </p>
              </Stack>
              <p className="text-xs pr-3">
                <b>{props?.data?.numberReq} items</b>
              </p>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default Component;
