import React from "react";
import { Button, Form, Input, Select } from "antd";
import { DatePicker } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const { Search } = Input;
const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
  console.log(info?.source, value);
const layout = {
  labelCol: { span: 4 },
};
const RegisterItem = () => {
  return (
    <div>
      <Form
        name="wrap"
        {...layout}
        // labelAlign="left"
        colon={false}
        style={{ width: "100%" }}
      >
        <div className="flex gap-2 w-full pt-5">
          <div className="w-full">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Op.code:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Decl.code:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10  w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Seq:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                HSCODE:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Item Code:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Origin:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              PKG Qty:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Qty:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Unit:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
          </div>
          <div className="w-full">
          <Form.Item
          label=<span className="text-[12px] text-[#030229] pt-2">
          Item Desc:
          </span>
          name="name"
          rules={[{ required: true }]}
        >
          <Input className="h-10 w-[22rem]" />
        </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Supplier:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Agent:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Tax Payer Name:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem] " />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Item Name:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Gross WT:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Invoice AMT:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Invoice Currency:
              </span>
              name="code"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>

            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
              Rate:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[22rem]" />
            </Form.Item>
          </div>
        </div>

        <Form.Item label=" " className="w-1/2 m-auto mt-5">
          <CustomButton
            title={"Save"}
            bgColor="[#605BFF]"
            width="full"
            textColor="white"
            icon={<SaveIcon />}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegisterItem;
