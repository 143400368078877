import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { DatePicker } from 'antd';
import type { SearchProps } from "antd/es/input/Search";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CustomButton from '../buttons/CustomButton';
import {ReactComponent as SaveIcon} from "../../assets/images/icons/save.svg"

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';
const { Search } = Input;
const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
  console.log(info?.source, value);
const AddCustomerForm = () => 
{ 
  
  return(
  <div>
     <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: "200px",float:"right" }}
            />
              <Form
    name="wrap"
    // labelAlign="left"
    colon={false}
    style={{ width: "100%"}}
  >
    <div className='flex gap-10 w-full pt-5'>
    <div className='flex gap-4'>
    <span className='text-[12px] text-[#030229] pt-2'>Type:</span>
    <Select
showSearch
style={{ width: 250,height:40 }}
placeholder="No Use"
optionFilterProp="children"
filterOption={(input, option) => (option?.label ?? '').includes(input)}
filterSort={(optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}
options={[
  {
    value: '1',
    label: 'No User',
  },
  {
    value: '2',
    label: 'users',
  }
]}
/>
  </div>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Delegator:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Phone 1:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>

      <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>TIN:</span> name="code" rules={[{ required: true }]} >
      <Input className='h-10 w-64'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Nationality:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Phone 2:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>

      <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Name:</span> name="code" rules={[{ required: true }]} >
      <Input className='h-10 w-64'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>E-mail:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Fax No:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>

      <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Bank:</span> name="code" rules={[{ required: true }]} >
      <Input className='h-10 w-64'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Account:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Depositor:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    </div>
    <div className='flex gap-10 w-full'>

      <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Address:</span> name="code" rules={[{ required: true }]} >
      <Input className='h-10 w-64'/>
    </Form.Item>

    <Form.Item label=<span className='text-[12px] text-[#030229] pt-2'>Remark:</span> name="name" rules={[{ required: true }]}>
      <Input className='h-10 w-64'/>
    </Form.Item>
    <div className='flex gap-4'>
    <span className='text-[12px] text-[#030229] pt-2'>Use:</span>
    <Select
showSearch
style={{ width: 270,height:40 }}
placeholder="No Use"
optionFilterProp="children"
filterOption={(input, option) => (option?.label ?? '').includes(input)}
filterSort={(optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}
options={[
  {
    value: '1',
    label: 'No User',
  },
  {
    value: '2',
    label: 'users',
  }
]}
/>
  </div>
    </div>
    <div className='flex gap-10 w-full'>
    <div className='flex gap-4'>
    <span className='text-[12px] text-[#030229] pt-2'>Group:</span>
    <Select
showSearch
style={{ width: 270,height:40 }}
placeholder="No Use"
optionFilterProp="children"
filterOption={(input, option) => (option?.label ?? '').includes(input)}
filterSort={(optionA, optionB) =>
  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
}
options={[
  {
    value: '1',
    label: 'No User',
  },
  {
    value: '2',
    label: 'users',
  }
]}
/>
  </div>
    </div>
    <Form.Item label=" " className='w-1/2 m-auto mt-5'>
   <CustomButton title={"Save"} bgColor='[#605BFF]' width='full' textColor='white' icon={<SaveIcon/>}/>
    </Form.Item>
  </Form>
  </div>

);}

export default AddCustomerForm;