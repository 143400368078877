import React from 'react';
import { Table } from 'antd';
import HoverDropDown from '../DropDown/HoverDropDown';
import type { MenuProps } from 'antd';
import { GrFormView } from 'react-icons/gr';
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";


const PurchaseTable = (props:any) => {
  console.log("props",props)
  const items: MenuProps['items'] = [
    {
        label: <div className='flex gap-2'><GrFormView size={25}/><span className="text-sm pt-1">View Details</span></div>,
        key: '0',
        onClick: () => props?.open(),
    },
    {
        label: <div className='flex gap-3'><FiEdit  size={22}/><span className="text-sm pt-1">Modify</span></div>,
        key: '1',
        onClick: () => props?.modalOpen(),
    },
    {
        label: <div className='flex gap-2'><MdDeleteOutline  size={25}/><span className="text-sm pt-1">Delete</span></div>,
        key: '3',
    },
];

  const columns= [
    {
      title: 'Invoice ID',
      dataIndex: 'id',
    },
    {
      title: 'Supplier',
      dataIndex: 'name',
    },
    {
      title: 'Supply Date',
      dataIndex: 'date',
    },
    {
      title: 'Accept Date',
      dataIndex: 'date',
    },
    {
      title: 'Purchase Code',
      dataIndex: 'quantity',
    },
    {
      title: 'Purchase Amount',
      dataIndex: 'price',
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
    },
    
    {
      title: 'Status',
      dataIndex: 'status',
      render :(text:any)=>(
        <>
        <button className='border border-[#03022940] px-3 py-1  rounded-lg' >{text}</button>
        </>
    )
    },
    {
      title: ' ',
      dataIndex: 'data',
      render :()=>(
          <>
          <HoverDropDown items={items}/>
          </>
      )
    },
  ];
  
  const data= [
    {
      key: '1',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Wait for approval"
    },
    {
      key: '2',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Approved"
    },
    {
    key: '3',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Wait for approval"
    },
    {
      key: '4',
      id:"3719",
      name: 'Strutika Suzan Garfinkle',
      date:"21-05-2024",
      qauntity:"2",
      price:"15000",
      sales:"0",
      vat:"0",
      status:"Approved"
    },
    
   
  ];
  return(
  
  <>
    <Table columns={columns} dataSource={data} size="middle" />
  </>
)};

export default PurchaseTable;