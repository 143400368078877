import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "../../../components/cards/DetailsPage/DetailsTabs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShopSalesTable from "../../../components/tables/ShopSalesTable";
import ShopStockTable from "../../../components/tables/shop/ShopStockTable";
import ShopAgentTable from "../../../components/tables/shop/ShopAgentTable";
import {
  createRequestStockAction,
  getAllStockSentAction,
} from "../../../store/channel/actions";
import { getOneShopAction } from "../../../store/shop/actions";
import RequestStockView from "./RequestStockView";
import ShopProfile from "./shopProfile";
import DeviceWithCommssion from "./deviceWithCommssion";

type TAB = {
  title: string;
  component: any;
  button?: any;
};

const ShopDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { channel, auth, shop } = useSelector((state: any) => state);
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCommissionDevices, setShowCommissionDevices]=useState(false)
  const handleShowCommissionDevices=()=>{
    setShowCommissionDevices(true)
  }

  const tabs: TAB[] = [
    { title: "Profile", 
    component: <ShopProfile handleShowCommissionDevices={handleShowCommissionDevices} data={shop}/>
  },
    {
      title: "Stock",
      component: <ShopStockTable />,
    },
    {
      title: "Stock Requests",
      component: <RequestStockView />,
      button: (
        <LoadingButton
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={async () => {
            if (auth?.token) {
              const res: any = await createRequestStockAction(auth?.token, {
                shopId: shopId,
                channel: channel?.selected?._id,
              })(dispatch);
              if (res) {
                navigate(
                  `/channel/${channel?.selected?.name}/pos/shops/${shopId}/${res?.requestId}`
                );
              }
            }
          }}
          loading={channel?.isFetching}
        >
          Request Stock
        </LoadingButton>
      ),
    },
    {
      title: "Agent",
      component: <ShopAgentTable />,
    },
  ];
  useEffect(() => {
    auth?.token && getOneShopAction(shopId as string, auth?.token)(dispatch);
    auth?.token &&
      shopId &&
      getAllStockSentAction(auth?.token, `?shopId=${shopId}`)(dispatch);
  }, [auth?.token, channel.query, dispatch, shopId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    
    <div className="md:mt-10 xl:mt-5 text-black h-full overflow-y-auto scrollbar-hide">
    {showCommissionDevices? <DeviceWithCommssion/>:<>
      <DetailsHeaderActionBar
        pageName="Shop"
        title={`${shop?.selected?.name} ~ ${
          shop?.selected?.type === "service-center"
            ? "SC"
            : shop?.selected?.type === "connect-shop"
            ? "CS"
            : shop?.selected?.type
        }`}
        buttonsComponent={tabs[activeTab]?.button}
      />
      <DetailsTabs
        tabs={tabs}
        activeTab={activeTab}
        handleChange={handleChange}
      />
      </>}
    </div>
  );
};

export default ShopDetails;
