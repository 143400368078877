import React, { useEffect, useState } from "react";
import DevicesSummary from "./devicesSummary";
import { ReactComponent as ReacdyForUseDevice }   from "../../../../assets/images/vault/icons/gift.svg";
import {ReactComponent as Released} from "../../../../assets/images/vault/icons/streamline_travel-map-navigation-arrow-compass-arrow-map-bearing-navigation-maps-heading-gps.svg"
import {ReactComponent as Locked} from "../../../../assets/images/vault/icons/lock.svg"
import {ReactComponent as Active} from "../../../../assets/images/vault/icons/tabler_activity-heartbeat.svg";
import { Space, Table, Tag ,Select} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import SearchInput from "../../../../components/buttons/SearchButton"
import * as piIcons from "react-icons/pi"
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import DeviceDetails from "./deviceDetails";
import { getAllDevicesAction, getDeviceDetailsAction, getDeviceServiceStatusAction } from "../../../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
interface DataType {
  key: string;
  imei: string;
  brand: string;
  model: string;
  lastChange: string;
  lastCheckIn: string;
  status: any;
}
const PrepaidDevices = () => {
  const {auth,vault}=useSelector((state:any)=>state)
  const dispatch=useDispatch();
  const [searchKey, setSearchKey] = useState();
  const [viewDetail,setViewDetails]=useState(false);
  const handleChange=(values:any)=>{
console.log(values,"changed")
  }
  useEffect(()=>{
    auth?.token &&
    getDeviceServiceStatusAction(
      auth?.token,
      `?serviceDetails=PREPAID`
    )(dispatch);
  },[auth?.token,dispatch])
  useEffect(() => {
    auth?.token && getAllDevicesAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);
  const styles = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
  };
  const PrePaidDeviceData = vault?.all?.data?.map((el: any) => {
      if (el?.enableVaultResponse?.serviceDetails?.toUpperCase() === "PREPAID") {
        return {
          key: el?.enableVaultResponse?.TAC,
          imei: el?.enableVaultResponse?.deviceUniqueID,
          brand: el?.enableVaultResponse?.tenantName,
          model: el?.enableVaultResponse?.deviceModel,
          lastChange: el?.enableVaultResponse?.lastChanged?.slice(0,10),
          lastCheckIn: el?.enableVaultResponse?.lastCheckIn?.slice(0,10),
          status: el?.enableVaultResponse?.stateInfo,  
          data: el,
        };
      }
      return null; 
  })
  const filteredPrePaidDeviceData = PrePaidDeviceData.filter((item:any) => item !== null);
  const columns: ColumnsType<DataType> = [
    {
      title: 'IMEI',
      dataIndex: 'imei',
      key: 'imei',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'MODEL',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
              <Tag   key={status} className={`py-1 w-24 text-center rounded-full ${status ==="active"? "text-[#0FA91E] border border-[#0FA91E80]":status ==="locked"? "text-[#FA3659] border border-[#FA365980]":status ==="released"? "text-[#2943D6] border border-[#2943d622]":status ==="ready for use"? "text-[#F19F1D] border border-[#2943d622]":"text-[#2943D6] border border-[#2943d622]"} `}>
                {status}
              </Tag>
        </>
      ),
    },
    {
      title: 'LAST CHANGE',
      dataIndex: 'lastChange',
      key: 'lastChange',
    },
    {
      title: 'LAST CHECK-IN',
      dataIndex: 'lastCheckIn',
      key: 'lastCheckIn',
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (_, record) => (
        <Space>
          <piIcons.PiEyeLight size={20} className="cursor-pointer" onClick={()=>setViewDetails(true)}/>
      </Space>
      ),
    },
  ];
  
  
  return (
    <>
    {!viewDetail ?
    <div>
         <DetailsHeaderActionBar
        // handleClickDelete={props?.handleClickDelete}
        // handleClickEdit={props?.handleClickEdit}
        // goBack={goBack}
        pageName={"Manage devices"}
        // title={layout?.userInfo?.names}
        title="Prepaid Devices"
      />
      <div className="w-full h-[119px] bg-white" style={styles}>
   
        <div className="flex gap-5 divide-x pt-3">
            <DevicesSummary status={"Ready for use"} value={(vault?.deviceServiceStatus?.data?.ReadyForUse)?vault?.deviceServiceStatus?.data?.ReadyForUse:"0"} customeIcon={<ReacdyForUseDevice/>} border={"[#2943d622]"}/>
            <DevicesSummary status={"Active"} value={(vault?.deviceServiceStatus?.data?.active)?vault?.deviceServiceStatus?.data?.active:"0"} customeIcon={<Active/>} border={"[#0FA91E]"}/>
            <DevicesSummary status={"Locked"} value={(vault?.deviceServiceStatus?.data?.locked)?vault?.deviceServiceStatus?.data?.locked:"0"} customeIcon={<Locked/>} border={"[#2943d622]"}/>
            <DevicesSummary status={"Released"} value={(vault?.deviceServiceStatus?.data?.released)?vault?.deviceServiceStatus?.data?.released:"0"} customeIcon={<Released/>} border={"[#2943d622]"}/>

        </div>
        <div className="flex justify-between my-8">
        <Select
          defaultValue="Filter By All"
          style={{ width: 300 }}
          onChange={handleChange}
          options={[
            { value: "all", label: "Filter By All" },
            { value: "Ready", label: "Ready for use" },
            { value: "active", label: "Active" },
            { value: "locked", label: "Locked" },
            { value: "Realeased", label: "Released" }
          ]}
        />
        <SearchInput
                  // onSearch={handleSearch}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setSearchKey(e.target.value);
                  }}
                />
                </div>
                <div>
                <Table columns={columns} loading={vault?.isFetching} dataSource={filteredPrePaidDeviceData} />
                </div>
      </div>
    </div>:
      <DeviceDetails viewDetail={viewDetail} setViewDetails={setViewDetails}/>
                }
                </>
  );
};

export default PrepaidDevices;
