import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function DisabledTabs(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props?.setSelectedType(
      newValue === 0 ? "" : newValue === 1 ? "b2b" : "b2c"
    );
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="disabled tabs example"
    >
      <Tab label="All Accounts" />
      <Tab label="B2B Accounts" />
      <Tab label="B2C Accounts" />
    </Tabs>
  );
}
