import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DatePicker,
  DatePickerProps,
  Skeleton,
  Modal,
  Table,
  MenuProps,
  Space,
  Dropdown,
  Tag,
} from "antd";
import { MdDelete } from "react-icons/md";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { AiOutlineUnorderedList, AiTwotoneEdit } from "react-icons/ai";
import {
  Divider,
  Typography,
  Card,
  Box,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import FilterButton from "./components/FilterButton";
import ThreeDotDropdown from "./accountactions/threeDotDropdown";
import {
  deleteOneAccountAction,
  getAllAccountAction,
} from "../../../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import UpdateAccountForm from "../../../../components/forms/UpdateAccountForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import SubAccountFilter from "../../../../components/filters/subAccountFilter";
import { TbGridDots } from "react-icons/tb";
import { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../../utils/setColor";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { myAccounts } from "../../../../store/account";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { useParams } from "react-router-dom";
import { handleClearAllState } from "../../../../utils/converter";
import { myInvoices } from "../../../../store/invoice";
import { handlerSelectTabs } from "../../../../store/layout/actions";
import { accountIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";

const DetailsPage = (props: any) => {
  const navigate = useNavigate();
  const { auth, account } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedType, setSelectedType] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);

  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  const clearActions = [mySubscritionActions.setSelected, myInvoices?.setAll];

  useEffect(() => {
    if (selectedStatus !== "") {
      auth?.token &&
        getAllAccountAction(
          auth?.token,
          `?&page=${page - 1}&limit=${limit}&status=${selectedStatus}${
            selectedType ? `&type=${selectedType}` : ``
          }&field=${accountIncludeItem}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllAccountAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&${
            selectedType ? `type=${selectedType}` : ``
          }&field=${accountIncludeItem}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, selectedType, limit, page, selectedStatus]);

  useEffect(() => {
    if (!accountId) {
      dispatch(myAccounts.setSelected(null));
      dispatch(mySubscritionActions.setSelected(null));
    }
  }, [accountId]);

  const openUpdateModel = (value: any) => {
    setUpdateData(value);
    setIsUpdateModalOpen(true);
  };

  const onCancelUpdateModel = () => {
    setUpdateData("");
    setIsUpdateModalOpen(false);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  const deleteaccount = async () => {
    await deleteOneAccountAction(auth?.token, deleteDataId, {
      isDeleted: true,
    })(dispatch);
    onCancelDeleteModel();
  };
  const [activeAccount, setActiveAccount] = useState("accounts as grid");

  interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 150,
    },
    {
      key: "tel",
      title: "  Telephone",
      dataIndex: "tel",
      width: 150,
    },
    {
      key: "payment",
      title: "Monthly Payment",
      dataIndex: "payment",
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "device",
      title: "devices",
      dataIndex: "device",
    },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              className={`h-[1.5rem] w-[3.5rem] rounded-md  ${
                status === "active"
                  ? "bg-[#0FA958]"
                  : status === "Inactive"
                  ? "bg-[#F1595C]"
                  : "bg-yellow-300"
              }`}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                {status}
              </Typography>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "disable-navigation",
      render: (_, record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                onClick={() => openUpdateModel(record?.data)}
                className="flex gap-2"
              >
                <AiTwotoneEdit className=" fill-green-500" size={25} /> Edit
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                onClick={(e) => openDeleteModel(record?._id)}
                className="flex gap-2"
              >
                <MdDelete className="pt-1 fill-red-500" size={25} /> Delete
              </label>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-2">
              <Space direction="vertical">
                <Dropdown menu={{ items }} placement="bottom">
                  <BiDotsHorizontalRounded size={32} />
                </Dropdown>
              </Space>
            </div>
          </>
        );
      },
    },
  ];
  const value = account?.all?.data;

  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          key: el._id,
          name: el?.customerDetails?.name,
          tel: el?.customerDetails?.tel,
          device: el?.deliveryNote?.list?.length,
          payment: "250,000",
          date: el?.createdAt?.substring(0, 10),
          time: el?.createdAt?.substring(11, 16),
          status: el?.status,
          data: el,
          action: {
            openUpdateModel,
            openDeleteModel,
          },
        });
      });

    setformData(dataMapper);
  }, [value]);
  const handleIconClick = (iconType: string) => {
    setActiveAccount(iconType);
  };

  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
    handlerSelectTabs(0)(dispatch);
  }, []);

  return (
    <>
      <div className="my-3 text-black">
        <DetailsHeaderActionBar pageName="Subscription" title="Account" />
      </div>
      <div className=" h-full w-full bg-white ">
        <div className="flex flex-row px-4 justify-between">
          <SubAccountFilter setSelectedStatus={setSelectedStatus} />
          <FilterButton setSelectedType={setSelectedType} />
          <div className="flex flex-row items-center gap-4">
            <DatePicker onChange={onChange} className={"w-48"} />
            <div className="text-gray-500 flex flex-row px-4">
              <div
                style={{
                  color:
                    activeAccount === "accounts as grid" ? "#3b82f6" : "gray",
                  cursor: "pointer",
                }}
              >
                <TbGridDots
                  size={30}
                  onClick={() => handleIconClick("accounts as grid")}
                />
              </div>

              <AiOutlineUnorderedList
                size={30}
                onClick={() => handleIconClick("accounts as table")}
                className={`cursor-pointer ${
                  activeAccount === "accounts as table"
                    ? "fill-blue-500"
                    : "fill-gray-500"
                }`}
              />
            </div>
          </div>
        </div>
        <Divider sx={{ mt: 1, mb: 1 }} />
        {activeAccount === "accounts as grid" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                pl: 2,
                gap: 2.5,
              }}
            >
              {account?.isFetching ? (
                <div className="flex flex-wrap">
                  {[1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4]?.map((el: any) => (
                    <CurstomeCardSkeleton />
                  ))}
                </div>
              ) : (
                <>
                  {account?.all?.data?.map((items: any) => (
                    <div className="">
                      <div
                        onClick={(event: any) => {
                          const isDisabledDiv = event.target.closest(
                            ".ant-dropdown-trigger"
                          );
                          const isDisabledlabel =
                            event.target.closest(".ant-dropdown-menu");
                          if (!isDisabledDiv && !isDisabledlabel) {
                            navigate(`/subscription/account/${items?._id}`);
                          }
                        }}
                      >
                        <Card
                          sx={{ width: 350, height: 160 }}
                          className="px-3 pt-3"
                        >
                          <Stack direction="column" spacing={1}>
                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                            >
                              <Stack direction="column">
                                <Typography
                                  noWrap
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: 17,
                                    width: "10rem",
                                  }}
                                >
                                  {items?.accountID}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 900,
                                    fontSize: 19,
                                    color: `${
                                      items?.type === "b2b"
                                        ? "#57967c"
                                        : "#5c4721"
                                    }`,
                                  }}
                                  className="capitalize"
                                >
                                  {items?.type?.toUpperCase()}
                                </Typography>
                              </Stack>
                              <Stack direction={"row"} spacing={2}>
                                <Tag
                                  className="w-[70px] h-[25px] py-1 text-center capitalize"
                                  color={searchValue(items?.status)}
                                >
                                  {items?.status}
                                </Tag>
                                <div>
                                  <ThreeDotDropdown
                                    onUpdate={() => openUpdateModel(items)}
                                    onDelete={() => openDeleteModel(items?._id)}
                                  />
                                </div>
                              </Stack>
                            </Stack>

                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                            >
                              <Stack direction="column">
                                <Typography
                                  noWrap
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: 15,
                                  }}
                                >
                                  {items?.customerDetails?.name}
                                </Typography>
                                <Typography sx={{ fontSize: 15 }}>
                                  {items?.customerDetails?.tel}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Stack direction="row" spacing={1}>
                                <Tooltip title="20/08/2023">
                                  <IconButton aria-label="delete" size="small">
                                    <EventAvailableIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                -
                                <Tooltip title="20/02/2024">
                                  <IconButton aria-label="delete" size="small">
                                    <EventBusyIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="20 Contacts">
                                  <IconButton aria-label="delete" size="small">
                                    <PermContactCalendarIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="20 Notes">
                                  <IconButton aria-label="delete" size="small">
                                    <TextSnippetIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>{" "}
                              <Stack direction="row" spacing={1}>
                                <Typography
                                  noWrap
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                  }}
                                >
                                  Devices:
                                </Typography>
                                <Typography
                                  noWrap
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                  }}
                                >
                                  {items?.deliveryNote?.list?.length || "N/A"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Card>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Box>
          </ScrollableFrame>
        )}
        {activeAccount === "accounts as table" && (
          <ScrollableFrame
            setLimit={setLimit}
            setPage={setPage}
            limit={limit}
            total={account?.all?.total}
            count={Math.ceil(account?.all?.total / limit)}
          >
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: (event: any) => {
                    const isDisabledColumn = event.target.closest(
                      ".disable-navigation"
                    );
                    const isDisabledlabel =
                      event.target.closest(".ant-dropdown-menu");
                    if (!isDisabledColumn && !isDisabledlabel) {
                      navigate(`/subscription/account/${record?.key}`);
                    }
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={account?.isFetching}
            />
          </ScrollableFrame>
        )}
      </div>

      <Modal
        style={{ maxWidth: "100%" }}
        title="Update Account"
        open={isUpdateModalOpen}
        onCancel={onCancelUpdateModel}
        footer={null}
      >
        <UpdateAccountForm
          dataToUpdate={updateData}
          onCancel={onCancelUpdateModel}
        />
      </Modal>

      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteaccount}
        onCancel={onCancelDeleteModel}
        itemName={"Account"}
        isLoading={account?.isFetching}
      />
    </>
  );
};
export default DetailsPage;
