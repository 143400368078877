import React from "react";
import { Select } from "antd";
import * as tfIcons from "react-icons/tfi";
const VaultFilter = () => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <div className="flex gap-3 my-2 ml-4">
        <tfIcons.TfiFilter size={20} className="mt-1" />
        <Select
          defaultValue="Filter By All"
          style={{ width: 180 }}
          onChange={handleChange}
          options={[
            { value: "all", label: "Filter By All" },
            { value: "prepaid", label: "Prepaid Devices" },
            { value: "postpaid", label: "PostPaid Devices" }
          ]}
        />
      </div>
    </>
  );
};
export default VaultFilter;
