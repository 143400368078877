import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import phoneImage from "../../../../assets/images/vault/Rectangle 1381.png";
import { Tag, Button, Dropdown, Space } from "antd";
import { searchValue } from "../../../../utils/setColor";
import { useDispatch, useSelector } from "react-redux";
import type { MenuProps } from "antd";
import VaultButton from "../../../../components/buttons/vaultButton";
import { ReactComponent as RefleshIcon } from "../../../../assets/images/vault/icons/material-symbols_history.svg";
import { ReactComponent as HomeIcon } from "../../../../assets/images/vault/icons/mingcute_home-3-line.svg";
import { ReactComponent as LockIcon } from "../../../../assets/images/vault/icons/majesticons_lock-line.svg";
import { ReactComponent as ReleaseIcon } from "../../../../assets/images/vault/icons/akar-icons_paper-airplane.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/images/vault/icons/mingcute_message-4-line.svg";
import {
  getnotesAction,
  lockDeviceAction,
} from "../../../../store/vault/actions";
import VaultModal from "../../../../components/Modals/vaultModal";
import UnlockDevice from "./unlockDevice";
import SendMessage from "./sendMessage";
import { RiArrowDropDownLine } from "react-icons/ri";
import VaultNotes from "./vaultNotes";
import LockDevice from "./lockDevice";
import { Carousel } from 'antd';
interface detailsProps {
  viewDetail: any;
  setViewDetails: any;
  deviceData?: any
}
const DeviceDetails = ({ viewDetail, setViewDetails, deviceData }: detailsProps) => {
  const { auth, layout, vault } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLockModalOpen, setIsLockModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [vaultData, setVaultData] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const showModal = () => {
    setIsModalOpen(true);
    setVaultData(deviceData?.data?.enableVaultResponse)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showLockModal = () => {
    setIsLockModalOpen(true);
    setVaultData(deviceData?.data?.enableVaultResponse)
  };

  const handleLockModalOk = () => {
    setIsLockModalOpen(false);
  };

  const handleLockModalCancel = () => {
    setIsLockModalOpen(false);
  };
  const showMessageModal = () => {
    setIsMessageModalOpen(true);
    setVaultData(deviceData?.data?.enableVaultResponse)
  };

  const handleMessageOk = () => {
    setIsMessageModalOpen(false);
  };

  const handleMessageCancel = () => {
    setIsMessageModalOpen(false);
  };
  useEffect(() => {
    auth?.token &&
      getnotesAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&warehouseItem=${deviceData?.data?._id}`
      )(dispatch);
  }, [auth?.token, dispatch, page, limit])


  const styles = {
    border: "1px solid rgba(3, 2, 41, 0.10)"
  };

  const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span className="flex gap-2 text-sm">
          {" "}
          <HomeIcon className="mt-[2px]" />
          Deregister
        </span>
      )
    },
    {
      key: "2",
      label: (
        <span className="flex gap-2 text-sm" onClick={showLockModal}>
          {" "}
          <LockIcon className="mt-[2px]" />
          Lock
        </span>
      )
    },
    {
      key: "3",
      label: (
        <span className="flex gap-2 text-sm" onClick={showModal}>
          {" "}
          <ReleaseIcon className="mt-[2px]" />
          Unlock
        </span>
      )
    },
    {
      key: "4",
      label: (
        <span className="flex gap-2 text-sm" onClick={showMessageModal}>
          {" "}
          <MessageIcon className="mt-[2px]" />
          Send message
        </span>
      )
    }
  ];
  return (
    <>
      <div className="">
        {viewDetail && (
          <>
            {" "}
            <DetailsHeaderActionBar
              // handleClickDelete={props?.handleClickDelete}
              // handleClickEdit={props?.handleClickEdit}
              goBack={() => setViewDetails(false)}
              pageName={"Manage devices"}
              // title={layout?.userInfo?.names}
              title="View Details"
            />
            <div>
              <div className="flex justify-between mt-4">
                <div
                  className={`flex gap-5 ${layout?.isSideNavOpen ? "w-[760px]" : "w-[900px]"
                    }  h-[208px] rounded-md py-9 px-8`}
                  style={styles}
                >
                  <div className="bg-[#E0E0E0] w-[106px] p-2 h-[120px] rounded-md">
                    <Carousel autoplay >
                      {deviceData?.data?.product?.images?.map((img: any) => (
                        <img src={img} alt="" />
                      ))}
                    </Carousel>
                  </div>
                  <div className="flex justify-between  w-full">
                    <div>
                      <h1 className="text-[#030229] font-medium text-lg">
                        {deviceData?.data?.enableVaultResponse?.deviceModel}
                      </h1>
                      <p className="mt-4">
                        <span className="text-[#030229] text-base font-medium">
                          IMEI:
                        </span>
                        <span className="text-[#03022976] text-sm  pl-3">
                          {deviceData?.data?.enableVaultResponse?.deviceUniqueID}
                        </span>
                      </p>
                      <p className="mt-3">
                        <span className="text-[#030229] text-base font-medium">
                          Brand:
                        </span>
                        <span className="text-[#03022976] text-sm  pl-3">
                          {deviceData?.brand}
                        </span>
                      </p>
                      <p className="mt-3">
                        <span className="text-[#030229] text-base font-medium">
                          Model:
                        </span>
                        <span className="text-[#03022976] text-sm pt-5 pl-3">
                          {deviceData?.data?.enableVaultResponse?.deviceModel}
                        </span>
                      </p>
                    </div>
                    <div className="ml-10">
                      <Tag
                        color={searchValue(deviceData?.data?.enableVaultResponse?.stateInfo)}
                        className="rounded-full"
                      >
                        {deviceData?.data?.enableVaultResponse?.stateInfo}
                      </Tag>
                      <p className="text-[#03022998] text-sm pt-7">
                        {deviceData?.data?.enableVaultResponse?.createdTimeStamp?.slice(0, 10)}, {deviceData?.data?.enableVaultResponse?.createdTimeStamp?.slice(11, 19)}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${layout?.isSideNavOpen ? "w-[470px]" : "w-[500px]"
                    }  h-[208px] rounded-md py-7 px-8`}
                  style={styles}
                >
                  <p className="">
                    <span className="text-[#030229] text-base font-medium">
                      Tenant:
                    </span>
                    <span className="text-[#03022976] text-sm  pl-3">
                      {deviceData?.data?.enableVaultResponse?.tenantName}
                    </span>
                  </p>
                  <p className="mt-3">
                    <span className="text-[#030229] text-base font-medium">
                      Last Change:
                    </span>
                    <span className="text-[#03022976] text-sm  pl-3">
                      {deviceData?.data?.enableVaultResponse?.lastChanged?.slice(0, 10)} {deviceData?.data?.enableVaultResponse?.lastChanged?.slice(11, 19)}
                    </span>
                  </p>
                  <p className="mt-3">
                    <span className="text-[#030229] text-base font-medium">
                      Last Check-in:
                    </span>
                    <span className="text-[#03022976] text-sm pt-5 pl-3">
                      {deviceData?.data?.enableVaultResponse?.lastCheckIn?.slice(0, 10)} {deviceData?.data?.enableVaultResponse?.lastCheckIn?.slice(11, 19)}
                    </span>
                  </p>
                  <p className="mt-3">
                    <span className="text-[#030229] text-base font-medium">
                      Device ID:
                    </span>
                    <span className="text-[#03022976] text-sm pt-5 pl-3">
                      {deviceData?.data?.enableVaultResponse?.deviceUniqueID}
                    </span>
                  </p>
                </div>
                <VaultModal
                  onOpen={isModalOpen}
                  onClose={handleCancel}
                  title={<h1 className="text-center text-[#030229] text-lg py-4">Unlock</h1>}
                  component={<UnlockDevice onClose={handleOk} data={vaultData} />}
                />
                <VaultModal onOpen={isMessageModalOpen} onClose={handleMessageCancel} width={700} title={<h1 className="text-center text-[#030229] text-lg py-4">Send Message</h1>}
                  component={<SendMessage onClose={handleMessageOk} data={vaultData} />}
                />
                <VaultModal onOpen={isLockModalOpen} onClose={handleLockModalCancel} width={400} title={<h1 className="text-center text-[#030229] text-lg py-4">Lock Device</h1>}
                  component={<LockDevice onClose={handleLockModalOk} data={vaultData} />}
                />
              </div>
              <div className="flex justify-between mr-5 mt-5">
                <div className="flex gap-2 cursor-pointer">
                  <RefleshIcon />
                  <h1 className="text-black">Refresh</h1>
                </div>
                <div className="w-28">
                  <Dropdown menu={{ items }} placement="bottomLeft">
                    <button className="bg-[#605BFF] flex gap-1 rounded-md px-4 text-white">
                      <span className=" py-2">Actions</span>  <RiArrowDropDownLine size={30} className=" mt-[3px]" />
                    </button>
                  </Dropdown>
                </div>
              </div>
              <VaultNotes setPage={setPage} setLimit={setLimit} limit={limit} />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default DeviceDetails;