import React from "react";
import { Button, Paper, Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useSelector } from "react-redux";

const WarehouseFilter = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const [selectedFilters, setSelectedFilters] = React.useState(false);
  return (
    <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          startIcon={
            selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
          }
          // onClick={() => {
          //   setSelectedFilters(!selectedFilters);
          //   resetFilter();
          // }}
        >
          Filter
        </Button>

        <h1 className="text-gray-400">
          Result:{" "}
          {wareHouse?.isFetching ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            wareHouse?.all?.result
          )}
        </h1>
      </Stack>
    </Paper>
  );
};

export default WarehouseFilter;
