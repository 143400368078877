import React from "react";
import { Card, Table, Tag, Input } from "antd";
import { ManOutlined, ToolOutlined, UserOutlined, WomanOutlined } from "@ant-design/icons";
import AttendanceChart from "./AttendanceChart";
import DepartmentFiltering from "../department/DepartmentFiltering";
import TimeComponent from "../overview/TimeComponent";

const { Search } = Input;

const stats = [
  { label: 'Total Employees', value: 452, change: '+', text: "2 new employees added!", icon: <UserOutlined />, color: 'text-green-500' },
  { label: 'On Time', value: 360, change: '+5% ', text: "Increase than last month", icon: <UserOutlined />, color: 'text-red-500' },
  { label: 'Absent', value: 30, change: '+3% ', text: "Increase than last month", icon: <ManOutlined />, color: 'text-blue-500' },
  { label: 'Early Departures', value: 6, change: '+3% ', text: "Increase than last month", icon: <WomanOutlined />, color: 'text-purple-500' },
  { label: 'Time-off', value: 42, change: '2% ', text: "Less than last month", icon: <UserOutlined />, color: 'text-blue-500' },
  { label: 'Total Pay-Checks', value: `${10}M`, change: '2% ', text: "Increase than last month", icon: <UserOutlined />, color: 'text-blue-500' }
];

const sampleData = [
  {
    key: '1',
    name: 'John Brown',
    department: 'Finance',
    date: '2024-05-20',
    checkIn: '09:00',
    checkOut: '17:00',
    workHours: 8,
    active: true,
  },
  {
    key: '2',
    name: 'Jane Smith',
    department: 'HR',
    date: '2024-05-20',
    checkIn: '09:30',
    checkOut: '17:30',
    workHours: 8,
    active: true,
  },
  {
    key: '3',
    name: 'Samuel Green',
    department: 'Engineering',
    date: '2024-05-20',
    checkIn: '10:00',
    checkOut: '18:00',
    workHours: 8,
    active: false,
  }
];

const Attendance: React.FC = () => {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Check-in',
      dataIndex: 'checkIn',
      key: 'checkIn',
    },
    {
      title: 'Check-out',
      dataIndex: 'checkOut',
      key: 'checkOut',
    },
    {
      title: 'Work hours',
      dataIndex: 'workHours',
      key: 'workHours',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_: any, record: any) => (
        <Tag color={record.active ? 'green' : 'volcano'}>
          {record.active ? 'Work from office' : 'Absent'}
        </Tag>
      ),
    },
  ];

  const onSearch = (value: string) => {
    console.log(value);
    // Implement search functionality if needed
  };

  return (
    <div className="p-4">
      <div className="flex flex-col md:flex-row items-stretch mb-4 px-2 gap-6">
        <div className="p-4  w-[100%] md:w-[40%] py-8 h-[292px] bg-white rounded-md">
          <TimeComponent />
        </div>
        <div className="flex flex-wrap gap-6">
          {stats.map((stat, index) => (
            <div key={index} className="w-full sm:w-full md:w-[50%]  2xl:w-[30%] bg-white rounded-md h-[134px]">
              <div className="flex flex-col items-center justify-between h-full py-2 px-3">
                <>
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="text-xl font-poppins text-black">{stat.value}</div>
                    <div className={`rounded-full p-1 ${stat.color}`}>
                      {stat.icon}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className="text-md font-poppins text-gray-500">{stat.label}</div>
                    <div></div>
                  </div>
                  <div className="flex flex-row items-center justify-between w-full">
                    <div className={`rounded-full px-2 py-2.5 ${stat.color}`}>
                      {stat.change}
                    </div>
                    <div className="text-gray-300 text-sm">{stat.text}</div>
                  </div>
                </>
              </div>
            </div>
          ))}
        </div>
        <div className=" w-[100%] md:w-[50%]  h-auto bg-white">
          <div className="font-poppins text-base font-normal leading-6 text-left">
            <div className="text-lg font-poppins mb-4 text-black px-2 py-2">Weekly Attendance</div>
          </div>
          <AttendanceChart />
        </div>
      </div>
      <div className="bg-white rounded-md">
        <div className="flex flex-col md:flex-row items-center justify-between pb-3 px-3 py-3">
          <h2 className="text-black">Attendance Overview</h2>
          <div className="flex flex-row items-center">
            <Search placeholder="input search text" onSearch={onSearch} enterButton />
            <DepartmentFiltering />
          </div>
        </div>
        <Table columns={columns} dataSource={sampleData} rowKey="key" />
      </div>
    </div>
  );
};

export default Attendance;


