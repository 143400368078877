import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useSelector } from "react-redux";
import ProductDetailsCard from "./productDetailsCard";
import History from "./history";
import DetailsHeaderActionBar from "../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import ProductPurchaseOrder from "./productPO";
import ProductInvoiceDetails from "./productInvoice";
import InventoryItemSearch from "../POS/InventoryItemSearch";

const ProductHistoryDetails = (props: any) => {
  const {inventory } = useSelector((state: any) => state);
  const styles = {
    customElement: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    customShadowCard: {
      boxShadow: "0px 1px 2px 2px rgba(194, 194, 194, 0.06)",
    },
    customBorderCard: {
      border: "0.5px solid rgba(3, 2, 41, 0.15)",
    },
  };

  const onChange = (key: string) => {
    console.log(key);
  };


  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <h1 className="font-medium text-base">TimeLine</h1>,
      children:inventory?.searchBySn?.data?.map((d: any) => {
        return <History data={d} />;
      }),
    },
    {
      key: "2",
      label: <h1 className="font-medium text-base">Purchase Order</h1>,
      children: inventory?.searchBySn?.data?.map((d: any) => {
        return <ProductPurchaseOrder data={d} />;
      }),
    },
    {
      key: "3",
      label: <h1 className="font-medium text-base">Receipt</h1>,
      children: inventory?.searchBySn?.data?.map((d: any) => {
        return <ProductInvoiceDetails data={d} />;
      }),
    },
  ];

  return (
    <>
      <div className="mt-2 h-[90vh] text-black overflow-y-auto scrollbar-hide">
        <DetailsHeaderActionBar
          goBack={() => props.setProductDetails(false)}
          pageName={"Manage Products"}
          title="Product History"
        />
        <InventoryItemSearch />
        <div
          className="bg-white pl-5 pt-2 text-black  rounded-md  mt-5 "
          style={styles.customElement}
        >
          <div className="mt-5">
            {inventory?.searchBySn?.data?.map((d: any) => {
              return <ProductDetailsCard data={d} />;
            })}
          </div>
          <Tabs items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
};

export default ProductHistoryDetails;
