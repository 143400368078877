import { useEffect, useState } from "react";
import { Stack, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Badge, Carousel, Modal, Tag, notification } from "antd";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import DeleteModal from "../../Modals/DeleteModal";
import {
  deletePlanAction,
  getSubscriptionPricelistItemAction,
} from "../../../store/channel/actions";

import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
// import AddItem from "../subscription/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";

const SubProductCardGrid = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceListData, setPriceListId] = useState(null);
  const [prodDataId, setProdDataId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [editSubPrice, setEditSubPrice] = useState(false);
  const dispatch = useDispatch();

  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();

  const handleOnClickView = (value: any, e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setPriceListId(null);
      setProdDataId(null);
    }
  }, [isModalOpen]);

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleEditSubPrice = async () => {
    setEditSubPrice(true);
  };
  const handleCancelSubPrice = () => {
    setEditSubPrice(false);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    await deletePlanAction(auth?.token, props?.data._id)(dispatch);
    notification.success({ message: "Plan deleted successfully" });
    await getSubscriptionPricelistItemAction(
      auth?.token,
      `?channel=${channel?.selected?._id}&page=0&limit=15&hasSubs=true`
    )(dispatch);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon
          color="green"
          text={`${
            props?.data?.subscriptionPlan[0]?.subscriptionType === "B2B"
              ? props?.data?.subscriptionPlan[0]?.monthlyInstalment?.toLocaleString()
              : itemPrice
          } RWF`}
        >
          <div
            className="relative w-80 h-52 p-2 pt-10 bg-white text-black rounded-md"
            onClick={(e: any) => handleOnClickView(props?.data, e)}
          >
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.product?.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge
                className="site-badge-count-10 text-sm p-2 h-1"
                style={{ backgroundColor: "#faad14" }}
              >
                <div className="flex flex-col pl-2 gap-1">
                  <h1 className="font-bold text-lg">
                    {props?.data?.product?.model}
                  </h1>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.specification?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <Stack>
                              <p className="text-xs">
                                <b>{data[0]}:</b>
                                {` ${data[1]}`}
                              </p>
                            </Stack>
                          )
                      )}
                  </div>
                </div>
              </Badge>
            </div>
          </div>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2">
            <Tag
              color={getColorByValue(props?.types, props?.data?.product?.type)}
            >
              {props?.data?.product?.type}
            </Tag>

            <Tag
              color={getColorByValue(
                props?.brands,
                props?.data?.product?.brand
              )}
            >
              {props?.data?.product?.brand}
            </Tag>
            <Tag
              color={searchValue(
                props?.data?.subscriptionPlan[0]?.subscriptionType
              )}
            >
              {props?.data?.subscriptionPlan[0]?.subscriptionType}
            </Tag>
            {[
              "admin",
              "finance",
              "finance-manager",
              "dev",
              "b2b-relation",
            ].includes(auth?.user?.role?.toLowerCase()) && (
              <>
                <IconButton
                  aria-label="fingerprint"
                  color="primary"
                  size="small"
                  onClick={handleEditSubPrice}
                >
                  <BorderColorIcon />
                </IconButton>
                {["admin", "finance", "finance-manager", "dev"].includes(
                  auth?.user?.role?.toLowerCase()
                ) && (
                  <IconButton
                    aria-label="fingerprint"
                    color="error"
                    size="small"
                    onClick={handleClickDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            )}
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title={"Edit Pricelist"}
        open={editSubPrice}
        onCancel={handleCancelSubPrice}
        footer={null}
        width={700}
      >
        <AddItem dataToUpdate={props?.data} onCancel={handleCancelSubPrice} />
      </Modal>

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
      <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        className="min-w-max"
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default SubProductCardGrid;
