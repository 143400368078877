import { myLayoutActions } from ".";

export const toggleSideNavOpenClose = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsSideNavOpen(!isOpen));
  };
};
export const viewUserDetails = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewUser(isOpen));
    dispatch(myLayoutActions.setUserInfo(data));
  };
};
export const viewChannelDetails = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewChannel(isOpen));
    dispatch(myLayoutActions.setChannelInfo(data));
  };
};
export const productHistoryInformation = (isOpen: boolean, data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setViewProductHistory(isOpen));
    dispatch(myLayoutActions.setProductHistoryInfo(data));
    console.log("preeeeeee", data);
  };
};

export const setActiveMenuAction = (currentMenu: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedMenu(currentMenu));
  };
};
export const setRectirectPathAction = (currentPath: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setRedirectPath(currentPath));
  };
};
export const setPrevPathAction = (prevtPath: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setPrevRoute(prevtPath));
  };
};

export const handleSelectedChannelAction = (channel: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedChannel(channel));
  };
};

export const handleSelectedShop = (shop: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedShop(shop));
  };
};

export const activeSelectedTheme = (data: any) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setSelectedTheme(data));
  };
};

export const openCSVButton = (isOpen: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setOpenCSVButton(isOpen));
  };
};

export const showFranchiseeDash = (isShown: boolean) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setIsFranchiseeDash(isShown));
  };
};

export const handlerSelectTabs = (tab: number) => {
  return async (dispatch: any) => {
    dispatch(myLayoutActions.setActiveTab(tab));
  };
};
