import { Badge, Carousel } from "antd";
import React from "react";

interface cardPorps{
    bargeoneName?:any,
    bargeTwoName?:any,
    component:any,
    onClick?:any,
}
const BargeCard=(({bargeoneName,bargeTwoName,component,onClick}:cardPorps)=>{
  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)"
    },
    shadow: {
      boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
    }
  };
    return(
        <div className="w-[388px] pl-4" onClick={onClick} style={styles.shadow}>
           <Badge.Ribbon
           color="#E1F7DE"
           className="h-7 pt-1 w-36 text-center"
            text=<span className="text-[#3AC722] text-sm">{bargeoneName}</span>>
            <Badge.Ribbon
              color="#EDEDFE"
              className="h-7 pt-1 mt-10 w-36 text-center"
              text=<span className="text-[#605BFF] text-[12px]">
            {bargeTwoName}
              </span>
              >
              <div className="border border-[#03022940] rounded-lg p-3 pl-5 mt-2 ">
                {component}
              </div>
            </Badge.Ribbon>
          </Badge.Ribbon>
        </div>
    )
})

export default BargeCard