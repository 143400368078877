import { useSelector } from "react-redux";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";

const VaultNotes = (props: any) => {
  const { vault } = useSelector((state: any) => state);
  const allNotes = vault?.notes?.data;

  return (
    <>
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={vault?.notes?.total}
        count={Math.ceil(vault?.notes?.total / props?.limit)}
      >
          <ul className="ml-6">
            {allNotes?.map((el: any, index: any) => {
              const titleWords = el?.title?.split(" ");

              return (
                <li className="flex" key={index}>
                  <div className="mt-3 h-[auto]">
                    <div className="h-3 w-3 bg-[#0f0f4716] border-[0.1px] border-[#0f0f4746] rounded-full"></div>
                    {index !== allNotes?.length - 1 && (
                      <hr
                        className="border-[0.1px] border-[#0f0f4711] mt-[0.6px] ml-[.3rem] mr-3 h-full cursor-pointer  duration-500"
                      />
                    )}
                  </div>
                  <div className={`mb-3 px-6 py-2 w-full h-full rounded-md`}>
                    <div className="w-[90%]">
                      <div className="mb-2 font-normal">
                        {titleWords.includes("Active") || titleWords.includes("Locked") ||titleWords.includes("Released")||titleWords.includes("Idle")||titleWords.includes("Ready For Use") ? (
                          <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                            {titleWords.map((word:any, i:any) => (
                              <span
                                key={i}
                                className={`${
                                  word === "Active" 
                                    ? "text-[#0FA91E] border border-[#0FA91E]"
                                    : word === "Locked"
                                    ? "text-[#FA3659] border border-[#FA3659]"
                                    : word === "Released"
                                    ? "text-[#2943D6] border border-[#2943D6]": word === "Idle"
                                    ? "text-[#605BFF] border border-[#605BFF]":word === "Ready For Use"
                                    ? "text-[#F19F1D80] border border-[#F19F1D80]":""
                                } rounded-full px-[2px] font-normal text-sm`}
                              >
                                {word}
                              </span>
                            ))}
                          </p>
                        ) : (
                          <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                            {el?.title}
                          </p>
                        )}

                        <p className="text-[#0f0f475e] text-[12px] pt-2">
                          {el?.createdAt?.slice(0, 10)}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-[#0F0F47B2] flex gap-2 font-medium text-sm">
                          {el?.details}
                        </p>
                        <p className="text-[#0f0f475e] text-[12px] pt-2">
                          {el?.createdAt?.slice(11, 19)}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
      </ScrollableFrame>
    </>
  );
};

export default VaultNotes;
