import { notification } from "antd";
import { myVaultActions } from ".";
import { authActions } from "../auth";
import store from "store";
import {
  AddDeviceService,
  getDeviceStatus,
  lockDeviceService,
  unLockDeviceService,
  getDeviceDetailsService,
  sendMessageService,
  getAllDevicesService,
  getDeviceStatusService,
  getOverviewCountService,
  getNotesServices,
  getDeviceServiceStatus,
  getMessageTamplateService,
} from "./services";

export const addDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await AddDeviceService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myVaultActions.setNew(res));
        // dispatch(myVaultActions.setAll(resAll));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const lockDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await lockDeviceService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({
          message: "Successfully",
        });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const unLockDeviceAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await unLockDeviceService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myVaultActions.setUnLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({
          message: "Successfully",
        });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const sendMessageAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await sendMessageService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setUnLock(res));
        dispatch(myVaultActions.setIsFetching(false));
        notification.success({ message: "Success" });
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllDevicesAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getAllDevicesService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setAll(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDeviceDetailsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceDetailsService(data, token);
      // const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myVaultActions.setSelected(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDevicesStatusAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceStatusService(token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setStatusCount(res?.data));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOverviewCountAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getOverviewCountService(token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setOverViewCount(res?.data));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDeviceStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceStatus(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setDeviceStatus(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDeviceServiceStatusAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getDeviceServiceStatus(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setDeviceServiceStatus(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getMessageTamplateAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getMessageTamplateService(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setMessageTamplate(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getnotesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myVaultActions.setIsFetching(true));
      const res = await getNotesServices(token, query);
      // console.log(">>>>>>>All suppliers:: ", res);
      if (res?.status === 200) {
        dispatch(myVaultActions.setNotes(res));
        dispatch(myVaultActions.setIsFetching(false));
      }
      dispatch(myVaultActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};