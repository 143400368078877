import React from "react";
import { employees, Employee } from "./emmployee";
import { Table, Tag } from "antd";
import { ManOutlined, ToolOutlined, UserOutlined, WomanOutlined } from "@ant-design/icons";
import { PieChart } from '@mui/x-charts/PieChart';
import actionIcon from './Group 73.png';
import ArrowIcon from "./Vector.svg";
import { Input, Space } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import DepartmentFiltering from "./DepartmentFiltering";
const { Search } = Input;


const stats = [
  { label: 'Active', value: 360, change: <img src={ArrowIcon} alt="Action Icon" className="xs" />, text: "Less than last month", icon: <UserOutlined />,  color: 'text-green-500 bg-[#97CE71] text-xs '  },
  { label: 'Inactive', value: 30, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <UserOutlined />, color: 'text-[#605BFF] bg-violet-300 text-xs '},
  { label: 'Male', value: 112, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <ManOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs '  },
  { label: 'Female', value: 60, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <WomanOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs' },
  { label: 'Permanent', value: 60, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Less than last month", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs '},
  { label: 'Contract', value: 100, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <UserOutlined />,  color: 'text-green-500 bg-[#97CE71] text-xs ' },
  { label: 'Interns', value: 2, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <UserOutlined />, color: 'text-green-500 bg-[#97CE71] text-xs ' },
  { label: 'With Equipments & Tools', value: 72, change: <img src={ArrowIcon} alt="Action Icon" className="cursor-pointer" />, text: "Increase than last month", icon: <ToolOutlined />,  color: 'text-green-500 bg-[#97CE71] text-xs '  }
];


const data = [
  { name: 'Finance', value: 7, color: '#6C5DD3' },
  { name: 'Dev Team', value: 10, color: '#807CFF' },
  { name: 'Sales', value: 30, color: '#A09DFF' },
  { name: 'Agents', value: 100, color: '#BFBDFF' },
  { name: 'Inventory', value: 5, color: '#DFDEFF' }
];


const Department: React.FC = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Employment Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Starting Date',
      dataIndex: 'startingDate',
      key: 'startingDate',
    },
    {
      title: 'Equipments & Tools',
      dataIndex: 'equipment',
      key: 'equipment',
    },
    {
      title: 'Status',
      key: 'active',
      render: (_: any, record: Employee) => (
        <Tag color={record.active ? 'green' : 'volcano'} className="rounded-md">
          {record.active ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Employee) => (
        <img src={actionIcon} alt="Action Icon" className="cursor-pointer" />
      ),
    },
  ];

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

  const colors = ['#3930A8', '#807CFF', '#78CFFF', '#BBD9FC', '#030229'];
  const size = {
    width: 400,
    height: 200,
  };

  return (
    <div className="p-4">
           <div className="flex flex-col md:flex-row items-stretch mb-4 gap-5">
        <div className="bg-white p-4 w-[100%] md:w-[30%] h-[292px] rounded-md">
          <h2 className="text-lg font-bold mb-4 text-black">Departments</h2>
          <div className="flex flex-row items-center justify-between">
            <div className="w-[40%] text-black">
              <PieChart series={[{ data, innerRadius: 65 }]} colors={colors} {...size}>
              </PieChart>
            </div>
            <div className="text-center mt-6 align-center">
              <ul className="list-none p-0 flex flex-col items-start">
                {data.map((entry, index) => (
                  <li key={index} className="flex items-center justify-start mb-2 text-gray-700">
                    <span
                      className="inline-block w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: entry.color }}
                    ></span>
                    {entry.name} ({entry.value})
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <div key={index} className="h-[134px] w-[100%] md:w-[250px] bg-white rounded-md">
              <div className="flex flex-col items-center justify-between h-full py-2 px-3">
                <>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className="text-xl font-poppins text-black">{stat.value}</div>
                    <div className={`rounded-full p-1 ${stat.color}`}>
                      {stat.icon}
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className="text-md font-poppins text-gray-500">{stat.label}</div>
                    <div></div>
                  </div>
                  <div className="flex flex-row items-center justify-between  w-full ">
                    <div className={`rounded-full px-2 py-2.5 ${stat.color}`}>
                      {stat.change}
                    </div>
                    <div className="text-gray-300 text-sm">{stat.text}</div>
                  </div>
                </>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white rounded-md">
        <div className="flex flex-col md:flex-row  items-center justify-between pb-3 px-3">
          <h2 className="text-black text-lg">Employees</h2>
          <div className="flex flex-row items-center">
            <Search placeholder="input search text" onSearch={onSearch} enterButton />
            <DepartmentFiltering />
          </div>
        </div>
        <Table columns={columns} dataSource={employees} rowKey="name" />
      </div>
    </div>
  );
};

export default Department;



