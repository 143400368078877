import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import VaultButton from "../buttons/vaultButton";
import "../../pages/dashboard/vaults/vault.css";
import { addDeviceAction } from "../../store/vault/actions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Label } from "recharts";
interface formProps {
  inputName?: any;
  selectName?: any;
  placeholder?: any;
  selectPlaceholder?: any;
}
const { Option } = Select;
const AddDevices = ({
  inputName,
  selectName,
  placeholder,
  selectPlaceholder
}: formProps) => {
  const [form] = Form.useForm();
  const { auth, vault } = useSelector((state: any) => state);
  const [selectService, setSelectService] = useState<number>()
  const [selectedMethod, setselectedMethod] = useState({})
  const dispatch = useDispatch();
  const addDevice = async (values: any) => {
    console.log("this is values", values)
    auth?.token &&
      (
        addDeviceAction(auth?.token, {
          deviceList: [
            {
              deviceType: "mobile",
              idType: "imei",
              serviceList:selectedMethod,
              deviceUid: values.deviceUid
            }
          ],
        })
      )(dispatch);
    // await getDeviceAction(auth?.token, "?")(dispatch);
    form.resetFields();
  };

  const options = [{ key: 1, serviceName: "prepaid", paymentMethod: "prepaid",value3:"Idle" },{ key: 2, serviceName: "DeviceFinancing", paymentMethod: "Prepaid" ,value3:"Ready For Use"}, { key: 3, serviceName: "DeviceFinancing", paymentMethod: "postpaid",value3:"Ready for Use"}]

  useEffect(() => {
    if (selectService) {
      setselectedMethod(options?.filter((el) => el?.key === selectService)?.map((d) =>
      d
    ))
    }
  }, [selectService])

  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        style={{ maxWidth: "100%", marginTop: "20px" }}
        onFinish={addDevice}
      >
        <Form.Item
          name="deviceUid"
          label={
            <span className="text-[#030229] pl-1 text-sm font-normal">
              Enter Device IMEI
            </span>
          }
          rules={[{ required: true, message: "Please enter device Id" }]}
          className="w-full"
        >
          <Input
            className="pl-4 h-[42px] w-[100%] text-base"
            placeholder="00000000"
          />
        </Form.Item>
        <Form.Item
          name="paymentMethod"
          label={
            <span className="text-[#030229b2] pl-1 pt-4 text-sm">
              Assign to service
            </span>
          }
          rules={[{ required: true, message: "Please select payment methord" }]}
          className="w-full h-[52px] vault-select"
        >
          <Select
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder="Inventory"
            className=" capitalize rounded-md h-[52px] w-[100%] vault-select  lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bcg-[#EFF0F6] border-none"
            onChange={(value: any) => setSelectService(value)}
          >
            {options?.map((el: any) => (
              <option value={el?.key}>
                {" "}
                <span>
                  {el?.serviceName}({el?.paymentMethod} )
                  <p className="text-[#030229b2] text-[12px] font-thin">
                    {el?.paymentMethod} &gt;  {el?.value3}
                  </p>{" "}
                </span>{" "}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="mt-10">
          <VaultButton btnName={vault?.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : "Save"} />
        </Form.Item>
      </Form>
    </>
  );
};
export default AddDevices;
