import React, { useState } from "react";
import SettingButton from "../../../pages/dashboard/setting/SettingButton";
import { BiPlus } from "react-icons/bi";
import { ReactComponent as ImportIcons } from "../assets/images/icons/vscode-icons_file-type-excel2.svg";
import EbmButton from "../components/EbmButton";
import EbmCard from "../components/EbmCard";
import { Select, Input } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import ManageItemTable from "../components/tables/ManageItemTable";
import GenearalModal from "../components/modals/GeneralModal";
import CustomButton from "../components/buttons/CustomButton";
import { MdOutlineFilterList } from "react-icons/md";
import BargeCard from "../components/cards/BargeCard";
import ItemCardInfo from "../components/ItemCardInfo";
import AddItemForm from "../components/forms/AddItemForm";
import GeneralDrawer from "../components/GeneralDrawer";
import ItemDetails from "../components/ItemDetails";
const ItemManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [typeValue, setTypeValue] = useState<any>(null);
  const [itemForm,setItemForm]=useState(false);
  const handleShoFilter = () => {
    setShowFilter(true);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showItemForm=(()=>{
    setItemForm(true)
  })

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const type = [
    {
      value: "1",
      label: "MIFI Router"
    },
    {
      value: "2",
      label: "fixed phone"
    },
    {
      value: "3",
      label: "Feature Phone"
    },
    {
      value: "4",
      label: "4G Fixed Wireless Phone"
    },
    {
      value: "5",
      label: "Smartphone"
    }
  ];
  const modal = [
    {
      value: "1",
      label: "MIFI Router"
    },
    {
      value: "2",
      label: "fixed phone"
    },
    {
      value: "3",
      label: "Feature Phone"
    },
    {
      value: "4",
      label: "4G Fixed Wireless Phone"
    },
    {
      value: "5",
      label: "Smartphone"
    }
  ];
  const { Search } = Input;
  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);
  console.log("typeValue", typeValue);
  return (
    <div>
    <GeneralDrawer openDrawer={openDrawer} closeDrawer={onClose} width={550} title={<h1 className="text-base font-medium text-[#030229]">Item Details</h1>} component={<div>
      <ItemDetails/>
    </div>}/>
      <div className="flex justify-between">
        <h1 className="text-[#030229] text-lg">Item Management</h1>
        <div className="flex gap-5">
          <EbmButton
            btnName={"Import"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <EbmButton
            btnName={"Export"}
            icon={<ImportIcons />}
            btnBgColor="white"
          />
          <SettingButton
            btnName={"New"}
            icon={<BiPlus color="white" size={20} />}
            btnBgColor="[#605BFF]"
            textColor="white"
            onClick={showModal}
          />
        </div>
      </div>
      <div className="flex gap-5 mt-5">
        <EbmCard title={"Total Items"} total={"452"} />
        <EbmCard title={"Total Purchase Price"} total={"RWF 2000,000"} />
        <EbmCard title={"Total Sale Price"} total={"RWF 3000,000"} />
      </div>
      <div className="bg-white p-3 rounded-md mt-5">
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-base ">
            Item Code, Barcode, Item Name
          </h1>
          <div className="flex gap-5">
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="User"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={[
                {
                  value: "1",
                  label: "User 1"
                },
                {
                  value: "2",
                  label: "User 2"
                }
              ]}
            />
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              style={{ width: "200px" }}
            />
          </div>
          <GenearalModal
            openModal={isModalOpen}
            closeModal={handleOk}
            title={<h1 className="text-[#030229] font-semibold text-lg text-center py-2">
            Add New Item
            </h1>}
            component={
              <div className="px-4 h-[70vh] w-full">
                <div className="flex gap-6 px-5">
                  <CustomButton
                    title={"Filter"}
                    hoverText="[#605BFF]"
                    icon={
                      <MdOutlineFilterList
                        size={20}
                        className="hover:text-[#605BFF]"
                      />
                    }
                    hoverbgColor="[#605BFF1A]"
                    onClick={handleShoFilter}
                  />
                  {showFilter && (
                    <Select
                      showSearch
                      style={{ width: 300, height: 40 }}
                      placeholder="Filter By Type"
                      optionFilterProp="children"
                      onChange={(value) => (
                        setTypeValue(value.label),
                        console.log("typeValue filter", typeValue)
                      )}
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => (
                        console.log("text filter", optionA),
                        setTypeValue(optionA?.label),
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      )}
                      options={type}
                    />
                  )}
                  {showFilter && (
                    <Select
                      showSearch
                      style={{ width: 300, height: 40 }}
                      placeholder="Filter By Type"
                      optionFilterProp="children"
                      onChange={(value) => (
                        setTypeValue(value.label),
                        console.log("typeValue filter", typeValue)
                      )}
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => (
                        console.log("text filter", optionA),
                        setTypeValue(optionA?.label),
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      )}
                      options={type}
                    />
                  )}
                  {showFilter && (
                    <Select
                      showSearch
                      style={{ width: 300, height: 40 }}
                      placeholder="Filter By Model"
                      optionFilterProp="children"
                      onChange={(value) => (
                        setTypeValue(value.label),
                        console.log("typeValue filter", typeValue)
                      )}
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) => (
                        console.log("text filter", optionA),
                        setTypeValue(optionA?.label),
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      )}
                      options={type}
                    />
                  )}
                  {/* <Select
            placeholder="Filter by Region"
            allowClear
            style={{ width: 150 }}
            onChange={(value) => {
              if (value === undefined) {
                handleSelectShopRegion("");
              } else {
                handleSelectShopRegion(value);
              }
            }}
          >
           {type.map((d: any, index: any) => (
                <Option>
                  {d}
                </Option>
              ))}
          </Select> */}
                </div>
                <div className="mt-10 w-full">
                  {!itemForm ? 
                  <BargeCard component={<ItemCardInfo />} bargeTwoName={"+ Ext-Warranty 10%"} bargeoneName={"1,700,000 RWF"} onClick={showItemForm}/>:
                  <AddItemForm/>}

                </div>
              </div>
            }
            width={1200}
          />
        </div>
        <div className="mt-5">
          <ManageItemTable open={showDrawer}/>
        </div>
      </div>
    </div>
  );
};

export default ItemManagement;
