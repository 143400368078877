import React, { useEffect, useState } from "react";
import { Stack, Skeleton, Box } from "@mui/material";
import type { TableColumnsType } from "antd";
import { Card, Row, Table, Tag, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrdersStatisticsAction,
  getAllPurchaseOverviewAction,
  getRecentPurchaseOrdersAction,
} from "../../store/purchase/actions";
import Cart from "../../assets/cart.png";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { searchValue } from "../../utils/setColor";
import { LoadingButton } from "@mui/lab";
import ScrollableFrame from "../../components/layout/ScrollableFrame";
import { formatDateToYYYYMMDD } from "../../utils/converter";

const PageView = (props: any) => {
  const { purchase, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [selecteStataus, setSelecteStataus] = useState("");
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    auth?.token &&
      getRecentPurchaseOrdersAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          selecteStataus !== "" ? `&status=${selecteStataus}` : ""
        }&startingDate=${startDate}&endingDate=${endDate}`
      )(dispatch);
  }, [auth?.token, dispatch, endDate, limit, page, startDate, selecteStataus]);

  useEffect(() => {
    if (auth?.token) {
      getAllPurchaseOverviewAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    auth?.token &&
      getAllOrdersStatisticsAction(
        auth?.token,
        `?startingDate=${startDate}&endingDate=${endDate}`
      )(dispatch);
  }, [auth?.token, startDate, endDate, dispatch]);

  const columns: TableColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "RFQ",
      dataIndex: "po",
      key: "po",
    },
    {
      title: "Delivered On",
      dataIndex: "confirmationDate",
      key: "confirmationDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (text: any) => (
        <Tag color={searchValue(text)} className="capitalize py-0.5 px-2">
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <>
          {record?.status?.toLowerCase() === "replied" && (
            <LoadingButton
              variant="outlined"
              style={{ width: 100 }}
              className="flex justify-center items-center bg-[#605BFF1A] text-[#0000FFB2] rounded-sm w-20"
              onClick={() => navigate(`po/${record?.po}`)}
            >
              Create P.O
            </LoadingButton>
          )}
        </>
      ),
    },
  ];

  const data: any[] = [
    {
      name: "January",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "February",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "March",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "April",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "June",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "July",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "August",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "September",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "October",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "November",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "December",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const newData = purchase?.recent?.data?.map((d: any) => {
    return {
      po: d?._id,
      confirmationDate: d?.confirmationDate,
      numberofsuppliers: d?.statuses[0]?.count,
      status: d?.statuses[0]?.status,
      date: d?.updatedAt?.slice(0, 10),
      action: "",
    };
  });

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setSelectedDateRange(selection);

    setStartDate(formatDateToYYYYMMDD(new Date(selection.startDate)));
    setEndDate(formatDateToYYYYMMDD(new Date(selection.endDate)));
  };
  const handleSelectedStatus = (value: any) => {
    setSelecteStataus(value);
  };

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-screen">
      <Stack spacing={3}>
        {purchase?.overViewIsFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 4 }).map(() => (
              <Box sx={{ width: 330 }}>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </Box>
            ))}
          </div>
        )}
        {!purchase?.overViewIsFetching && (
          <div
            className="justify-center flex flex-wrap text-white gap-4 w-[95%]"
            style={{ marginLeft: 10 }}
          >
            <Card bordered={false} style={{ width: 335, height: 150 }}>
              <div className="flex flex-row space-x-10">
                <div>
                  <img src={Cart} alt="" />
                </div>
                <div>
                  <div className="text-xl pb-2">
                    {purchase?.purchaseOverview?.data?.purcahseOrders || 0}
                  </div>
                  <div className="font-poppins text-lg font-normal text-[#030229]">
                    Total Purchase Orders{" "}
                  </div>
                </div>
              </div>
            </Card>
            <Card
              bordered={false}
              style={{ height: 150 }}
              className="flex-auto"
            >
              <div className="flex flex-row space-x-10">
                <div>
                  <img src={Cart} alt="" />
                </div>
                <div>
                  <div className="text-xl pb-2">
                    {purchase?.purchaseOverview?.data?.totalQuotation || 0}
                  </div>
                  <div className="font-poppins text-lg font-normal text-[#030229]">
                    Total Products Received
                  </div>
                </div>
              </div>
            </Card>
            <Card
              bordered={false}
              style={{ height: 150 }}
              className="flex-auto"
            >
              <div className="flex flex-row space-x-10">
                <div>
                  <img src={Cart} alt="" />
                </div>
                <div>
                  <div className="text-xl pb-2">
                    {purchase?.purchaseOverview?.data?.totalProductss || 0}
                  </div>
                  <div className="font-poppins text-lg font-normal text-[#030229]">
                    RFQs Received{" "}
                  </div>
                </div>
              </div>
            </Card>
            <Card style={{ height: 150 }} className="flex-auto">
              <div className="flex flex-row space-x-10">
                <div>
                  <img src={Cart} alt="" />
                </div>
                <div>
                  <div className="text-xl pb-2">
                    {purchase?.purchaseOverview?.data?.suppliers || 0}
                  </div>
                  <div className="font-poppins text-lg font-normal text-[#030229]">
                    Active Suppliers{" "}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
        <div className="w-full  p-3 rounded-lg flex flex-col md:flex-row gap-4">
          <Card bordered={false} className="w-[100%] md:w-[65%] pt-3 px-4">
            <div className="flex flex-row items-center justify-between">
              <div className="text-poppins text-lg font-normal text-[#030229]">
                Orders statistics
              </div>
              <div className="flex flex-row items-center gap-4">
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#80D1DA] mr-1 rounded-full"></div>
                  <div>Purchase Orders</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#6047ED] mr-1 rounded-full"></div>
                  <div>Product Received</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#3498DB] mr-1 rounded-full"></div>
                  <div>RFQs Received</div>
                </div>
              </div>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={data}
                  margin={{
                    top: 6,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="33" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="totalQuantity"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="totalQuotation"
                    stroke="#82ca9d"
                  />
                  <Line
                    type="monotone"
                    dataKey="upurchaseOrders"
                    stroke="#3498DB"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
          <DateRangePicker
            className="w-[80%] md:w-[30%] pb-4"
            onChange={handleSelect}
            showPreview
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[selectedDateRange]}
            direction="vertical"
            locale={enUS}
          />
        </div>
        <div className="w-full  p-3 rounded-lg space-y-4">
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={purchase?.recent?.total}
            count={Math.ceil(purchase?.recent?.total / limit)}
            customSize={500}
          >
            <Row className="w-full gap-4 pb-4">
              <Card bordered={false} className="w-[100%] px-4">
                <div className="text-poppins text-lg font-normal pt-2 pb-4 text-[#030229]">
                  Recent Activities
                </div>
                <div className="flex items-center space-x-2 pb-10">
                  <Radio.Group
                    defaultValue=""
                    buttonStyle="solid"
                    onChange={(e: any) => handleSelectedStatus(e.target.value)}
                  >
                    <Radio.Button value="">ALL</Radio.Button>
                    <Radio.Button value="Replied">Replied</Radio.Button>
                    <Radio.Button value="Sent">Sent</Radio.Button>
                    <Radio.Button value="Completed">Completed</Radio.Button>
                    <Radio.Button value="Cancelled">Cancelled</Radio.Button>
                  </Radio.Group>
                </div>
                <div>
                  <Table
                    columns={columns}
                    pagination={false}
                    dataSource={newData}
                    scroll={{ x: true }}
                    loading={purchase?.isFetching}
                  />
                </div>
              </Card>
            </Row>
          </ScrollableFrame>
        </div>
      </Stack>
    </div>
  );
};
export default PageView;
