import React, { useState } from "react"
import SideBar from "./sideBar"
import VaultOverView from "./vaultOverview"
import AddDevices from "./manage devices/addDevices"
import PrepaidDevices from "./manage devices/prepaidDevices"
import PostPaidDevices from "./manage devices/postPaidDevices"
import IdleDevices from "./manage devices/idleDevices"

const Index=()=>{
    const [activeTabs,setActiveTabs]=useState(`${localStorage.getItem("activeVaultTabs")?localStorage.getItem("activeVaultTabs"):"overview"}`)
    localStorage.setItem("activeVaultTabs", activeTabs)
    return(
        <>
        <div className="vault-container">
            <div className="w-full h-[calc(100%-40px)] overflow-y-auto">
                <div className="flex gap-5">
                    <SideBar activeTabs={activeTabs} setActiveTabs={setActiveTabs}/>
                    <div className="ml-[336px] p-5 mt-2 bg-[#FAFAFB] h-[94vh] w-full overflow-y-auto ">
                      {activeTabs==="overview" && <VaultOverView />}
                      {activeTabs==="addDevice" && <AddDevices/>}  
                      {activeTabs==="prePaidDevices" &&  <PrepaidDevices/>}
                      {activeTabs==="postPaidDevices" &&  <PostPaidDevices/> }
                       {activeTabs==="idleDevices" && <IdleDevices/>}
                    </div>

                </div>

            </div>
        </div>
        
        </>
    )
}

export default Index