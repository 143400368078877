import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Table,
  notification,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
// import PhoneInput from "react-phone-input-2";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import AddItem from "./AddItems";
import { createOrderAction } from "../../../../store/account/actions";
import { getPricelistItemAction } from "../../../../store/channel/actions";
import {
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
  submitSubOrdersAction,
} from "../../../../store/subscription/order/actions";
import { Stack } from "@mui/material";
import { myChannelActions } from "../../../../store/channel";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

interface FormData {
  name: string;
  url: string;
  owner: string;
  type: string;
  approver: string;
  // dateTime: Moment | null;
  description: string;
}

const CreateOrder = (props: any) => {
  const [form] = Form.useForm<FormData>();
  const dispatch = useDispatch();
  const { orders, auth, account, order } = useSelector((state: any) => state);
  const dataMapper: any[] = [];
  const [formData, setformData] = React.useState(dataMapper);
  const [contactsAdded, setContactsAdded] = useState(false);
  const [channelId, setChannelId] = useState("");

  const orderId = orders?.new?.data?._id ?? orders?.selected?._id;

  const onFinishAccount = async (values: any) => {
    // console.log("onfinish", values);
    const payload = {
      contacts: values.contacts && [...values.contacts],
      customerDetails: {
        ...values,
      },
    };

    delete payload?.customerDetails?.contacts;

    if (orderId && auth?.token) {
      await createOrderAction(auth?.token, {
        order: orderId,
        ...payload,
        // contacts: [...values.contacts],
        // customerDetails: {
        //   ...values,
        // },
      })(dispatch);
      form.resetFields();
      await getOneSubsOrderAction(auth?.token, orderId)(dispatch);
    }
  };

  useEffect(() => {
    if (channelId) {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${channelId}&hasSubs=true`
        )(dispatch);
    } else {
      dispatch(myChannelActions.setPriceListItems(null));
    }
  }, [auth?.token, dispatch, channelId]);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const value = orders?.selected?.list;
  useEffect(() => {
    value &&
      value.forEach((el: any) => {
        dataMapper.push({
          type: el?.product?.product?.type,
          brand: el?.product?.product?.brand,
          model: el?.product?.product?.model,
          specification: `${el?.product?.specification
            ?.slice(2, 6)
            ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
            ?.join(", ")}`,
          quantity: el?.quantity,
          data: el,
        });
      });

    setformData(dataMapper);
  }, [orders?.selected]);

  const handleSubmit = async () => {
    if (!orders?.selected?.account?.duration) {
      return notification.error({ message: "Please Select Duration" });
    }

    if (!orders?.selected?.account?.planType) {
      return notification.error({ message: "Please Select Plan Type" });
    }

    if (orderId && auth?.token) {
      const response = await submitSubOrdersAction(
        orderId,
        { status: "created" },
        auth?.token
      )(dispatch);
      if (response) {
        props?.onClose();
        await getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=created&status=pending&status=draft`
        )(dispatch);
      }
    }
  };

  useEffect(() => {
    if (orders?.selected?.account) {
      form.setFieldsValue(orders?.selected?.account?.customerDetails);
    } else {
      form.resetFields();
    }
  }, [form, orders?.selected?.account]);

  const initialData = {
    ...orders?.selected?.account?.customerDetails,
  };

  return (
    <div>
      <Drawer
        onClose={props?.onClose}
        open={props?.open}
        title="Create New Order"
        width={720}
        className="text-[#2943D6]"
        bodyStyle={{ paddingBottom: "80px" }}
        footerStyle={{ textAlign: "right" }}
        footer={
          orders?.selected?.account?.customerDetails !== undefined &&
          orders?.selected?.list?.length > 0 && (
            <>
              <Button
                className="bg-[#605BFF] text-gray-900 outline-none"
                style={{ color: "black" }}
                disabled={orders?.selected?.list?.length < 1}
                onClick={handleSubmit}
                loading={orders?.createOderIsFetching}
              >
                Save
              </Button>
            </>
          )
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishAccount}
          initialValues={initialData || {}}
          disabled={orders?.selected?.account?.customerDetails !== undefined}
        >
          <div>
            <div className="grid grid-cols-2 gap-x-10 pl-18">
              <Form.Item
                label="Customer Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter customer name" },
                ]}
              >
                <Input className="" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="tel"
                rules={[
                  { required: true, message: "Please enter Phone Number" },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Please enter customer Number"
                  className=""
                />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="Please enter Country" className="" />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please enter customer name" },
                ]}
              >
                <Input placeholder="Please enter customer name" className="" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter Email" }]}
              >
                <Input placeholder="Please enter Email" className="" />
              </Form.Item>
            </div>
            {orders?.selected?.account?.contacts === undefined && (
              <Divider orientation="center">Add Contacts</Divider>
            )}
            <Form.List
              name="contacts"
              {...formItemLayout}
              // initialValue={initialContactData ?? [{}]}
            >
              {(
                fields: { [x: string]: any; key: any; name: any }[],
                { add, remove }: any
              ) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Stack>
                      <Stack direction={"row"} className="gap-4">
                        <Form.Item
                          {...restField}
                          label="Names"
                          name={[name, "name"]}
                          rules={[{ required: true, message: "Missing names" }]}
                          style={{ minWidth: 200 }}
                        >
                          <Input placeholder="Names" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Phone Number"
                          name={[name, "phone"]}
                          rules={[
                            {
                              required: true,
                              message: "Phone is required!",
                              whitespace: true,
                            },
                          ]}
                          style={{ minWidth: 200 }}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Email"
                          name={[name, "email"]}
                          rules={[
                            {
                              required: true,
                              message: "email is required!",
                              type: "email",
                            },
                          ]}
                          style={{ minWidth: 200 }}
                        >
                          <Input placeholder="enter your email" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Stack>
                    </Stack>
                  ))}
                  {orders?.selected?.account?.contacts === undefined && (
                    <Form.Item>
                      <Button
                        onClick={() => {
                          add();
                          setContactsAdded(true); // Set contactsAdded to true when "Add Contacts" is clicked
                        }}
                        // startIcon={<PlusOutlined />}
                      >
                        Add Contacts
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </div>
          {orders?.selected?.account?.customerDetails === undefined && (
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: 100 }}
              loading={account?.isFetching || orders?.isFetching}
            >
              Next
            </LoadingButton>
          )}
        </Form>

        {orders?.selected?.account?.customerDetails !== undefined && (
          <>
            {/* <div className="mb-4">
              <ContactItems orderId={orderId} />
            </div> */}
            <div className="mb-4">
              <AddItem
                orderId={orderId}
                channelId={channelId}
                setChannelId={setChannelId}
              />
            </div>
            <Table
              columns={columns}
              dataSource={formData}
              loading={orders?.isFetching}
            />
          </>
        )}
      </Drawer>
    </div>
  );
};

export default CreateOrder;
