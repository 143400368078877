export const StockRequestHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specificatios", key: "specification" },
  { label: "Quantity Requested", key: "qtyReq" },
  { label: "Quantity Approved", key: "qtyAppreved" },
];

export const PurchaseOrdersHeaders = [
  { label: "Requisition Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Model", key: "items" },
  { label: "Quantity", key: "quantity" },
  { label: "Cost/Unit (RWF)", key: "cost" },
  { label: "Total Cost (RWF)", key: "totalCost" },
  { label: "Delivery Date", key: "deliveryDate" },
];

export const ShopDetailsHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const TransactionsHeaders = [
  { label: "Date", key: "paidOn" },
  // { label: "Paid On", key: "date_time" },
  { label: "Shop", key: "shop_name" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "barCode" },
  { label: "IMEI2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "New SIM Card", key: "newSimCard" },
  { label: "Device Retail Prices (RWF)", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Purchase Cost (RWF)", key: "puchaseCost" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Method Of Payment", key: "methodOfPayment" },
  { label: "Momo Transaction Id", key: "momoTransactionId" },
  { label: "Macye Macye Payment Type", key: "macyemacyeType" },
  { label: "Documents", key: "documents" },
  { label: "RRA ReceiptCode", key: "rraReceiptCode" },
  { label: "Status", key: "status" },
];

export const SaveTheChildrenReportHeaders = [
  { label: "Date", key: "date" },
  { label: "Done by", key: "doneBy" },
  { label: "Customer's Full Name", key: "customerName" },
  { label: "Customer Phone No", key: "customerTel" },
  { label: "Monthly Payment", key: "mPayment" },
  { label: "Total Amount Paid", key: "mPaid" },
  { label: "Amount Remain", key: "mPaidremain" },
  { label: "Status", key: "status" },
];

export const SalesReportHeader = [
  { label: "Date", key: "paidOn" },
  // { label: "Paid On", key: "date_time" },
  { label: "Shop", key: "shop" },
  { label: "District", key: "district" },
  { label: "Region", key: "region" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "IMEI 1", key: "barCode" },
  { label: "IMEI 2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "New SIM Card", key: "newSimCard" },
  { label: "Device Retail Prices (RWF)", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Method of Payment", key: "methodOfPayment" },
  { label: "Macye Macye Payment Type", key: "macyemacyeType" },
  { label: "documents", key: "documents" },
  { label: "Transaction Id", key: "transactionId" },
  { label: "RRA Receipt Code", key: "rraCode" },
  { label: "Status", key: "status" },
];

export const WarehouseHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "In Shop", key: "inShop" },
  { label: "In Warehouse", key: "inWarehouse" },
  { label: "Sold", key: "sold" },
];

export const ProductDetailsHeaders = [
  { label: "Product", key: "product" },
  { label: "Shop", key: "shop" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const AllShopsDataHeaders = [
  { label: "Name", key: "name" },
  { label: "type", key: "type" },
  { label: "Location", key: "address" },
  { label: "Region", key: "region" },
];

export const NosalesHeaders = [
  { label: "Date", key: "date" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Price (RWF)", key: "prices" },
  { label: "Quantity", key: "quantity" },
  { label: "Shop", key: "shop" },
  { label: "Agent", key: "agent" },
  { label: "Customer", key: "customer" },
  { label: "Customer's Phone", key: "customerTel" },
  { label: "Reason", key: "comments" },
];

export const ProductsHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
];

export const ProductInfoStockHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Product", key: "product" },
  { label: "SerialNumber", key: "serialNumber" },
  { label: "Specification", key: "specification" },
  { label: "Station", key: "station" },
  { label: "Status", key: "status" },
];

export const ProductInfoPricesHeaders = [
  { label: "Channel", key: "channel" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Prices (RWF)", key: "prices" },
  { label: "Extended Warranty", key: "exWarranty" },
];

export const ProductInfoSuppliersHeaders = [
  { label: "Model", key: "model" },
  { label: "Supplier Names", key: "supplierName" },
  { label: "TIN", key: "tin" },
  { label: "Telephone", key: "telephone" },
  { label: "Email", key: "email" },
  { label: "Type", key: "type" },
  { label: "Address", key: "location" },
];

export const WarehouseInHeaders = [
  { label: "Date", key: "date" },
  { label: "Model", key: "model" },
  { label: "Quantity", key: "quantity" },
  { label: "Supplier", key: "supplier" },
  { label: "Supplier TIN", key: "supplierTIN" },
  { label: "Supplier Phone", key: "supplierPhone" },
  { label: "Supplier Email", key: "supplierEmail" },
  { label: "Shipping Type", key: "shippingTypeName" },
  { label: "Delivere Name", key: "delivererName" },
  { label: "Delivere Phone", key: "delivererPhone" },
];

export const PriceListHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Capacity", key: "storage" },
  { label: "Memory", key: "memory" },
  { label: "Price", key: "amount" },
];

export const PurchasedHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Quantity Received", key: "quantity" },
  { label: "Unit Price", key: "amount" },
  { label: "Total", key: "total" },
];

export const StockHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "Status", key: "status" },
];

export const ShopReportHeader = [
  { label: "Region", key: "region" },
  { label: "Shop", key: "shop" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "Current Stock", key: "inShop" },
  { label: "Sold", key: "sold" },
];

export const InventoryTransferReportHeader = [
  { label: "Date", key: "date" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "From Shop", key: "fromShop" },
  { label: "To Shop", key: "toShop" },
  { label: "Transfered by (Names)", key: "transferedBy" },
];
