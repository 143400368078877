import React, { useEffect } from "react"
import Card from "./card"
import phone from "../../../assets/images/vault/icons/heroicons-solid_device-mobile.png"
import {ReactComponent as DownIcon} from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg"
import {ReactComponent as TravelIcon} from "../../../assets/icons/dashboard/vaults/streamline_travel-map-navigation-arrow-compass-arrow-map-bearing-navigation-maps-heading-gps.svg"
import {ReactComponent as VectorIcon} from "../../../assets/icons/dashboard/vaults/Vector.svg"
import {ReactComponent as GiftIcon} from "../../../assets/icons/dashboard/vaults/gift.svg"
import {ReactComponent as CaurseUp} from "../../../assets/icons/dashboard/vaults/solar_course-up-bold (1).svg"
import {ReactComponent as UpBoldIcon} from "../../../assets/icons/dashboard/vaults/solar_course-up-bold.svg"
import {ReactComponent as PhoneIcon} from "../../../assets/icons/dashboard/vaults/tabler_activity-heartbeat.svg"
import {ReactComponent as LockIcon} from "../../../assets/icons/dashboard/vaults/lock.svg"
import {ReactComponent as DeviceIcon} from "../../../assets/icons/dashboard/vaults/heroicons-solid_device-mobile.svg"
import VaultFilter from "./vaultFilter"
import { getAllDevicesAction, getDeviceStatusAction, getDevicesStatusAction, getOverviewCountAction } from "../../../store/vault/actions"
import { useDispatch, useSelector } from "react-redux"
import CustomChart from "./customChart"
import CustomPieChart from "./customPieChart"
const VaultOverView=()=>{
    const {auth,vault}=useSelector((state:any)=>state);
    const dispatch=useDispatch();
    useEffect(() => {
        // auth?.token && getDeviceStatusAction(auth.token,"?uploadId=88")(dispatch);
        auth?.token && getAllDevicesAction(auth?.token)(dispatch);
        auth?.token && getDevicesStatusAction(auth.token)(dispatch);
        auth?.token && getOverviewCountAction(auth.token)(dispatch);
       }, [auth.token, dispatch]);
       const styles={
        boxShadow:{
            boxShadow:"1px 4px 24px 0px rgba(3, 2, 41, 0.06)"
        }
    } 
    return(
        <>
        <VaultFilter/>
        <div className="flex gap-5 flex-wrap">
            {/* <div className="grid grid-cols-3 w-[65%] gap-3 h-64"> */}
        <Card name={"Total Devices"} total={vault?.all?.total} customeIcon={<DeviceIcon/>} icon={<CaurseUp/>} percent={"20%"}/>
        <Card name={"Idle"} total={(vault?.overviewCount?.Idle)?vault?.overviewCount?.Idle:0} customeIcon={<VectorIcon/>} percent={"-10%"} icon={ <UpBoldIcon className="mt-[2px]"/>}/>
        <Card name={"Ready for use"} total={(vault?.overviewCount?.ReadyForUse)?vault?.overviewCount?.ReadyForUse:0} customeIcon={<GiftIcon/>} percent={"10%"} icon={ <CaurseUp className="mt-[2px]"/>}/>
        <Card name={"Active"} total={(vault?.overviewCount?.Active)?vault?.overviewCount?.Active:0} customeIcon={<PhoneIcon/>} percent={"-10%"} icon={ <DownIcon className="mt-[2px]"/>}/>
        <Card name={"Locked"} total={(vault?.overviewCount?.locked)?vault?.overviewCount?.locked:0} customeIcon={<LockIcon/>} percent={"-10%"} icon={ <DownIcon className="mt-[2px]"/>}/>
        <Card name={"Released"} total={(vault?.overviewCount?.Released)?vault?.overviewCount?.Released:0} customeIcon={<TravelIcon/>} percent={"-10%"} icon={ <DownIcon className="mt-[2px]"/>}/>
        {/* </div> */}
            </div>
            <div className="absolute top-[27rem] bg-white p-5 rounded-md" style={styles.boxShadow}>
                <div className="flex mb-5 justify-between text-black">
                    <h1 className="text-[#030229]">Statistics</h1>
                    <div className="flex gap-4 text-sm">
                   <div className="flex gap-2"> <div className="w-2 h-2 mt-[6px] rounded-full bg-[#FA3659]"></div> <p className="text-[#FA3659]"> Locked</p></div>
                   <div className="flex gap-2"> <div className="w-2 h-2 mt-[6px] rounded-full bg-[#605BFF80]"></div> <p className="text-[#030229]"> Unlocked</p></div>
                   <div className="flex gap-2"> <div className="w-2 h-2 mt-[6px] rounded-full bg-[#0FA91E80]"></div> <p className="text-[#030229]"> Released</p></div>

                    </div>
                </div>
                <CustomChart/>
            </div>
            <div className="absolute top-[32rem] right-0 px-20 bg-dwhite rounded-md">
                <CustomPieChart/>
            </div>
        </>
    )
}
export default VaultOverView