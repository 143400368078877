import React from "react";
import { Button, Form, Input, Select } from "antd";
import { DatePicker } from "antd";
import type { SearchProps } from "antd/es/input/Search";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomButton from "../buttons/CustomButton";
import { ReactComponent as SaveIcon } from "../../assets/images/icons/save.svg";
import type { DatePickerProps } from "antd";
dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";
const { Search } = Input;
const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
  console.log(info?.source, value);
const layout = {
  labelCol: { span: 6 }
};
const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log(date, dateString);
};
const AddPurchaseForm = () => {
  return (
    <div>
      <Form
        name="wrap"
        {...layout}
        // labelAlign="left"
        colon={false}
        style={{ width: "100%" }}
      >
         <div className="flex justify-between">
          <div className="bg-[#F4F6FA] flex justify-center items-center gap-2"> <h1 className="text-base text-black pl-2">CurrentStatus:</h1>
            <CustomButton title={"Wait for Approval"} textColor="white" bgColor="green-500" textSize="base"/>
          </div>
          <div className="flex gap-5">
          <CustomButton title={"Accept"} textColor="[#0FA91E]" bgColor="[#0FA91E0D]" textSize="base"/>
          <CustomButton title={"Cancel"} textColor="[#F66659]" bgColor="[#F6665980]" textSize="base"/>
          </div>
        </div>
        <div className="flex  w-full pt-5">
          <div className="w-[40%] ">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Purchase Type:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Invoice ID:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10  w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Supplier ID:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Supplier Name:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Purchase Date:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Reference ID:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Total Amount:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                VAT:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Remark:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[14rem]" />
            </Form.Item>
          </div>

          <div className="w-[60%]">
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Item Code:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[25rem]" />
            </Form.Item>
            <Form.Item
              label=<span className="text-[12px] text-[#030229] pt-2">
                Class Code:
              </span>
              name="name"
              rules={[{ required: true }]}
            >
              <Input className="h-10 w-[25rem]" />
            </Form.Item>
            <div>
              <div className="flex w-full">
                <div className="w-1/2">
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Unit Price:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Tax Type:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Select
                      className="h-10 w-[10rem] ml-10"
                      showSearch
                      style={{ width: 160 }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "Not Identified"
                        },
                        {
                          value: "2",
                          label: "Closed"
                        }
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      D/C Rate(%):
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2">
                      Puurchase Price:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                </div>
                <div className="w-1/2">
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 pr-10">
                      Expiration Date:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      onChange={onChange}
                      className="h-10 w-[10rem] ml-10"
                    />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 pr-10">
                      Purchase Qty:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 pr-10">
                      VAT:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 pr-10">
                      D/C Amount:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                  <Form.Item
                    label=<span className="text-[12px] text-[#030229] pt-2 pr-10">
                      Total Price:
                    </span>
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input className="h-10 w-[10rem] ml-10" />
                  </Form.Item>
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="flex gap-5">
                  <CustomButton
                    title={"Clear"}
                    textColor="red-500"
                    bgColor="red-100"
                  />
                  <CustomButton
                    title={"Confirm"}
                    textColor="white"
                    bgColor="[#605BFF]"
                  />
                </div>
                <CustomButton
                  title={"Remove"}
                  textColor="[#030229B2]"
                  bgColor="[#0302291A]"
                />
              </div>
            </div>
          </div>
        </div>

        <Form.Item label=" " className="w-1/2 m-auto mt-5">
          <CustomButton
            title={"Save"}
            bgColor="[#605BFF]"
            width="full"
            textColor="white"
            icon={<SaveIcon />}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddPurchaseForm;
