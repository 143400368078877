import React, { useEffect, useState } from "react";
import Statistics from "./Statistics";
import SavetheChildrentable from "../../../components/tables/saveTheChildrenTable/SavetheChildrentable";
import { DatePicker, DatePickerProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllSaveTheChildrenAction } from "../../../store/report/actions";
import { SaveTheChildrenReportHeaders } from "../../../components/csvHeaders/Headers";
import dayjs from "dayjs";
import FilesCard from "../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { VscFilePdf } from "react-icons/vsc";
import { getAllSaveTheChildrenReport_CSVAction } from "../../../store/csvDownload/actions";
const OverView = () => {
  const { auth, report, layout, CSV } = useSelector((state: any) => state);
  const formattedDate = new Date().toISOString().slice(0, 7);
  const [selectedMonth, setSelectedMonth] = useState<string | string[]>(
    formattedDate
  );
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const channelId = auth?.user?.channel?.at(0)?._id;

  const dispatch = useDispatch();
  useEffect(() => {
    if (auth?.token) {
      getAllSaveTheChildrenAction(
        auth?.token,
        `?month=${selectedMonth}&status=${selectedStatus}&limit=${limit}&page=${
          page - 1
        }${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
    }
  }, [
    auth?.token,
    dispatch,
    selectedMonth,
    selectedStatus,
    channelId,
    limit,
    page,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllSaveTheChildrenReport_CSVAction(
        auth?.token,
        `?month=${selectedMonth}&status=${selectedStatus}&limit=${
          selectedStatus === "pending"
            ? report?.saveTheChildren?.data?.counts?.pending
            : selectedStatus === "paid"
            ? report?.saveTheChildren?.data?.counts?.paid
            : report?.saveTheChildren?.data?.counts?.late
        }&page=0${channelId ? `&channel=${channelId}` : ""}`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedMonth(dateString);
  };

  const csvData = CSV?.csvDownloadedData?.data?.list?.map((item: any) => {
    return {
      date: item?.dueDate?.slice(0, 10),
      customerName: item?.account?.customerDetails?.name,
      customerTel: item?.account?.customerDetails?.tel,
      doneBy: item?.createdBy?.names,
      mPayment: item?.amountToBePaid,
      mPaid: item?.amountPaid,
      mPaidremain: item?.amountToBePaid - item?.amountPaid,
      status: item?.status,
    };
  });

  return (
    <div className="mt-8 px-4">
      <div className="flex items-center justify-between mb-2.5 text-black">
        <DatePicker
          onChange={onChange}
          picker="month"
          defaultValue={dayjs(`${formattedDate}`, "YYYY-MM")}
        />
        <div className="flex justify-end gap-4 ">
          <FilesCard
            Icon={VscFilePdf}
            tailwindTextColor="text-white"
            tailwindBackgroundColor="bg-orange-400"
            disabled
          />
          <CSVExportButton
            csvHeaders={SaveTheChildrenReportHeaders}
            csvData={csvData}
            filename={`Save the children Report.csv`}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-10">
        <Statistics />
        <div className="max-h-full overflow-y-auto">
          <SavetheChildrentable
            setSelectedStatus={setSelectedStatus}
            limit={limit}
            selectedStatus={selectedStatus}
            setPage={setPage}
            setLimit={setLimit}
          />
        </div>
      </div>
    </div>
  );
};

export default OverView;
