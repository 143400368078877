import React, { useEffect, useState } from "react";
import { LinearProgress, Stack, styled } from "@mui/material";
import MetricsCard from "../../../components/cards/purchase/MetricsCard";
import type { DatePickerProps, TableColumnsType } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../../store/purchase/actions";
import moment from "moment";
import {
  getPiecesSoldStatsActions,
  getProductsStatsActions,
} from "../../../store/dashboard/actions";
import { calculatePercentageChange } from "../../../utils/converter";
import { Calendar, Card, Checkbox, Col, Row, Table } from 'antd';
import Wifi from "../../../assets/Icon.png";
import Galaxy from "../../../assets/Galaxy.png";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getStockRequestOverViewActions, getTopSellingProductActions } from "../../../store/inventory/actions";
import { DateRangePicker } from "react-date-range";
import { DatePicker } from 'antd';
import { enUS } from 'date-fns/locale';




type StatusItem = {
  day: string,
  in: number,
  out: number,
  stockRequest: string
};



const PageView = (props: any) => {
  const { auth, dashboard, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const { RangePicker } = DatePicker;

  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);

  const startDates = new Date(selectedDateRange?.startDate).toISOString().split("T")[0]
  const endDates = new Date(selectedDateRange?.endDate).toISOString().split("T")[0]


  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };


  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && enteredDate) {
      getProductsStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&selectBy=model`
      )(dispatch);
      getPiecesSoldStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&selectBy=model`
      )(dispatch);
    }
  }, [auth?.token, dispatch, enteredDate, compareDate]);

  const totalQtySold = dashboard?.pieceSoldStats?.data?.reduce(
    (total: any, item: any) => total + item?.currentQuantity,
    0
  );

  const totalComparedQtySold = dashboard?.pieceSoldStats?.data?.reduce(
    (total: any, item: any) => total + item?.comparedQuantity,
    0
  );

  const totalStockPerc = calculatePercentageChange(
    dashboard?.productStasts?.data?.totals?.currentDatetotal,
    dashboard?.productStasts?.data?.totals?.comparedDatetotal
  );

  const warehouseInPerc = calculatePercentageChange(
    dashboard?.productStasts?.data?.totals?.currentDateinWarehouse,
    dashboard?.productStasts?.data?.totals?.comparedDateinWarehouse
  );

  const warehouseOutPerc = calculatePercentageChange(
    dashboard?.productStasts?.data?.totals?.currentDateinShop,
    dashboard?.productStasts?.data?.totals?.comparedDateinShop
  );

  const qtySoldPerc = calculatePercentageChange(
    totalQtySold,
    totalComparedQtySold
  );

  function onPanelChange(value: any, mode: any) {
    console.log(value, mode);
  }

  const topSelling = inventory?.topSelling?.data

  const totalTopSellingProduct: any[] = [];
  let soldOut = 0;


  if (Array.isArray(topSelling)) {
    topSelling.forEach((item: any) => {
      const total = item?.status?.in + item?.status?.out + item?.status?.sold;
      const name = item?.name;
      const soldOut = (item?.status?.sold / total) * 100;

      if (name && !isNaN(total)) {
        totalTopSellingProduct.push({ name, total, soldOut });
      }
    });
  }

  useEffect(() => {
    auth?.token && getStockRequestOverViewActions(auth?.token, `?startingDate=${startDates}&endingDate=${endDates}`)(dispatch);
  }, [auth?.token, startDates, endDates, dispatch]);


  useEffect(() => {
    const productBrand = '$product.brand';
    const query = `/?brand=${productBrand}`;
    auth?.token && getTopSellingProductActions(auth?.token, query)(dispatch);
  }, [auth?.token, dispatch]);


  const newData: StatusItem[] = inventory?.stockrequest?.data;

  const columns: TableColumnsType<any> = [
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Activity', dataIndex: 'Activity', key: 'Activity' },
    { title: 'Quantity', dataIndex: 'Quantity', key: 'Quantity' },
    { title: 'Total Amount', dataIndex: 'TotalAmount', key: 'TotalAmount' },
    { title: 'Done By', dataIndex: 'DoneBy', key: 'DoneBy' },
    { title: 'Status', dataIndex: 'Status', key: 'Status' },
    { title: 'Action', dataIndex: 'action', key: 'action' }
  ];

  const data: any[] = [
    {
      name: 'Monday',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Tuesday',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Wednesday',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Thursday',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Friday',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Saturday',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Sunday',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];


  const compareDays = (a: any, b: any) => {
    if (a.day < b.day) return -1;
    if (a.day > b.day) return 1;
    return 0;
  };

  const sortedData = newData?.slice().sort(compareDays);
  console.log(selectedDateRange, "selectedDateRangeselectedDateRangeselectedDateRangeselectedDgeselectedDateRangeselectedDateRange");
  return (
    <div className="text-black py-6">
      <Stack spacing={3}>
        <div className="justify-center flex flex-wrap text-white gap-4 w-[95%]" style={{ marginLeft: 10 }}>
          <Card bordered={false} style={{ height: 150 }} className="flex-auto">
            <div className="flex flex-row justify-between">
              <div><img src={Wifi} /></div>
              <div>
                <div className="text-xl pb-2">
                  {dashboard?.productStasts?.data?.totals?.currentDatetotal || 0} </div>
                <div className="font-poppins text-lg font-normal text-[#030229]">
                  Total Stock
                </div>
              </div>
            </div>
          </Card>
          <Card bordered={false} style={{ height: 150 }} className="flex-auto">
            <div className="flex flex-row justify-between">
              <div><img src={Wifi} /></div>
              <div>
                <div className="text-xl pb-2">
                  {dashboard?.productStasts?.data?.totals?.currentDateinWarehouse || 0}
                </div>
                <div className="font-poppins text-lg font-normal text-[#030229]">
                  Total Warehouse In
                </div>
              </div>
            </div>
          </Card>
          <Card bordered={false} style={{ height: 150 }} className="flex-auto">
            <div className="flex flex-row justify-between">
              <div><img src={Wifi} /></div>
              <div>
                <div className="text-xl pb-2">
                  {dashboard?.productStasts?.data?.totals?.currentDateinShop || 0}
                </div>
                <div className="font-poppins text-lg font-normal text-[#030229]">
                  Total Warehouse Out  </div>
              </div>
            </div>
          </Card>
          <Card style={{ height: 150 }} className="flex-auto">
            <div className="flex flex-row justify-between">
              <div><img src={Wifi} /></div>
              <div>
                <div className="text-xl pb-2">
                  {totalQtySold?.toLocaleString() || 0}
                </div>
                <div className="font-poppins text-lg font-normal text-[#030229]">
                  Total Quantity Sold  </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="w-full  p-3 rounded-lg flex flex-col md:flex-row">
          <Card bordered={false} className="w-[100%] md:w-[65%] pt-3 px-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="text-poppins text-lg font-normal text-[#030229]">Stock Report</div>
              <div className="flex flex-row items-center gap-4">
                <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#80D1DA] mr-1 rounded-full"></div>
                  <div>Warehouse In</div></div>
                <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#6047ED] mr-1 rounded-full"></div><div>Warehouse Out</div></div>
                <div className="flex flex-row items-center gap-1"><div className="h-3 w-3 bg-[#3498DB] mr-1 rounded-full"></div><div>Stock Request</div></div>
              </div>
            </div>
            <div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  width={1000}
                  data={sortedData}
                  margin={{
                    top: 6,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}>
                  <CartesianGrid strokeDasharray="33" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="in" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="out" stroke="#6047ED" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="stockRequest" stroke="#3498DB" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
          <DateRangePicker
            className="w-[100%] md:w-[30%] pb-4"
            onChange={handleSelect}
            showPreview
            moveRangeOnFirstSelection={true}
            months={1}
            ranges={[selectedDateRange]}
            direction="vertical"
            locale={enUS}
          />
        </div>
        <div className="w-full   rounded-lg space-y-4 flex flex-col md:flex-row">
          <Card bordered={false} className="w-[65%] pt-3 px-4">
            <div className="text-poppins text-lg font-normal text-[#030229]"></div>
            <div>
              <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: true }}
                className="w-[100%] md:w-auto"
              />
            </div>
          </Card>
          <Card bordered={false} className="w-[30%] pt-3">
            <div className="flex flex-row items-row justify-between">
              <div className="text-poppins text-md font-normal text-[#030229]">Top-selling Products</div>
              <div className="flex flex-row items-row gap-3">
                <div>
                  <Checkbox>
                    type
                  </Checkbox>
                </div>
                <div><Checkbox>
                  Brands
                </Checkbox></div>
                <div>
                  <Checkbox>
                    Models
                  </Checkbox>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-row justify-between pt-4">
              <div className="w-full">Name</div>
              <div className="w-full">
                Sales
              </div>
            </div>

            {totalTopSellingProduct.map((item: any) => (
              <div className="flex flex-row items-row pt-4 border rounded-md px-4">
                <div className="w-full"><img src={Galaxy} /></div>
                <div className="w-full">
                  {item.name}
                </div>
                <div className="w-full pt-2 px-8">
                  <LinearProgress variant="determinate" value={item?.soldOut.toFixed(0)} />
                </div>
                <div className="w-full text-gray-400 text-md">
                  <span>{item?.soldOut.toFixed(0)}</span> % Sold Out
                </div>
              </div>
            ))}
          </Card>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
