import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, message, Upload } from 'antd';
import { useSelector } from 'react-redux';

const props: UploadProps = {
  name: 'file',
  action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const VaultUploadComponent = () => {
  const {layout}=useSelector((state:any)=>state)
  return(
  <Upload {...props}>
    <Button icon={<UploadOutlined />} className={` ${layout.isSideNavOpen?"w-[36rem]":"w-[40rem]"} h-11`}>Click to Upload</Button>
  </Upload>
);
  }
export default VaultUploadComponent;