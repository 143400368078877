import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { ShopReportHeader } from "../../csvHeaders/Headers";
import { VscFilePdf } from "react-icons/vsc";
import FilesCard from "../../cards/NoSale/FilesCard";
import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import ReportFiltering from "../../filters/ReportFiltering";
import { getAllStockReportAction } from "../../../store/report/actions";
import { getAllStockReport_CSVAction } from "../../../store/csvDownload/actions";
import ScrollableFrame from "../../layout/ScrollableFrame";
interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const StockReportTable = (props: any) => {
  const { auth, CSV, report, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [selectedShop, setSelectedShop] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedRagion, setSelectedRagion] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const columns: ColumnsType<DataType> = [
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text: any, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "ROM",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "RAM",
      dataIndex: "memory",
      key: "memory",
    },
    {
      title: "Current Stock",
      dataIndex: "inShop",
      key: "inShop",
    },
    {
      title: "Sold",
      dataIndex: "sold",
      key: "sold",
    },
  ];

  useEffect(() => {
    auth?.token &&
      getAllStockReportAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          selectedShop ? `&shop=${selectedShop}` : ""
        }${selectedBrand ? `&brand=${selectedBrand}` : ""}${
          selectedRagion ? `&region=${selectedRagion}` : ""
        }`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedShop,
    selectedBrand,
    selectedRagion,
    limit,
    page,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllStockReport_CSVAction(
          auth?.token,
          `?limit=${report?.stockReport?.total}&page=0${
            selectedShop ? `&shop=${selectedShop}` : ""
          }${selectedBrand ? `&brand=${selectedBrand}` : ""}${
            selectedRagion ? `&region=${selectedRagion}` : ""
          }`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = report?.stockReport?.data?.map((el: any) => {
    return {
      key: el?._id,
      inShop: el?.out?.toLocaleString() ?? 0,
      sold: el?.sold ?? 0,
      shop: el?.shop?.name,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      capacity: el?.product?.specification
        .filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
        .map((item: any) => item[1]),
      memory: el?.product?.specification
        .filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
        .map((item: any) => item[1]),
      specification: `${el?.product?.specification
        ?.slice(2)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      inShop: el?.out?.toLocaleString() ?? 0,
      sold: el?.sold ?? 0,
      shop: el?.shop?.name,
      region: el?.shop?.region,
      brand: el?.product?.product?.brand,
      model: el?.product?.product?.model,
      capacity: el?.product?.specification
        ?.filter((item: any) => item[0]?.toLowerCase() === "capacity (rom)")
        ?.map((item: any) => item[1]),
      memory: el?.product?.specification
        ?.filter((item: any) => item[0]?.toLowerCase() === "memory (ram)")
        ?.map((item: any) => item[1]),
      specification: `${el?.product?.specification
        ?.slice(2)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
    };
  });

  useEffect(() => {
    if (selectedShop !== "") {
      setSelectedRagion("");
    }
  }, [selectedShop]);

  return (
    <div className="relative text-black mt-4">
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={report?.stockReport?.total}
        count={Math.ceil(report?.stockReport?.total / limit)}
      >
        <div className="flex items-center justify-between">
          <ReportFiltering
            setSelectedShop={setSelectedShop}
            setSelectedBrand={setSelectedBrand}
            setSelectedRagion={setSelectedRagion}
            selectedShop={selectedShop}
            isStockReport={true}
          />
          <div className="flex justify-end gap-4 py-4">
            <div className="flex items-center space-x-2">
              <FilesCard
                Icon={VscFilePdf}
                tailwindTextColor="text-white"
                tailwindBackgroundColor="bg-orange-400"
                disabled
              />
              <CSVExportButton
                csvHeaders={ShopReportHeader}
                csvData={csvData}
                filename={`Inventory Report.csv`}
              />
            </div>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default StockReportTable;
