import { Input, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useDispatch, useSelector } from "react-redux";
import { updatePurchaseOrderItemAction } from "../../store/purchase/actions";
import { useMediaQuery } from "@mui/material";
const { Text } = Typography;

const ProductPurchaseOrderTable = (props:any) => {
  const { purchase, auth,inventory } = useSelector((state: any) => state);
  const isTablet = useMediaQuery("(max-width: 1100px)");
  const dispatch = useDispatch();
  const data: {
    _id: any;
    type: any;
    brand: any;
    description: any;
    model: any;
    priceUnit: any;
    quantity: any;
    dispatch: any;
    quantityLeft: any;
    totalAmount: string;
  }[] = [];
console.log("yessss",inventory)
inventory?.searchBySn &&
inventory?.searchBySn?.data?.map((el: any) => {
  console.log("data",el)
      data.push({
        _id: el?._id,
        type: el?.product?.type,
        brand: el?.product?.brand,
        description: el?.quotationItem?.specification?.map(
          (item: any) =>
            item[1] && (
              <div className="flex items-center gap-2">
                <span className="font-bold text-xs">{item[0]}: </span>
                <span className=" text-sm">{item[1]}</span>
              </div>
            )
        ),
        model: el?.product?.model,
        priceUnit: el?.requestedItem?.product?.prices[0]?.value?.toLocaleString("en-RW"),
        quantity: el?.quantity,
        quantityLeft: el?.quantityLeft,
        totalAmount: el?.requestedItem?.product?.prices[0]?.value?.toLocaleString("en-RW"),
        dispatch: dispatch,
      });
    });
  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price",
      dataIndex: "priceUnit",
      key: "priceUnit",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      // render: (text, record) => (
      //   <div>
      //     {(record.quantity * record.priceUnit)?.toLocaleString("en-RW")}
      //   </div>
      // ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        pagination={false}
        scroll={isTablet ? { x: 1300 } : undefined}
        summary={(pageData) => {
          let totalCash = 0;
          // let totalRepayment = 0;

          pageData.forEach(({ priceUnit, quantity }) => {
            totalCash += quantity * +priceUnit;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={7}>
                  Balance
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text style={{ fontWeight: "bolder" }}>
                    {totalCash.toLocaleString()}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default ProductPurchaseOrderTable;
